import { Link } from "@reach/router"
import React from "react"
import { Button, Flex, Heading, Image } from "ui"
import illustration from "../FeatureList/Illustration.svg"

const Illustration = () => <Image width="80%" src={illustration} />

const NotFound = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    mt={[4, 5]}
    width={[1, 0.6]}
  >
    <Heading fontSize={[4, 5]} fontWeight="normal" textAlign="center">
      We can't seem to find that suggestion{" "}
      <span role="img" aria-label="sad-face">
        😔
      </span>
    </Heading>
    <Flex justifyContent="center" width="100%" mt={4}>
      <Illustration />
    </Flex>
    <Flex mt={4} justifyContent="center">
      <Link to="/">
        <Button fontSize={[1, 2]} variant="primary">
          All Suggestions
        </Button>
      </Link>
    </Flex>
  </Flex>
)

export default NotFound
