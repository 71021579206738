import React from "react"
import { Flex, Text, Box } from "ui"
import { IFeatureRequest } from "../types"
import { Link } from "@reach/router"

const Duplicate = ({ duplicate }: { duplicate: IFeatureRequest }) => (
  <Flex flexDirection="column" width={1} my={2}>
    <Flex justifyContent="space-between" width={1}>
      <Flex width={1} flexDirection="column" flexWrap="wrap">
        <Flex justifyContent="space-between" width={1} alignItems="center">
          <Text
            width={0.9}
            fontSize={1}
            sx={{ fontWeight: "regular" }}
            color="darkerGrey"
          >
            {duplicate.title}
          </Text>
          <Flex>
            <Link to={`/suggestions/${duplicate.id}`}>
              <Box variant="nav">
                <Text
                  color="darkGrey"
                  sx={{
                    "&:hover": { cursor: "pointer" },
                    position: "abosulte",
                    bottom: "20px"
                  }}
                >
                  View
                </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)

export default Duplicate
