import { CommentProps, CommentsProps, IComment } from "pages/Features/types"
import React, { useContext, useEffect } from "react"
import { Box, Text, Flex, Divider, Image } from "ui"
import * as R from "ramda"
import { toPostedAt, extractGraphqlErrors } from "modules/helpers"
import NewCommentForm from "pages/Features/ViewFeature/CommentForm"
import { UserContext } from "Contexts"
import { useMutation } from "react-apollo"
import { DELETE_COMMENT, GET_FEATURE } from "modules/queries"
import { useToasts } from "react-toast-notifications"
import Avatar from "components/Avatar"
import Loader from "ui/Loader"

const Comment: React.FC<CommentProps> = ({ comment, reqId }) => {
  const { canManagePage } = useContext(UserContext)
  const { addToast } = useToasts()
  const [deleteComment, res] = useMutation(DELETE_COMMENT, {
    variables: { id: comment.id },
    refetchQueries: [{ query: GET_FEATURE, variables: { reqId } }]
  })

  useEffect(() => {
    if (res.error) {
      addToast(extractGraphqlErrors(res.error), { appearance: "error" })
    }
    if (res.data) {
      addToast("Comment deleted", { appearance: "success", autoDismiss: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res])

  if (res.loading) {
    return <Loader />
  }

  return (
    <Box width={1} my={2} bg="white" borderRadius={16} boxShadow="card" p={3}>
      <Flex alignItems="center">
        <Flex
          mr={3}
          alignItems="center"
          width="56px"
          justifyContent="center"
          height="100%"
        >
          <Avatar user={R.prop("submitter", comment)} />
        </Flex>
        <Flex width="100%" flexDirection="column">
          <Flex alignItems="center">
            <Flex flexWrap="wrap" my={2} ml={2} alignItems="center" width={0.8}>
              <Text mr={2} sx={{ fontWeight: "mid" }}>
                {comment.submitter.name}
              </Text>
              <Text
                mr={2}
                color="muted"
                sx={{ fontWeight: "body" }}
                fontSize={[0, 1]}
              >
                {toPostedAt(comment.insertedAt)}
              </Text>
            </Flex>
            {canManagePage && (
              <Flex width={0.2} justifyContent="flex-end">
                <Text
                  onClick={() => deleteComment()}
                  mr={2}
                  color="muted"
                  sx={{ fontWeight: "mid", "&:hover": { cursor: "pointer" } }}
                  fontSize={[0, 1]}
                >
                  Delete
                </Text>
              </Flex>
            )}
          </Flex>
          <Text
            ml={2}
            lineHeight="26px"
            color="darkGrey"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {comment.body}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

const Comments = ({
  comments,
  featureRequestId,
  canComment = true
}: CommentsProps) => {
  if (R.pipe(R.length, R.gte(0))(comments)) {
    return (
      <Flex flexDirection="column">
        <Text ml={2} color="darkGrey" sx={{ whiteSpace: "pre-wrap" }}>
          No Comments
        </Text>
        {canComment && (
          <Box mt={2} width={1}>
            <NewCommentForm reqId={featureRequestId} />
          </Box>
        )}
      </Flex>
    )
  } else {
    return (
      <Flex flexDirection="column">
        {R.map((c: IComment) => (
          <Comment key={c.id} reqId={featureRequestId} comment={c} />
        ))(comments)}
        <Box my={2} mt={3} width={1}>
          <Divider />
        </Box>
        {canComment && (
          <Box mt={2} width={1}>
            <NewCommentForm reqId={featureRequestId} />
          </Box>
        )}
      </Flex>
    )
  }
}

export default Comments
