//Can't be bothered thinking of a name for now
import * as R from "ramda"

export const getPref = (pref, page) => R.path(["appearance", pref], page)

const defaultHeadings = {
    pending: "Suggested",
    considered: "In Review",
    planned: "Planned",
    inprogress: "In Progress",
    completed: "Completed",
}

const defaultShowStatus = {
  pending: true,
  considered: true,
  planned: true,
  inprogress: true,
  completed: true,
}

export const defaultPrefs = (page) => ({
    wouldPay:
        page && getPref("wouldPay", page) !== null
            ? (getPref("wouldPay", page) as boolean)
            : true,
    showNavbarBg: page ? (getPref("showNavbarBg", page) as boolean) : true,
    showBranding: (getPref("showBranding", page) || true) as boolean,
    logoShadow: page ? (getPref("logoShadow", page) as boolean) : false,
    logoBorderRadius: page
        ? (getPref("logoBorderRadius", page) as boolean)
        : false,
    titleText:
        page && getPref("titleText", page)
            ? (getPref("titleText", page) as string)
            : "Feature Requests",
    customHeadings:
        page && typeof getPref("customHeadings", page) === "object"
            ? {...defaultHeadings, ...(getPref("customHeadings", page) as object)}
            : {
                pending: "Pending",
                considered: "In Review",
                planned: "Planned",
                inprogress: "In Progress",
                completed: "Completed",
            },
    showStatus:
        page && typeof getPref("showStatus", page) === "object"
            ? {...defaultShowStatus, ...(getPref("showStatus", page) as object)}
            : {
                pending: true,
                considered: true,
                planned: true,
                inprogress: true,
                completed: true,
            },
    subtitleText:
        page && getPref("subtitleText", page)
            ? (getPref("subtitleText", page) as string)
            : !!page
            ? `Join other users in helping make ${page.name} better`
            : "",
    changelogTitle: page && getPref("changelogTitle", page)
        ? (getPref("changelogTitle", page) as string)
        : 'Changelog',
    changelogSubtitle: page && getPref("changelogSubtitle", page)
        ? (getPref("changelogSubtitle", page) as string)
        : 'Track the latest updates',
    showRoadmap: page ? (getPref("showRoadmap", page) as boolean) : true,
    showChangelog: page ? (getPref("showChangelog", page) as boolean) : true,
    // showStatus: page && getPref("showStatus", page)
    //     ? (getPref("showStatus", page) as string)
    //     : 'pending,considered,planned,inProgress,completed',
    roadmapTitle: page && getPref("roadmapTitle", page)
        ? (getPref("roadmapTitle", page) as string)
        : 'Roadmap',
    roadmapSubtitle: page && getPref("roadmapSubtitle", page)
        ? (getPref("roadmapSubtitle", page) as string)
        : ''


})
