import { Text as RText } from "rebass"
import styled from "./theme"
import { fontWeight, FontWeightProps } from "styled-system"

export type TextProps = FontWeightProps

const Text = styled(RText)<TextProps>`
  ${fontWeight}
  letter-spacing: 0.1px;
`

export default Text
