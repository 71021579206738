import { IPageContext, Page } from "Contexts/types"
import { notNil } from "modules/helpers"
import { GET_PAGE } from "modules/queries"
import { extractSubdomain, isKampsiteApp } from "modules/subdomains"
import React, { useContext, useState } from "react"
import { useQuery } from "react-apollo"
import Loader from "ui/Loader"

export const PageContext = React.createContext<IPageContext>(null)

const PageProvider = ({ children }) => {
  const subdomain = extractSubdomain(window)
  const [page, setPage] = useState(null)
  const [loading, setLoading] = useState(true)
  const prefs =
    page && page.appearance ? page.appearance : { showNavbarBg: true }

  return (
    <PageContext.Provider
      value={{
        subdomain: page ? page.subdomain : subdomain,
        page,
        setPage,
        labels: page && page.labels,
        prefs,
        loading,
        setLoading,
        isKampsiteApp: isKampsiteApp(window),
      }}
    >
      {loading ? <Loader /> : children}
      <FetchPage
        setLoading={setLoading}
        setPage={setPage}
        subdomain={page ? page.subdomain : subdomain}
      />
    </PageContext.Provider>
  )
}
export const PageFetcher = () => {
  return null
}

const FetchPage = ({ setPage, subdomain, setLoading }) => {
  const { data, loading, error } = useQuery(GET_PAGE, {
    variables: { subdomain },
  })

  if (loading) {
    setLoading(true)
    return null
  }

  if (error) {
    setLoading(false)
  }

  if (data && notNil(data.page)) {
    setLoading(false)
    setPage(data.page)
  }

  if (data) {
    setLoading(false)
  }

  return null
}

PageProvider.displayName = "PageProvider"

export default PageProvider
