import * as R from "ramda"
import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import {AnimatedBox, Box, Divider, Text} from "ui"
import { Link, navigate, RouteComponentProps } from "@reach/router"
import Loader from "ui/Loader"
// import FeatureRequest from "../../FeatureRequest"
import {Flex} from "../../../ui";
import {FeatureImage} from "../../Features/FinishedFeatures/View/FinishedFeature";
import ViewFinishedFeature from "../FinishedFeatures/View";

const canLoadMore = (currentNumber: number, total: number) =>
  currentNumber < total

const FeatureRequestList = (props: any) => {
    const navigate = props.navigate
    const canManagePage = props.canManagePage
    return (

        <Box width="100%">
            <InfiniteScroll
                pageStart={0}
                loadMore={props.loadMore}
                hasMore={canLoadMore(props.requests.length, props.totalCount)}
                loader={<Loader key={Math.random().toString()} />}
                initialLoad={false}
            >
                {props.requests.map(
                    (req) =>
                        req.showcase ? (
                           <ViewFinishedFeature data={req}></ViewFinishedFeature>
                        ) : (
                            <Flex width={1} flexDirection="column" alignItems="center" mt={3} mb={3}>
                                <Flex>

                                    <Text color="#92929D">
                                        { new Date(req.updatedAt).toLocaleDateString() }: &nbsp;
                                    </Text>
                                    <Link to={'/suggestions/' + req.id}>{ req.title }</Link>
                                </Flex>

                            </Flex>
                    )
                    )}
            </InfiniteScroll>
        </Box>
    )
}



export default FeatureRequestList
