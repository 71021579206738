import { RouteComponentProps, navigate } from "@reach/router"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import useBool from "hooks/useBool"
import useGetOrgUsers from "hooks/useGetOrgUsers"
import InviteUser from "pages/ManagementDashboard/Users/InviteUser"
import * as R from "ramda"
import React, { useContext, useState, useEffect } from "react"
import { AnimatedBox, Box, Button, Divider, Flex, Heading, Text } from "ui"
import Loader from "ui/Loader"
import OrgUser from "./OrgUser"
import { useMutation } from "react-apollo"
import { REMOVE_USER, INVITE_USER, GET_ORG_USERS } from "modules/queries"
import { useToasts } from "react-toast-notifications"
import { extractGraphqlErrors } from "modules/helpers"

const Users: React.FC<RouteComponentProps> = ({ location }) => {
  const { addToast } = useToasts()
  const { page } = useContext(PageContext)
  const { user: meUser } = useContext(UserContext)
  const orgId: string = R.path(["organisation", "id"], page)
  const { users, loading, maxUsers } = useGetOrgUsers(orgId)

  const [showForm, cycleShowForm] = useBool(false)
  const reachedMax = users && users.length >= maxUsers
  const [input, setInput] = useState({ name: "", email: "" })
  const [inviteUser, { data, loading: inviteLoading, error }] = useMutation(
    INVITE_USER,
    {
      variables: { input: { ...input, orgId } },
      refetchQueries: [{ query: GET_ORG_USERS, variables: { orgId } }],
    }
  )

  const [removeUser, removeResp] = useMutation(REMOVE_USER, {
    refetchQueries: [{ query: GET_ORG_USERS, variables: { orgId } }],
  })

  const removeUserFromOrg = (userId) =>
    removeUser({ variables: { userId, orgId } })

  useEffect(() => {
    if (data) {
      addToast("User invited", { appearance: "success", autoDismiss: true })
      cycleShowForm()
    } else if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [data, error])

  useEffect(() => {
    if (removeResp.data) {
      addToast("User removed", { appearance: "success", autoDismiss: true })
    } else if (removeResp.error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [removeResp])

  if (loading || inviteLoading || removeResp.loading) {
    return <Loader />
  }

  return (
    <Box width={1}>
      <Heading textAlign="center" fontSize={5}>
        Users
      </Heading>
      <Text m={3} color="muted">
        Add new users to help manage your page.
      </Text>
      <Box my={3} width={1}>
        <Divider />
      </Box>
      <Flex width={1} flexDirection="column">
        {users &&
          users.map((user) => (
            <Box key={user.id} my={3}>
              <OrgUser
                user={user}
                isViewingUser={user.id === meUser.id}
                onRemove={removeUserFromOrg}
              />
            </Box>
          ))}
        {showForm && (
          <AnimatedBox
            initial={{ y: -100, x: 100 }}
            animate={{ y: 0, x: 0 }}
            m={3}
          >
            <InviteUser
              input={input}
              setInput={(field) => (val) =>
                setInput({ ...input, [field]: val })}
              onSubmit={inviteUser}
            />
          </AnimatedBox>
        )}
      </Flex>
      <Flex justifyContent="flex-end" my={3}>
        {!showForm && (
          <Button
            onClick={() =>
              reachedMax ? navigate("/manage/billing") : cycleShowForm()
            }
          >
            {"Invite User"}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

export default Users
