import { RouteComponentProps } from "@reach/router"
import Container from "components/Container"
import useMe from "hooks/useMe"
import React from "react"
import Loader from "ui/Loader"
import OnboardingForm from "./OnboardingForm"

const Onboarding: React.FC<RouteComponentProps> = ({ location }) => {
  const { loading } = useMe()

  if (loading) {
    return <Loader />
  }

  return (
    <Container
      location={location}
      title={"Let's get setup"}
      subtitle={"Don't worry, you can change this later"}
    >
      <OnboardingForm />
    </Container>
  )
}

export default Onboarding
