import * as R from "ramda"

const FAKE_IS_ON_SUBDOMAIN = true
const FAKE_SUBDOMAIN = "abrankings"
const isDev = process.env.NODE_ENV === "development"

export const removeDomainName = (hostParts: string[]): string[] =>
  R.reject((val: string) =>
    R.includes(R.toLower(val), ["kampsite", "co", "localhost", "test-kampsite"])
  )(hostParts)

const rootAppSubdomains = ["app.kampsite.co", "kampsite.co", "test-kampsite.co"]

const isRootAppSubdomain = (domain: string): boolean =>
  R.includes(domain, rootAppSubdomains)

export const isKampsiteApp = (wnd: Window): boolean =>
  R.pipe(
    R.path(["location", "hostname"]),
    R.flip(R.includes)([
      "app.kampsite.co",
      "app.test-kampsite.co",
      ...(!FAKE_IS_ON_SUBDOMAIN && isDev ? ["localhost"] : []),
    ])
  )(wnd)

export const isKampsite = (url) => url.includes("kampsite.co")

export const getSubdomain = R.ifElse(
  isKampsite,
  R.pipe(R.split("."), removeDomainName, R.head),
  R.identity
)

export const extractSubdomain = (wnd: Window): string => {
  if (FAKE_IS_ON_SUBDOMAIN && isDev) {
    return FAKE_SUBDOMAIN
  }

  return R.pipe(
    R.path(["location", "hostname"]),
    R.ifElse(isRootAppSubdomain, R.always(null), getSubdomain)
  )(wnd)
}
