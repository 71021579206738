import { navigate } from "@reach/router"
import InputWithLabel from "components/Input"
import { extractGraphqlErrors } from "modules/helpers"
import {
  GET_FEATURE,
  GET_FEATURE_COUNTS,
  UPDATE_FEATURE_STATUS
} from "modules/queries"
import ModalContent from "pages/Features/FeatureRequest/Actions/ModalContent"
import * as R from "ramda"
import React, { useEffect, useState, useContext } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Checkbox, Label, Link, Select, Text } from "ui"
import { PageContext } from "Contexts/PageContext"

const inputValid = ({ status, rejectedReason }) =>
  R.cond([
    [R.equals("pending"), R.T],
    [R.equals("completed"), R.T],
    [R.equals("considered"), R.T],
    [R.equals("planned"), R.T],
    [R.equals("accepted"), R.T],
    [R.equals("rejected"), () => R.length(rejectedReason) > 2]
  ])(status)

const ChangeStatusModalContent = ({
  input,
  setInput,
  createShowcase,
  setCreateShowcase
}) => (
  <Box width={1}>
    <Text
      mb={2}
      sx={{
        fontWeight: "mid",
        fontSize: [3, 3, 4]
      }}
    >
      Change Status
    </Text>
    <Box mb={3}>
      <Label sx={{ fontWeight: "mid" }} htmlFor="status">
        Status
      </Label>
      <Select
        id="status"
        name="status"
        onChange={e => setInput({ ...input, status: e.target.value })}
        defaultValue={input.status}
      >
        <option value="pending" key="pending">
          Suggested
        </option>
        <option value="considered" key="considered">
          In Review
        </option>
        <option value="planned" key="planned">
          Planned
        </option>
        <option value="accepted" key="accepted">
          In progress
        </option>
        <option value="completed" key="completed">
          Completed
        </option>
        <option value="rejected" key="rejected">
          Rejected
        </option>
      </Select>
    </Box>
    {input.status === "accepted" && (
      <InputWithLabel
        name="plannedDate"
        type="text"
        placeholder="When do you plan to release this feature"
        labelText="Planned Release Date"
        labelSubText="optional"
        value={input.plannedDate}
        onChange={val => setInput({ ...input, plannedDate: val })}
        autoFocus={false}
        smallLabel={true}
      />
    )}
    {input.status === "planned" && (
      <InputWithLabel
        name="plannedDate"
        type="text"
        placeholder="When do you plan to release this feature"
        labelText="Planned Release Date"
        labelSubText="optional"
        value={input.plannedDate}
        onChange={val => setInput({ ...input, plannedDate: val })}
        autoFocus={false}
        smallLabel={true}
      />
    )}
    {input.status === "rejected" && (
      <InputWithLabel
        name="rejectedReason"
        type="text"
        placeholder="I am rejecting this request because..."
        labelText="Reason"
        labelSubText="Explain why you are rejecting this request"
        value={input.rejectedReason}
        onChange={val => setInput({ ...input, rejectedReason: val })}
        autoFocus={false}
        smallLabel={true}
      />
    )}
    {input.status === "completed" && (
      <Box mt={2}>
        <Label>
          <Checkbox
            id="would-you-pay"
            required
            name="would-you-pay"
            checked={createShowcase}
            onChange={() => setCreateShowcase(!createShowcase)}
          />
          Create a feature showcase?
        </Label>
        <Text my={2}>
          A feature showcase is a dedicated page highlighting the newly released
          change or feature. You can read more about it{" "}
          <Link href="https://feedback.kampsite.co/suggestions/87206ada-1514-44aa-b8b7-0bf576e9bdd1/feature-showcase/db169115-2d14-4a69-be88-3057a84dec2c">
            here
          </Link>
          .
        </Text>
      </Box>
    )}
  </Box>
)

const ChangeStatusModal = ({ isOpen, request, onClose }) => {
  const { addToast } = useToasts()
  const { subdomain } = useContext(PageContext)
  const [createShowcase, setCreateShowcase] = useState(false)
  const [input, setInput] = useState({
    status: request.status,
    plannedDate: "",
    rejectedReason: ""
  })

  const [updateStatus, { data, error }] = useMutation(UPDATE_FEATURE_STATUS, {
    variables: { input, reqId: request.id },
    refetchQueries: [{ query: GET_FEATURE_COUNTS, variables: { subdomain } }]
  })

  const doUpdateStatus = () =>
    updateStatus().then(res => {
      if (res.data) {
        addToast("Status updated", {
          appearance: "success",
          autoDismiss: true
        })
        input.status === "completed" &&
          createShowcase &&
          navigate(`/suggestions/${request.id}/feature-showcase/new`)
      } else if (error) {
        addToast(extractGraphqlErrors(error), {
          appearance: "error",
          autoDismiss: true
        })
      }
    })

  return (
    <ModalContent
      open={isOpen}
      buttonText="Update"
      onSubmit={doUpdateStatus}
      buttonDisabled={!inputValid(input)}
      onClose={onClose}
    >
      <ChangeStatusModalContent
        createShowcase={createShowcase}
        setCreateShowcase={setCreateShowcase}
        input={input}
        setInput={setInput}
      />
    </ModalContent>
  )
}

export default ChangeStatusModal
