import InputWithLabel from "components/Input"
import React from "react"
import { Box, Button, Flex } from "ui"

interface InviteUserProps {
  input: {
    email: string
    name: string
  }
  setInput: (field) => (val) => any
  onSubmit: () => any
}

const InviteUser = ({ input, setInput, onSubmit }) => (
  <Box width={1}>
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center" width={1}>
      <Box mr={3} width={1 / 3}>
        <InputWithLabel
          name="email"
          type="email"
          value={input.email}
          onChange={setInput("email")}
          labelText="Email"
          placeholder="name@yourco.com"
          smallLabel
          labelSubText=""
          required
        />
      </Box>
      <Box mr={3} width={1 / 3}>
        <InputWithLabel
          name="name"
          type="name"
          value={input.name}
          onChange={setInput("name")}
          labelText="Name"
          placeholder="George"
          labelSubText=""
          smallLabel
          required
        />
      </Box>
    </Flex>
    <Flex mt={4} justifyContent="center" alignItems="center">
      <Button onClick={onSubmit}>Send Invite</Button>
    </Flex>
  </Box>
)

export default InviteUser
