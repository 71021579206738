import React, { useRef, useEffect } from "react"
import { Box, Input, Text, Flex, TextArea } from "ui"
import * as R from "ramda"
import styled from "ui/styled"

const Label = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeights.mid};
`

const validLength = (val: string, { minLength, maxLength }: InputProps) =>
  (val.length >= (minLength || 0) &&
    (maxLength ? val.length <= maxLength : true)) ||
  val === ""

const lengthErrorMessage = ({ minLength, maxLength }: InputProps) =>
  minLength && maxLength
    ? `must be between ${minLength} and ${maxLength} characters`
    : minLength
    ? `at least ${minLength} characters required`
    : `cannot be more than ${maxLength} characters`

interface InputProps {
  name: string
  type: string
  placeholder: string
  value: string
  onChange: (text: string) => any
  labelText: string
  labelSubText: string
  autoFocus?: boolean
  textArea?: boolean
  rows?: number
  required?: boolean
  disabled?: boolean
  smallLabel?: boolean
  minLength?: number
  maxLength?: number
  customValidation?: (val) => null | string
  width?: string
}
const inputProps = [
  "name",
  "type",
  "placeholder",
  "value",
  "setValue",
  "required",
]
const InputWithLabel = (props: InputProps) => {
  const ref = useRef(null)
  useEffect(() => {
    if (props.autoFocus) {
      ref.current.focus()
    }
  }, [ref, props.autoFocus])
  // This was whole custom validation was tacked on and not properly thoughout
  // Really should have used a proper form library instead of building my own duct taped one
  const hasError =
    (props.customValidation && props.customValidation(props.value)) ||
    !validLength(props.value, props) ||
    false

  return (
    <Box width="100%">
      <Flex
        flexDirection={["column", "column", "row"]}
        alignItems={["flex-start", "flex-start", "center"]}
        flexWrap="wrap"
        ml={2}
        mb={1}
      >
        {props.smallLabel ? (
          <Label mr={2}>{props.labelText}</Label>
        ) : (
          <Label fontSize={[2, 2, 3]} mr={2}>
            {props.labelText}
          </Label>
        )}
        <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
          {props.labelSubText}
        </Text>
      </Flex>
      {props.textArea ? (
        <TextArea
          rows={props.rows}
          ref={ref}
          {...R.pick(inputProps)(props)}
          onChange={(e) => props.onChange(e.target.value)}
          sx={{
            border: !hasError ? "none" : "2px solid #fc5959 !important",
          }}
        />
      ) : (
        <Input
          disabled={props.disabled}
          ref={ref}
          {...R.pick(inputProps)(props)}
          onChange={(e) => props.onChange(e.target.value)}
          sx={{
            width: props.width ? props.width : "100%",
            border: !hasError ? "none" : "2px solid #fc5959 !important",
          }}
        />
      )}
      {hasError && (
        <Text
          ml={2}
          fontSize={[0, 0, 1]}
          color="danger"
          sx={{ fontWeight: "mid" }}
        >
          {(props.customValidation && props.customValidation(props.value)) ||
            lengthErrorMessage(props)}
        </Text>
      )}
    </Box>
  )
}

export default InputWithLabel
