import React from "react"
import { AuthenticatedUser } from "Contexts/types"
import { Flex, Text, Box } from "ui"
import Avatar from "components/Avatar"

interface OrgUserProps {
  user: AuthenticatedUser
  isViewingUser: boolean
  onRemove: (id: string) => any
}

const OrgUser = ({ user, isViewingUser, onRemove }: OrgUserProps) => (
  <Flex alignItems="center" justifyContent="space-between" width={1}>
    <Flex alignItems="center">
      <Box mx={3}>
        <Avatar user={user} />
      </Box>
      <Flex justifyContent="center" flexDirection="column">
        <Text fontSize={2} sx={{ fontWeight: "regular" }}>
          {user.name}
        </Text>
      </Flex>
    </Flex>
    {!isViewingUser && (
      <Text
        fontSize={1}
        onClick={() => {
          if (window.confirm("Are you sure?")) {
            onRemove(user.id)
          }
        }}
        sx={{
          fontWeight: "regular",
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.1)"
          }
        }}
        color="muted"
      >
        Remove
      </Text>
    )}
  </Flex>
)

export default OrgUser
