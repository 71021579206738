import * as R from "ramda"
import React from "react"
import { Box, Flex, Image, Text } from "ui"

const isIn = (list: string[]) => (val: string) => R.includes(val, list)

const firstLetter = (user) =>
  R.pipe(R.propOr("Anonymous", "name"), R.head, R.toLower)(user)

const nameImageBackgroundColor = (user) =>
  R.pipe(
    firstLetter,
    R.cond([
      [isIn(["a", "b", "c", "d", "e", "f", "g"]), R.always("primary")],
      [isIn(["h", "i", "j", "k", "l", "m", "n"]), R.always("success")],
      [
        isIn(["o", "p", "q", "r", "s", "t", "u", "v", "w", "x"]),
        R.always("danger"),
      ],
      [isIn(["v", "w", "x", "y", "z"]), R.always("warning")],
    ])
  )(user)

const Avatar = ({ user, width = "48px", fontSize = 2 }) => (
  <Flex height={width} width={width}>
    {R.prop("imageUrl", user) ? (
      <Image sx={{ borderRadius: "50px" }} src={user.imageUrl} />
    ) : (
      <Box
        width={width}
        height={width}
        borderRadius="50px"
        bg={`${nameImageBackgroundColor(user)}Lightest`}
      >
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent="center"
          alignItems="center"
        >
          <Text
            sx={{ fontWeight: "mid" }}
            color={nameImageBackgroundColor(user)}
            fontSize={fontSize}
          >
            {R.toUpper(firstLetter(user))}
          </Text>
        </Flex>
      </Box>
    )}
  </Flex>
)

export default Avatar
