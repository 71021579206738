import { navigate } from "@reach/router"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import { GET_FEATURES } from "modules/queries"
import NoSuggestions from "pages/Features/FeatureList/NoSuggestions"
import * as R from "ramda"
import React, { useContext, useState, useEffect } from "react"
import { useQuery, useLazyQuery } from "react-apollo"
import { AnimatedBox, Box, Button, Flex, Heading, Input } from "ui"
import Loader from "ui/Loader"
import { buildFetchMore, getReqs } from "."
import FeatureRequestList from "./FeatureRequestList"
import { useDebounce } from "use-debounce"
import SearchIcon from "components/Search"
import LabelGroup from "components/LabelGroup"

const STATUS_LABELS = ["accepted", "completed", "pending"]

interface SearchProps {
  onExit: () => any | void
}

const Search = ({ onExit }: SearchProps) => {
  const { subdomain, labels } = useContext(PageContext)
  const { activity, updateActivity, canManagePage } = useContext(UserContext)
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedVal] = useDebounce(searchTerm, 300)
  const [hasSearched, setHasSearched] = useState(false)
  const [selectedLabels, setSelectedLabels] = useState([])

  const [searchReqs, { data, error, loading, fetchMore }] = useLazyQuery(
    GET_FEATURES
  )

  useEffect(() => {
    const doSearch = () =>
      searchReqs({
        variables: {
          subdomain,
          status: "all",
          limit: 15,
          offset: 0,
          searchTerm: debouncedVal,
          labels: R.map(R.prop("text"), selectedLabels),
        },
      })

    if (debouncedVal.length > 1) {
      setHasSearched(true)
      doSearch()
    } else if (hasSearched && debouncedVal.length === 0) {
      doSearch()
    } else if (selectedLabels.length > 0) {
      setHasSearched(true)
      doSearch()
    }
  }, [debouncedVal, searchReqs, hasSearched, selectedLabels])

  if (error) {
    return null
  }

  const requests = getReqs(data) || []

  return (
    <Box width="100%" mt={4}>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        width={1}
        alignItems="center"
      >
        <Flex mb={3} width={1} justifyContent="space-between" mt={3}>
          <Box width={[0, 0, 0.2]} display={["block", "none", "block"]}></Box>
          <Box width={[1, 1, 0.6, 0.6]}>
            <AnimatedBox
              position="relative"
              width={0.6}
              initial={{
                width: 0,
                x: 150,
              }}
              animate={{
                width: "100%",
                x: 0,
              }}
              transition={{
                duration: 0.5,
              }}
            >
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                autoFocus
                type="text"
                placeholder="search"
              />
              <Box
                sx={{ cursor: "pointer" }}
                onClick={onExit}
                position="absolute"
                top="25%"
                right="5%"
              >
                <SearchIcon />
              </Box>
            </AnimatedBox>
          </Box>
          <Flex
            width={[0, 0.2]}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box display={["none", "block"]}>
              <Flex alignItems="center">
                <Button
                  onClick={() => navigate("/new")}
                  fontSize={[1, 2]}
                  variant="primary"
                >
                  + New
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex mb={4} justifyContent="center" width={1}>
        <AnimatedBox>
          <LabelGroup
            labels={R.reject(R.propEq("type", "INTERNAL"))(labels)}
            onChange={setSelectedLabels}
            selectedLabels={selectedLabels}
          />
        </AnimatedBox>
      </Flex>
      <AnimatedBox
        initial={{
          opacity: 0.1,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.8,
        }}
      >
        {!hasSearched && (
          <Flex flexDirection="column" alignItems="center">
            <Heading mt={3} fontSize={4} fontWeight="normal" textAlign="center">
              Search titles, descriptions and labels.
            </Heading>
            <Heading
              my={3}
              color="muted"
              fontSize={3}
              fontWeight="normal"
              textAlign="center"
            >
              Results will appear below
            </Heading>
          </Flex>
        )}
      </AnimatedBox>
      {requests.length > 0 && (
        <Flex width={1} flexDirection="column" alignItems="center">
          {loading ? (
            <Loader />
          ) : R.length(requests) > 0 ? (
            <FeatureRequestList
              loadMore={buildFetchMore(fetchMore, requests)}
              totalCount={requests.length === 15}
              userVoted={activity.voted}
              updateActivity={updateActivity}
              requests={requests}
              canManagePage={canManagePage}
            />
          ) : (
            <Box
              boxShadow="card"
              pb={5}
              borderRadius="0 0 16px 16px"
              bg="white"
            >
              <Flex justifyContent="center">
                <NoSuggestions />
              </Flex>
            </Box>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default Search
