import { RouteComponentProps } from "@reach/router"
import auth from "modules/auth"
import React, { useContext } from "react"
import { Box, Button, Flex, Heading, Text, Divider } from "ui"
import { UserContext } from "Contexts"
import * as R from "ramda"
import { Org } from "Contexts/types"

const slackLogin = orgId => {
  fetch(`${process.env.REACT_APP_BACKEND_URL}/slack-signup?org_id=${orgId}`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${auth.getIdToken()}`
    }
  })
    .then(res => res.json())
    .then(({ url }) => (window.location = url))
}

const Success = () => (
  <Box py={2} px={3} bg="successLightest" width={1} borderRadius={8}>
    <Text sx={{ fontWeight: "mid" }} color={"success"}>
      Your slack account has been successfully connnected!
      <span role="img" aria-label="Wooohoooo Emoji">
        🎉
      </span>
    </Text>
  </Box>
)

const Integrations: React.FC<RouteComponentProps> = ({ location }) => {
  const { user } = useContext(UserContext)
  const searchParams = new URLSearchParams(location.search)
  const success = searchParams.get("slack_success") === "true"
  const hasOrg = R.length(R.propOr([], "organisations", user)) > 0
  const org: Org = R.head<Org>(R.propOr([], "organisations", user))

  return (
    hasOrg && (
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Box mb={3}>
            <Text
              mb={2}
              sx={{
                fontWeight: "mid",
                fontSize: [3, 3, 4]
              }}
            >
              Slack Integration
            </Text>
            <Text color="muted">
              Connect your slack account to receive notifications when there are
              new suggestions and comments.
            </Text>
            <Box width={1} my={3}>
              <Divider />
            </Box>
          </Box>
          {success && (
            <Box mb={3}>
              <Success />
            </Box>
          )}
        </Flex>
        <Box my={3}>
          {org["slackSetup"] ? (
            <Button disabled={true} onClick={() => null}>
              Account connected
            </Button>
          ) : (
            <Button onClick={() => slackLogin(org && org.id)} bg="success">
              Connect with slack
            </Button>
          )}
        </Box>
      </Flex>
    )
  )
}

export default Integrations
