import { GET_ME } from "modules/queries"
import { useQuery } from "react-apollo"

const useMe = () => {
  const getMeResp = useQuery(GET_ME)

  return getMeResp
}

export default useMe
