import InputWithLabel from "components/Input"
import { NewRequestFormInputType } from "pages/Features/types"
import * as R from "ramda"
import React from "react"
import { Heading } from "rebass"
import { Box, Button, Checkbox, Flex, Label, Text } from "ui"
import Loader from "ui/Loader"
import { isGuest } from "modules/user"
import { User } from "Contexts/types"
import Upload from "pages/settings/fileUpload"
import LabelPill from "components/Label"
import LabelGroup from "components/LabelGroup"

const submitterValid = R.curry((user, image, submitter) =>
  needsConsent(submitter, image) && isGuest(user)
    ? submitter.consent.storeName &&
      submitter.consent.storeEmail &&
      (image ? submitter.email.length > 0 : true)
    : true
)

const needsConsent = ({ name, email }, image) =>
  !R.includes(name, ["", "anonymous"]) ||
  R.length(email) > 0 ||
  (image && image.size)

const imageToBig = (image) => image && image.size >= 4e6

const formValid = (user: User, attrs: NewRequestFormInputType): boolean =>
  R.pipe(
    R.pick(["description", "pageId", "title", "submitter", "image"]),
    R.evolve({
      description: R.pipe(R.length, R.lte(5)),
      title: R.pipe(R.length, R.lte(5)),
      pageId: R.pipe(R.isNil, R.not),
      submitter: submitterValid(user, attrs.image),
      image: R.pipe(imageToBig, R.not),
    }),
    Object.values,
    R.all(R.identity)
  )(attrs)

const consentPath = ["submitter", "consent"]

const NewSuggestionForm = ({
  user,
  submitForm,
  input,
  loading,
  setInput,
  isEdit = false,
  labels = [],
  canUploadImage = true,
}) => {
  const updateField = (name: string) => (val: string | boolean) =>
    setInput({ ...input, [name]: val })

  const updateSubmitter = (name: string) => (val: string) =>
    setInput({
      ...input,
      submitter: { ...input.submitter, [name]: val },
    })

  if (loading) {
    return <Loader />
  }

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault()
        !loading && formValid(user, input) && submitForm()
      }}
    >
      <Flex width={1} flexDirection="column">
        <Box my={3} width="100%">
          <InputWithLabel
            name="title"
            type="text"
            required
            placeholder="Title"
            labelText="Title"
            labelSubText="Quick summary about your suggestion"
            value={input.title}
            onChange={updateField("title")}
            autoFocus={true}
            minLength={5}
            maxLength={400}
          />
        </Box>

        <Box my={3} width="100%">
          <InputWithLabel
            name="description"
            type="text"
            required
            placeholder="Go into more detail about the feature you want added or the improvement you want made"
            labelText="Description"
            labelSubText="Go into more detail about your idea"
            value={input.description}
            onChange={updateField("description")}
            autoFocus={false}
            textArea
            minLength={5}
            maxLength={4000}
            rows={10}
          />
        </Box>
        {labels.length > 0 && (
          <Flex ml={2} my={3} flexDirection="column">
            <Heading
              as="h1"
              sx={{
                fontWeight: "mid",
                fontSize: [2, 2, 3],
              }}
            >
              Labels
            </Heading>
            <Text mb={2} fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
              Choose up to three labels to help categorise your suggestion
            </Text>
            <LabelGroup
              labels={labels}
              selectedLabels={input.labels}
              onChange={updateField("labels")}
              maxAllowed={3}
            />
          </Flex>
        )}
        {canUploadImage && (
          <Flex alignItems={"center"} mt={3} ml={2}>
            <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
              Image
            </Text>
            <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
              Upload a an image (optional)
            </Text>
          </Flex>
        )}
        {canUploadImage && (
          <Flex flexWrap="wrap" alignItems="center" mt={2} mb={3} ml={2}>
            <Box width={[1, "auto"]}>
              <Upload
                error={
                  imageToBig(input.image) &&
                  "Image to big. Please choose an image under 4mb"
                }
                value={input.image}
                onSelected={updateField("image")}
              />
            </Box>
            {input.image && (
              <Box>
                <Text
                  ml={3}
                  fontSize={1}
                  fontWeight="normal"
                  onClick={() => updateField("image")(null)}
                  color="muted"
                  sx={{ ":hover": { cursor: "pointer" } }}
                >
                  Remove
                </Text>
              </Box>
            )}
          </Flex>
        )}
        {user.type === "guest" && (
          <>
            <Flex my={3} flexDirection="column" width={1}>
              <Heading
                as="h1"
                sx={{
                  fontWeight: "mid",
                  fontSize: [2, 2, 3],
                }}
              >
                {input.image ? "A little bit about you" : "Optional"}
              </Heading>
              <Text
                mb={2}
                fontWeight="normal"
                fontSize={[0, 0, 1]}
                color="muted"
              >
                {input.image
                  ? "Since you are uploading an image, we need your name and email. You can rest assure that we won't have access to your email and it will only live on Kampsite's servers"
                  : " The below two field are completely optional. If you do choose to enter your information, you can rest assure that we won't have access to your email and it will only live on Kampsite's servers."}
              </Text>
              <Text
                mt={2}
                fontWeight="normal"
                fontSize={[0, 0, 1]}
                color="muted"
              >
                Name will be displayed under the suggestion, email is used to
                let you know when there are any updates to your suggestion
              </Text>
            </Flex>
            <Flex width="100%" flexWrap="wrap">
              <Box mr={[0, 3]} my={3} width={[1, 0.4, 0.3]}>
                <InputWithLabel
                  name="name"
                  type="text"
                  placeholder="Your name"
                  labelText="Name"
                  labelSubText=""
                  value={input.submitter.name}
                  onChange={updateSubmitter("name")}
                  autoFocus={false}
                />
              </Box>
              <Box ml={[0, 3]} my={3} width={[1, 0.4, 0.3]}>
                <InputWithLabel
                  name="email"
                  type="email"
                  placeholder="Email address"
                  labelText="Email"
                  labelSubText=""
                  value={input.submitter.email}
                  onChange={updateSubmitter("email")}
                  autoFocus={false}
                />
              </Box>
            </Flex>
            {needsConsent(input.submitter, input.image) && (
              <Box my={2}>
                <Label>
                  <Checkbox
                    checked={submitterValid(user, input.image, input.submitter)}
                    onChange={() => {
                      const valid = submitterValid(
                        user,
                        input.image,
                        input.submitter
                      )
                      setInput(
                        R.assocPath(
                          consentPath,
                          { storeName: !valid, storeEmail: !valid },
                          input
                        )
                      )
                    }}
                    id="storeName"
                    name="storeName"
                  />
                  I give consent for Kampsite to display my name and send me
                  updates via email
                </Label>
              </Box>
            )}
          </>
        )}
        <Box mt={3} px={[4, 4, 0, 0]} alignSelf="flex-end">
          <Button
            type="submit"
            disabled={!formValid(user, input) && !loading}
            onClick={() => submitForm()}
            size="large"
            variant="primary"
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default NewSuggestionForm
