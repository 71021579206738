import { FeaturePageNavbar } from "pages/navbar"
import React from "react"
import ContentLoader from "react-content-loader"
import { Box, Flex, Text } from "ui"
import "./browser.css"
import { PagePrefs } from "Contexts/types"

interface FakeBrowserProps {
  subdomain: string
  companyName: string
  logo: File | null | string
  prefs: PagePrefs
  isOnboarding: boolean
}

const MyLoader = () => (
  <ContentLoader
    height={40}
    width={400}
    speed={2}
    animate={true}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="37" y="10" rx="3" ry="3" width="350" height="6" />
    <rect x="35" y="24" rx="3" ry="3" width="53" height="6" />
    <circle cx="17" cy="11" r="5" />
    <rect x="10" y="19" rx="3" ry="3" width="15" height="12" />
  </ContentLoader>
)

const getLogoUrl = logo =>
  typeof logo === "string" ? logo : URL.createObjectURL(logo)

const FakeBrowser = (props: FakeBrowserProps) => (
  <div className="browser">
    <div className="browser-navigation-bar">
      <i></i>
      <i></i>
      <i></i>
      <input value={`https://${props.subdomain}.kampsite.co`} disabled />
    </div>

    <div className="browser-container">
      <FeaturePageNavbar
        showMenu={false}
        prefs={props.prefs}
        companyName={props.companyName || "Your Company"}
        logo={props.logo && getLogoUrl(props.logo)}
      />
      <h3>{props.isOnboarding ? "Feature Requests" : props.prefs.titleText}</h3>
      <Text mt="-16px" color="muted" fontSize={0}>
        {props.isOnboarding
          ? "You can change this text later"
          : props.prefs.subtitleText}
      </Text>
      <Flex width="100%" flexDirection="column" px={4} alignItems="center">
        <Box
          pt={2}
          my={3}
          borderRadius={8}
          width="100%"
          bg="white"
          boxShadow="card"
        >
          <MyLoader />
        </Box>
        <Box
          pt={2}
          my={3}
          borderRadius={8}
          width="100%"
          bg="white"
          boxShadow="card"
        >
          <MyLoader />
        </Box>

        <Box
          pt={2}
          my={3}
          borderRadius={8}
          width="100%"
          bg="white"
          boxShadow="card"
        >
          <MyLoader />
        </Box>
      </Flex>
    </div>
  </div>
)

export default FakeBrowser
