import React from "react"
import Popup from "reactjs-popup"
import { Box, Button, Flex } from "ui"

interface SignInModalProps {
  children: any
  open: boolean
}

const SignInModal = ({ open, children }: SignInModalProps) => (
  <Popup
    open={open}
    modal
    closeOnDocumentClick
    lockScroll
    overlayStyle={{
      background: "rgba(0, 0, 0, 0.8)"
    }}
    contentStyle={{
      borderRadius: 8,
      width: "unset",
      padding: "8px",
      marginTop: "20%",
      background: "white"
    }}
  >
    {close => (
      <Box
        className="modal"
        borderRadius={8}
        width={["90vw", "70vw", "50vw", "40vw"]}
        maxWidth={"420px"}
        py={2}
        px={[2, 3]}
      >
        {children(close)}
      </Box>
    )}
  </Popup>
)

export default SignInModal
