import React, { useContext, useState, useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { Flex, Heading, Box, Text, Divider, Button } from "ui"
import Prefs from "pages/NotificationPrefs"
import Integrations from "pages/settings/Integrations"
import { useMutation } from "react-apollo"
import { UPDATE_PROFILE } from "modules/queries"
import InputWithLabel from "components/Input"
import { UserContext } from "Contexts"
import { useToasts } from "react-toast-notifications"
import { extractGraphqlErrors } from "modules/helpers"
import Loader from "ui/Loader"

const UpdateProfile = () => {
  const { addToast } = useToasts()
  const { user } = useContext(UserContext)
  const [input, setInput] = useState({ name: user && user.name })
  const [updateProfile, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    { variables: { input } }
  )

  useEffect(() => {
    if (data) {
      addToast("Profile updated", { appearance: "success", autoDismiss: true })
    } else if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [data, error])

  if (loading) {
    return <Loader />
  }

  return (
    <Flex width={1} flexDirection="column">
      <Box alignSelf="flex-end">
        <Button onClick={() => updateProfile()}>Save</Button>
      </Box>
      <Box>
        <Text
          mb={2}
          sx={{
            fontWeight: "mid",
            fontSize: [3, 3, 4]
          }}
        >
          Profile
        </Text>
        <Text color="muted">
          Feel free to update your profile details below
        </Text>
      </Box>
      <Box width={1} my={3}>
        <Divider />
      </Box>
      <Box my={3} width={1 / 3}>
        <InputWithLabel
          name="name"
          type="text"
          value={input.name}
          onChange={val => setInput({ ...input, name: val })}
          labelSubText="Will be visible on all comments"
          labelText="Name"
          smallLabel
          minLength={2}
          placeholder="Your name"
        />
      </Box>
    </Flex>
  )
}

const AccountSettings: React.FC<RouteComponentProps> = ({ location }) => (
  <Box width={1}>
    <Heading mb={2} textAlign="center" fontSize={5}>
      Account Settings
    </Heading>
    <Flex width={1} flexDirection="column">
      <Box my={3}>
        <UpdateProfile />
      </Box>
      <Box my={3}>
        <Integrations location={location} />
      </Box>
      <Box my={3}>
        <Prefs showBox={false} />
      </Box>
    </Flex>
  </Box>
)

export default AccountSettings
