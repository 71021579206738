import Cookies from "js-cookie"
import * as R from "ramda"

export interface CreateUserAttrs {
  email: string
  name: string
  consent: any
}

const isKampsiteDomain = R.contains("kampsite.co", window.location.hostname)

class Auth {
  authFlag: string
  accessToken: string | null | undefined
  isSignedIn: boolean | null
  alreadyAuthing: Promise<string> | null

  constructor() {
    this.authFlag = "isLoggedIn"
    this.accessToken = null
    this.oldSignIn = this.oldSignIn.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.isSignedIn = null
    this.alreadyAuthing = null
    this.setSession = this.setSession.bind(this)
    this.signUserIn = this.signUserIn.bind(this)
    this.requestUserToken = this.requestUserToken.bind(this)
    this.createUser = this.createUser.bind(this)
  }

  createUser(attrs: CreateUserAttrs) {
    return fetch(`/api/users`, {
      method: "POST",
      body: JSON.stringify(attrs),
      headers: { "content-type": "application/json" },
      credentials: "same-origin",
    }).then((resp) =>
      resp.status === 200
        ? resp.json()
        : Promise.reject("Failed to create user")
    )
  }

  signUserIn(email: string, auth_code: string) {
    return fetch("/api/users/sign-in", {
      method: "POST",
      body: JSON.stringify({ email, auth_code }),
      headers: { "content-type": "application/json" },
      credentials: "same-origin",
    })
      .then((resp) =>
        resp.status === 200
          ? resp.json()
          : Promise.reject("Failed to authenticate")
      )
      .then(this.setSession)
  }

  requestUserToken(email: string) {
    return fetch(`/api/users/sign-in?email=${email}`).then((res) => res.json())
  }

  isAuthenticated() {
    if (window.location.pathname === "/auth/success") {
      this.isSignedIn = true
      return true
    }
    if (this.isSignedIn !== null) {
      return this.isSignedIn
    }

    const lsVal = JSON.parse(localStorage.getItem(this.authFlag) as string)
    const cVal = JSON.parse(Cookies.get(this.authFlag) || "false")
    this.isSignedIn = lsVal || cVal
    return lsVal || cVal
  }

  oldSignIn() {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, {
      method: "POST",
      body: "",
      headers: { "content-type": "application/json" },
      credentials: "same-origin",
    })
      .then((resp) =>
        resp.status === 200 ? resp.json() : Promise.reject("Unauthed")
      )
      .then(this.setSession)
      .catch((e) => {
        this.signOut()
      })
  }

  signIn() {
    return fetch("/api/auth/refresh", {
      method: "POST",
      body: "",
      headers: { "content-type": "application/json" },
      credentials: "same-origin",
    })
      .then((resp) => {
        return resp.status === 200 ? resp.json() : Promise.reject("Unauthed")
      })
      .then(this.setSession)
      .catch((e) => {
        return this.oldSignIn()
      })
  }

  setSession(authResult) {
    this.alreadyAuthing = null
    this.accessToken = authResult.accessToken
    localStorage.setItem(this.authFlag, JSON.stringify(true))
    Cookies.set(this.authFlag, JSON.stringify(true), {
      domain: ".kampsite.co",
      expires: 800,
      secure: false,
    })
    this.isSignedIn = true
    return authResult.accessToken
  }

  signOut() {
    localStorage.setItem(this.authFlag, JSON.stringify(false))
    Cookies.remove(this.authFlag, { domain: ".kampsite.co" })
    this.isSignedIn = false
  }

  getIdToken() {
    return this.accessToken
  }
}

const auth = new Auth()

export default auth
