import React from "react"
import styled from "ui/styled"
import { Button, Text, Flex, Box } from "ui"
import * as R from "ramda"

const SUpload = styled.input`
  display: none;
`

interface Props {
  value: File | null
  onSelected?: (file: any) => any
  error?: string
}

const Upload = (props: Props) => (
  <Box cursor="pointer">
    <SUpload
      accept="image/*"
      id="contained-button-file"
      onChange={(e) => props.onSelected(e.target.files[0])}
      type="file"
    />
    <label htmlFor="contained-button-file">
      <Flex flexDirection="column">
        <Flex alignItems="center" width={1}>
          <Button
            sx={{ fontWeight: "mid", height: "40px" }}
            variant="primary"
            as="span"
          >
            Upload
          </Button>
          {props.value && (
            <Text ml={2} sx={{ fontWeight: "mid" }}>
              {R.take(
                30,
                ((props.value.name || props.value) as unknown) as any
              )}
              ...
            </Text>
          )}
        </Flex>
        {props.error && (
          <Text my={2} color="danger">
            {props.error}
          </Text>
        )}
      </Flex>
    </label>
  </Box>
)

export default Upload
