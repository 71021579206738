import { RouteComponentProps } from "@reach/router"
import { PageContext } from "Contexts/PageContext"
import { Page, PagePrefs } from "Contexts/types"
import { extractGraphqlErrors } from "modules/helpers"
import { UPDATE_PAGE_PREFS } from "modules/queries"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Button, Checkbox, Divider, Flex, Heading, Label, Text } from "ui"
import { defaultPrefs } from "./helpers"

const questionPrefs = ["wouldPay"]
const getQuestionsFromPage = (page: Page) =>
  R.pipe(defaultPrefs, R.pick(questionPrefs))(page)

const SurveyQuestions: React.FC<RouteComponentProps & { page: Page }> = ({
  page
}) => {
  const { addToast } = useToasts()
  const prefs: PagePrefs = getQuestionsFromPage(page)
  const [questions, setQuestions] = useState(prefs)
  const [updatePrefs, { loading, error, data }] = useMutation(
    UPDATE_PAGE_PREFS,
    {
      variables: {
        pageId: page && page.id,
        prefs: questions
      }
    }
  )

  useEffect(() => {
    if (page) {
      setQuestions(getQuestionsFromPage(page))
    }
  }, [page])

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
    if (data) {
      addToast("Settings updated", { appearance: "success" })
    }
  }, [data, error, addToast])

  return (
    <Flex py={3} flexDirection="column">
      <Flex mb={4} flexDirection="column">
        <Heading mt={3} mb={4} fontSize={4}>
          Questions
        </Heading>
        <Text fontWeight="normal" fontSize={[0, 0, 1]} color="darkyGrey">
          Customize the questions asked when a user submits a suggestion or
          idea. Use these to gain better insights about the underyling problem
          the user is trying to solve.
        </Text>
      </Flex>
      <Flex mb={4} flexDirection="column">
        <Heading sx={{ fontWeight: "regular" }} my={3} fontSize={3}>
          Available Questions
        </Heading>
        <Label>
          <Checkbox
            checked={questions.wouldPay}
            onChange={() =>
              setQuestions({ ...questions, wouldPay: !questions.wouldPay })
            }
            id="wouldPay"
            name="wouldPay"
          />
          Would Pay?
        </Label>
      </Flex>
      <Box mt={3} px={[4, 4, 0, 0]} alignSelf="flex-end">
        <Button
          disabled={loading}
          onClick={() => updatePrefs()}
          size="large"
          variant="primary"
        >
          Save
        </Button>
      </Box>
      <Box my={4}>
        <Divider />
      </Box>
      <Flex mb={4} flexDirection="column">
        <Heading
          sx={{ fontWeight: "regular" }}
          my={3}
          fontSize={3}
          color="muted"
        >
          Coming Soon
        </Heading>
        <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
          Full customization over the questions asked when a user submits a
          suggestion. Don't worry though, we'll also have some highly optimized
          questions from industry experts to help you out as well.
        </Text>
      </Flex>
    </Flex>
  )
}

export default SurveyQuestions
