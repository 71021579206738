import { createLink } from "apollo-absinthe-upload-link"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { Observable } from "apollo-link"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { ServerError } from "apollo-link-http-common"
import auth from "modules/auth"
import React from "react"
import { ApolloProvider } from "react-apollo"

const api = `/api/graph`
const uploadLink = createLink({ uri: api, credentials: "same-origin" })

const authLink = setContext(async (_, { headers }) => {
  const token = auth.getIdToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const refreshLink = onError(({ networkError, operation, forward }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    return new Observable((observer) => {
      requestRefreshToken()
        .then((token) => {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${token || auth.getIdToken()}`,
            },
          }))
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }

          forward(operation).subscribe(subscriber)
        })
        .catch((error) => {
          observer.error(error)
        })
    })
  }
})

const requestRefreshToken = async () => auth.signIn()

const client = new ApolloClient({
  link: authLink.concat(refreshLink).concat(uploadLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
})

const Apollo = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

export default Apollo
