import { Link } from "@reach/router"
import Markdown from "components/Markdown"
import { toPostedAt } from "modules/helpers"
import { MenuButton, MenuItem } from "pages/Features/FeatureRequest/Actions"
import ThreeDots from "pages/Features/FeatureRequest/Actions/ThreeDots"
import React, { useState } from "react"
import {
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share"
import Popover from "react-tiny-popover"
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  Link as RLink
} from "ui"
import { ViewFinishedFeatureProps } from "../types"

const NewFeaturePill = () => (
  <Box
    height="21px"
    px={2}
    pb="2px"
    bg="danger"
    borderRadius="12px"
    width="110px"
  >
    <Flex width={1} justifyContent="center">
      <Text color="white" fontSize={1}>
        New Feature
      </Text>
    </Flex>
  </Box>
)

const ShareButtons = () => (
  <Flex justifyContent="space-between">
    <Box mx={2}>
      <TwitterShareButton url={window.location.href} translate={"yes"}>
        <TwitterIcon size={32} round={true} crossOrigin="anonymous" />
      </TwitterShareButton>
    </Box>
    <Box mx={2}>
      <EmailShareButton url={window.location.href} translate={"yes"}>
        <EmailIcon size={32} round={true} crossOrigin="anonymous" />
      </EmailShareButton>
    </Box>
  </Flex>
)

const Header = ({ title, added, navigate, canManagePage }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const openCloseMenu = () => setMenuOpen(!menuOpen)
  return (
    <Flex flexDirection="column">
      <Box mb={3} width={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <NewFeaturePill />
          <Flex alignItems="center">
            <ShareButtons />
            {canManagePage && (
              <Box mx={2}>
                <Actions
                  navigate={navigate}
                  menuOpen={menuOpen}
                  openCloseMenu={openCloseMenu}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box mb={3}>
        <Heading fontSize="24px" sx={{ fontWeight: "mid" }}>
          {title}
        </Heading>
      </Box>
      <Box>
        <Text color="muted">Added {toPostedAt(added)}</Text>
      </Box>
    </Flex>
  )
}

export const FeatureImage = ({
  imageUrl,
  onLoad
}: {
  imageUrl: string
  onLoad?: any
}) => (
  <Flex justifyContent="center" width={1}>
    <RLink target="_blank" href={imageUrl}>
      <Image
        sx={{ borderRadius: "8px", boxShadow: "mid" }}
        maxHeight={400}
        src={imageUrl}
        onLoad={onLoad}
      />
    </RLink>
  </Flex>
)

const Description = ({ description, whyBuilt }) => (
  <Flex flexDirection="column">
    <Box width={1}>
      <Heading fontSize={3} sx={{ fontWeight: "mid" }}>
        Description
      </Heading>
      <Box color="darkGrey" my={3}>
        <Markdown text={description} />
      </Box>
    </Box>
    {whyBuilt && (
      <Box width={1} mt={3}>
        <Heading fontSize={3} sx={{ fontWeight: "mid" }}>
          Why did we make this change?
        </Heading>
        <Box color="darkGrey" my={3}>
          <Text color="darkGrey" lineHeight="32px">
            {whyBuilt}
          </Text>
        </Box>
      </Box>
    )}
  </Flex>
)

const SuggestionButton = ({ reqId }) => (
  <Flex justifyContent="center">
    <Link to={`/suggestions/${reqId}`}>
      <Button py="12px">View Suggestion</Button>
    </Link>
  </Flex>
)

const Actions = ({ menuOpen, openCloseMenu, navigate }) => (
  <Popover
    isOpen={menuOpen}
    position="bottom"
    containerStyle={{
      overflow: "visible"
    }}
    onClickOutside={openCloseMenu}
    content={() => (
      <Box
        border="1px solid #f1f1f1"
        boxShadow="mid"
        bg="white"
        py={3}
        borderRadius={16}
      >
        <Flex flexDirection="column">
          <MenuItem
            onClick={() => {
              openCloseMenu()
              navigate("edit")
            }}
          >
            Edit
          </MenuItem>
        </Flex>
      </Box>
    )}
  >
    <MenuButton onClick={openCloseMenu}>
      <ThreeDots />
    </MenuButton>
  </Popover>
)

const FinishedFeature = ({
  finishedFeature,
  reqId,
  navigate,
  canManagePage
}: ViewFinishedFeatureProps) => (
  <Box
    p={4}
    bg="white"
    borderRadius={16}
    boxShadow="card"
    border="1px solid #f1f1f1"
    width="725px"
  >
    <Flex flexDirection="column">
      <Box width={1}>
        <Header
          canManagePage={canManagePage}
          navigate={navigate}
          title={finishedFeature.title}
          added={finishedFeature.added}
        />
      </Box>
      {finishedFeature.imageUrl && (
        <Box mt={5} mb={4}>
          <FeatureImage imageUrl={finishedFeature.imageUrl} />
        </Box>
      )}
      <Box mb={3} mt={4}>
        <Description
          description={finishedFeature.description}
          whyBuilt={finishedFeature.whyBuilt}
        />
      </Box>
      <Box width={1}>
        <Divider />
      </Box>
      <Box width={1} mt={4}>
        <SuggestionButton reqId={reqId} />
      </Box>
    </Flex>
  </Box>
)

export default FinishedFeature
