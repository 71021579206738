import { ApolloError } from "apollo-client"
import formatRelative from "date-fns/formatRelative"
import * as R from "ramda"

export const notNil = R.compose(R.not, R.isNil)

export const capitalize = R.converge(R.concat, [
  R.compose(R.toUpper, R.head),
  R.tail
])

export const toTitleCase = R.compose(
  R.join(" "),
  R.map(capitalize),
  R.split(" ")
)

export const extractGraphqlErrors = (error: ApolloError): string[] =>
  R.pipe(
    R.prop("graphQLErrors"),
    R.map<{ message: string }, string>(R.prop("message"))
  )(error)

export const toPostedAt = (dateTime: string) =>
  capitalize(formatRelative(new Date(dateTime), new Date()))
