import React from "react"
import { Box, Flex, Text } from "ui"

const FilterTab = ({
  selected,
  text,
  onChange,
  color,
  number,
  borderRight,
}: {
  number: number
  color: string
  text: string
  selected: boolean
  onChange: any
  borderRight?: boolean
}) => (
  <Flex

    flexDirection={["column-reverse", "row"]}
    justifyContent="center"
    alignItems="center"
    py={2}
    px={3}
    onClick={onChange}
    flexWrap="wrap"
    sx={{
      fontWeight: selected ? "mid" : "regular",
      color: selected ? "darkerGrey" : "muted",
      borderRight: borderRight ? "1px solid #f1f1f1" : "none",
      ...{
        ":hover": {
          cursor: "pointer",
          color: "darkerGrey",
          "> div": {
            fontWeight: "mid",
          },
        },
      },
    }}
  >
    <Text
      fontSize={[1, "15px", 2, 2]}
      textAlign="center"
      sx={{
        ":hover": { fontWeight: 600 },
        fontWeight: selected ? "mid" : "regular",
      }}
    >
      {text}
    </Text>
    <Box
      ml={[0, 3]}
      padding={2}
      borderRadius={"16px"}
      mb={[1, 0]}
      height="23px"
      color={color}
      fontSize={0}
      bg={`${color}Lightest`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {number}
    </Box>
  </Flex>
)

export default FilterTab
