import React from "react"
import { Box, Button, Checkbox, Divider, Flex, Label, Link, Text } from "ui"

const Prefs = ({
  prefs,
  setPrefsVal,
  onSubmit,
  buttonBottom,
  showInitialOnly = false
}) => (
  <Flex flexDirection="column" width={1}>
    {!buttonBottom && (
      <Box alignSelf="flex-end">
        <Button onClick={onSubmit}>Save</Button>
      </Box>
    )}
    <Box>
      <Text
        mb={2}
        sx={{
          fontWeight: "mid",
          fontSize: [3, 3, 4]
        }}
      >
        Email Preferences
      </Text>
      <Text color="muted">
        Because we hate spam as much as you, choose your preferred communication
        settings below.
      </Text>
    </Box>
    <Box width={1} my={3}>
      <Divider />
    </Box>
    {!showInitialOnly && (
      <>
        <Text
          my={3}
          sx={{
            fontWeight: "mid",
            fontSize: [2, 2, 3]
          }}
        >
          Page updates
        </Text>
        <Box my={2}>
          <Label>
            <Checkbox
              checked={prefs.emails}
              value={prefs.emails}
              onChange={setPrefsVal("emails")}
              id="emails"
              name="emails"
            />
            Get emailed when a new suggestion or comment is made
          </Label>
        </Box>
        <Text
          my={3}
          sx={{
            fontWeight: "mid",
            fontSize: [2, 2, 3]
          }}
        >
          Kampsite Email Preferences
        </Text>{" "}
      </>
    )}
    <Box my={2}>
      <Label sx={{ "> div > svg": { color: "grey !important" } }}>
        <Checkbox
          checked={prefs.essential}
          value={prefs.essential}
          onChange={setPrefsVal("essential")}
          id="essential"
          disabled
          name="essential"
        />
        For essential communication e.g. account issues
      </Label>
    </Box>
    <Box my={2}>
      <Label>
        <Checkbox
          checked={prefs.productUpdates}
          value={prefs.productUpdates}
          onChange={setPrefsVal("productUpdates")}
          id="productUpdates"
          name="product_updates"
        />
        When new features are released
      </Label>
    </Box>
    <Box my={2}>
      <Label>
        <Checkbox
          checked={prefs.feedback}
          value={prefs.feedback}
          onChange={setPrefsVal("feedback")}
          id="feedback"
          name="feedback"
        />
        To ask you for feedback on Kampsite
      </Label>
    </Box>
    {buttonBottom && (
      <Box my={3} alignSelf="center">
        <Button onClick={onSubmit}>Save</Button>
      </Box>
    )}
    <Box my={3} width={1}>
      <Text color="muted">
        We use SendGrid to send emails. You can view their privacy policy{" "}
        <Link
          sx={{ textDecoration: "none" }}
          href="https://sendgrid.com/policies/privacy/"
        >
          {" "}
          here.
        </Link>
      </Text>
    </Box>
  </Flex>
)

export default Prefs
