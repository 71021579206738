import { Textarea } from "@rebass/forms"
import styled from "./styled"

const MyTextArea = styled(Textarea)`
  border: none;
  background: white;
  box-shadow: ${(props) => props.theme.shadows.input};
  border-radius: 16px;
  padding: 16px;
  font-size: ${(props) => props.theme.fontSizes[3]};
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Avenir, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Helvetica,
    sans-serif;

  &:focus {
    outline: none;
  }
`

export default MyTextArea
