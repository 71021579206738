import { RouteComponentProps, navigate } from "@reach/router"
import { CREATE_FINISHED_FEATURE, GET_FEATURE_LITE } from "modules/queries"
import Container from "components/Container"
import CreateFinishedFeatureForm from "pages/Features/FinishedFeatures/New/form"
import { FinishedFeatureCreateInput } from "pages/Features/FinishedFeatures/types"
import { IFeatureRequest } from "pages/Features/types"
import React, { useState, useEffect } from "react"
import { useMutation, useQuery } from "react-apollo"
import Loader from "ui/Loader"
import { format } from "date-fns"
import { useToasts } from "react-toast-notifications"
import * as R from "ramda"

const defaultFinishedFeature = (
  req: IFeatureRequest
): FinishedFeatureCreateInput => ({
  title: req.title,
  description: req.description,
  whyBuilt: "",
  added: format(new Date(req.updatedAt), "yyyy-MM-dd"),
  image: null,
  featureRequestId: req.id
})

const NewFinishedFeature: React.FC<{ req: IFeatureRequest }> = ({ req }) => {
  const { addToast } = useToasts()
  const [input, setInput] = useState(defaultFinishedFeature(req))
  const [
    createFinishedFeature,
    { data, loading }
  ] = useMutation(CREATE_FINISHED_FEATURE, { variables: { input } })

  const setField = (field: keyof FinishedFeatureCreateInput) => (val: any) =>
    setInput({ ...input, [field]: val })

  useEffect(() => {
    if (data) {
      const id = R.path(["createFinishedFeature", "id"], data)
      addToast("Showcase Created", { appearance: "success", autoDismiss: true })
      navigate(`/suggestions/${req.id}/feature-showcase/${id}`)
    }
  }, [data])

  return (
    <CreateFinishedFeatureForm
      input={input}
      setInput={setField}
      loading={loading}
      submit={createFinishedFeature}
    />
  )
}

const NewFinishedFeatureContainer: React.FC<RouteComponentProps & {
  reqId?: string
}> = ({ reqId, location }) => {
  const { data } = useQuery(GET_FEATURE_LITE, { variables: { reqId } })

  if (data) {
    return (
      <Container location={location} title="New Feature Showcase">
        <NewFinishedFeature req={data.featureRequest} />
      </Container>
    )
  }

  return <Loader />
}

export default NewFinishedFeatureContainer
