import { Link, RouteComponentProps } from "@reach/router"
import Container from "components/Container"
import { UserContext } from "Contexts"
import useSubscribeUser from "hooks/useSubscribeUser"
import { getKey } from "modules/params"
import { GET_FEATURE } from "modules/queries"
import { isSignedIn, isSubscribedTo } from "modules/user"
import PageUserSignIn from "pages/Auth/PageUser/SignIn"
import FeatureRequest from "pages/Features/FeatureRequest"
import { IFeatureRequest } from "pages/Features/types"
import Comments from "pages/Features/ViewFeature/Comments"
import Duplicate from "pages/Features/ViewFeature/Duplicate"
import NotFound from "pages/Features/ViewFeature/NotFound"
import * as R from "ramda"
import React, { useContext, useState } from "react"
import { useQuery } from "react-apollo"
import { AnimatedBox, Box, Button, Flex, Text, Divider, Heading } from "ui"
import Loader from "ui/Loader"
import styled from "ui/styled"
import { FeatureImage } from "../FinishedFeatures/View/FinishedFeature"
import useBool from "hooks/useBool"
import { toPostedAt } from "modules/helpers"
import Avatar from "components/Avatar"

const notNil = (val: string | null) => val && val !== ""

const subscribedAny = (user, reqId) =>
  isSubscribedTo("FEATURE_REQUEST_COMPLETE", user, reqId) ||
  isSubscribedTo("FEATURE_REQUEST", user, reqId)

const NotifyButton = ({ user, reqId, onClick }) =>
  subscribedAny(user, reqId) ? (
    <Button
      mx={3}
      bg="white"
      frameBorder={"none"}
      color="darkGrey"
      sx={{ ":hover": { cursor: "auto !important" } }}
    >
      Subscribed
    </Button>
  ) : (
    <Button
      mx={3}
      bg="white"
      frameBorder={"none"}
      color="primary"
      onClick={onClick}
    >
      {" "}
      Notify me{" "}
    </Button>
  )

const RLink = styled(Link)`
  text-decoration: none;
  font-weight: ${(props) => props.theme.fontWeights.mid};
  color: ${(props) => props.theme.colors.danger};
`

const showStatusMessage = ({
  status,
  plannedDate,
  rejectedReason,
  isDuplicate,
}: IFeatureRequest) =>
  (status === "accepted" && notNil(plannedDate)) ||
  (status === "planned" && notNil(plannedDate)) ||
  (status === "rejected" && notNil(rejectedReason)) ||
  isDuplicate

const DuplicateMessage = ({ duplicateOfId }) => (
  <>
    <Text sx={{ fontWeight: "mid" }} color={"danger"}>
      This suggestion has been marked as a duplicate of another suggestion
      <span role="img" aria-label="Sad face">
        😢
      </span>{" "}
    </Text>
    <Text color={"danger"}>
      <RLink to={`/suggestions/${duplicateOfId}`}>Click here</RLink> to view the
      suggestion
    </Text>
  </>
)
const StatusMessage = ({ req }: { req: IFeatureRequest }) =>
  showStatusMessage(req) && (
    <Box
      py={2}
      px={3}
      bg={
        req.status === "rejected" && !req.isDuplicate
          ? "dangerLightest"
          : "successLightest"
      }
      width={1}
      borderRadius={8}
    >
      {req.isDuplicate ? (
        <DuplicateMessage duplicateOfId={req.duplicateOfId} />
      ) : req.status === "rejected" ? (
        <>
          <Text sx={{ fontWeight: "mid" }} color={"danger"}>
            Unfortunately this suggestion has been rejected by the creator{" "}
            <span role="img" aria-label="Sad face">
              😢
            </span>{" "}
          </Text>
          <Text color={"danger"}>{req.rejectedReason}</Text>
        </>
      ) : (
        <>
          <Text sx={{ fontWeight: "mid" }} color={"success"}>
            This feature has been {req.status}{" "}
            <span role="img" aria-label="Wooohoooo Emoji">
              🎉
            </span>{" "}
            and is planned for release:
          </Text>
          <Text color={"success"}>{req.plannedDate}</Text>
        </>
      )}
    </Box>
  )

const ViewFeature: React.FC<RouteComponentProps & { reqId?: string }> = ({
  reqId,
  location,
}) => {
  const { data, loading } = useQuery(GET_FEATURE, { variables: { reqId } })
  const { user, canManagePage, activity, updateActivity } = useContext(
    UserContext
  )
  const [showSubscribe, setShowSubscribe] = useState(false)
  const [showDuplicates, setShowDuplicates] = useState(false)
  const [subscribeUser, subscribeResp] = useSubscribeUser(
    "FEATURE_REQUEST_COMPLETE",
    reqId
  )
  const onNotifyMe = () =>
    isSignedIn(user) ? subscribeUser() : setShowSubscribe(true)

  if (loading) {
    return <Loader />
  }
  if (!loading && !data) {
    return (
      <Flex width={1} justifyContent="center">
        <NotFound />
      </Flex>
    )
  }

  const subscribeEmail = getKey("subscribeEmail")(location)

  const req = R.prop("featureRequest", data)

  return (
    <Container location={location} title="Suggestion">
      <PageUserSignIn
        open={showSubscribe}
        onSuccess={subscribeUser}
        onClose={() => setShowSubscribe(false)}
        onSkip={() => setShowSubscribe(false)}
        email={subscribeEmail}
        collectEmail={true}
        collectText={
          "Enter your email below and we'll let you know when this suggestion is complete."
        }
        collectTitle={"Get Notified"}
      />
      <Flex
        maxWidth="725px"
        alignItems="center"
        flexDirection="column"
        mt={2}
        mx="auto"
      >
        <Box my={2} width={1}>
          <StatusMessage req={req} />
        </Box>
        <Box
          mt={3}
          mb={3}
          bg="white"
          borderRadius={16}
          boxShadow="card"
          width={1}
          p={3}
        >
          <Flex flexDirection="column" width={1}>
            <Box my={2} width={1}>
              <FeatureRequest
                canManagePage={canManagePage}
                showComments={false}
                request={req}
                userVoted={R.includes(req.id, activity.voted)}
                updateUserVoted={updateActivity}
              />
            </Box>
            {req.imageUrl && (
              <Box my={3}>
                <FeatureImage imageUrl={req.imageUrl} />
              </Box>
            )}
            <Flex my={3} flexDirection="column" ml="5%">
              <Box width={1}>
                <Text
                  lineHeight={1.8}
                  color="body"
                  sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {req.description}
                </Text>
              </Box>
            </Flex>
            <Flex justifyContent="center" alignItems="center" width={1}>
              {R.includes(req.status, ["pending", "planned", "considered", "accepted"]) && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {subscribeResp.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <NotifyButton
                        user={user}
                        reqId={reqId}
                        onClick={onNotifyMe}
                      />
                      <Text fontSize={0} color="grey">
                        {subscribedAny(user, reqId)
                          ? "You'll receive an email when it's complete"
                          : "When this suggestion is completed"}
                      </Text>
                    </>
                  )}
                </Flex>
              )}
              {R.propEq("status", "completed", req) && req.showcase && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Link
                    to={`/suggestions/${req.id}/feature-showcase/${req.showcase.id}`}
                  >
                    <Button
                      mx={3}
                      bg="white"
                      frameBorder={"none"}
                      color="primary"
                    >
                      View Feature Showcase
                    </Button>
                  </Link>
                  <Text fontSize={0} color="grey">
                    This feature deserves a spotlight
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
        <Flex justifyContent="flex-end" width={1}>
          <Flex justifyContent="center" alignItems="center">
            <Text mr={3} fontSize={0} color="grey">
              <i>Posted by:</i>
            </Text>
            <Avatar
              width={"24px"}
              fontSize={0}
              user={R.prop("submitter", req)}
            />
          </Flex>
          <Flex flexWrap="wrap" my={2} ml={2} alignItems="center">
            <Text mr={3} fontSize={0} color="grey">
              {R.path(["submitter", "name"], req)}
            </Text>
          </Flex>
        </Flex>
        {R.length(req.duplicates) > 0 && (
          <Box ml={2} mt={3} mb={2} width={1}>
            <Text
              mb={2}
              sx={{
                fontWeight: "mid",
                fontSize: [3, 3, 4],
              }}
            >
              Duplicates
            </Text>
            <Text my={3} color="darkGrey" sx={{ whiteSpace: "pre-wrap" }}>
              These are suggestions that have been marked as duplicates of this
              suggestion.
            </Text>
            <Button
              variant="outline"
              fontSize={1}
              onClick={() => setShowDuplicates(!showDuplicates)}
            >
              {showDuplicates ? "Close" : "Show"} Duplicates
            </Button>
            {showDuplicates && (
              <AnimatedBox
                mt={3}
                mb={4}
                p={3}
                key={"duplicates"}
                transition={{
                  when: "beforeChildren",
                  staggerChildren: 1,
                }}
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ y: 0, opacity: 1 }}
              >
                {R.map(
                  (duplicate) => (
                    <AnimatedBox
                      key={duplicate.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <Duplicate key={duplicate.id} duplicate={duplicate} />
                    </AnimatedBox>
                  ),
                  req.duplicates
                )}
              </AnimatedBox>
            )}
          </Box>
        )}
        <Box mt={3} mb={2} width={1}>
          <Text
            ml={2}
            mb={2}
            sx={{
              fontWeight: "mid",
              fontSize: [3, 3, 4],
            }}
          >
            Comments
          </Text>
          <Comments
            featureRequestId={req.id}
            canComment={!req.isDuplicate}
            comments={req.comments}
          />
        </Box>
      </Flex>
    </Container>
  )
}

export default ViewFeature
