//https://codesandbox.io/embed/framer-motion-side-menu-mx2rw?fontsize=14&module=%2Fsrc%2FExample.tsx&codemirror=1
import { Link, navigate, WindowLocation } from "@reach/router"
import Avatar from "components/Avatar"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import { Page, User } from "Contexts/types"
import { motion, useCycle, Variants } from "framer-motion"
import auth from "modules/auth"
import { isAuthedUser } from "modules/user"
import { isPath, pathStartsWith } from "pages/ManagementDashboard/Sidebar"
import * as R from "ramda"
import * as React from "react"
import { useEffect, useRef } from "react"
import { Link as ExtLink } from "rebass"
import { Box, Divider, Flex, Text } from "ui"
import styled from "ui/styled"
import {
  ContactIcon,
  DashboardIcon,
  IntegrationsIcon,
  LinkIcon,
  PageSettingsIcon,
  QuestionIcon,
  SettingsIcon,
  UserIcon,
  BillingIcon,
  LabelIcon,
} from "./Icons"
import "./styles.css"
import NewPill from "components/NewPill"

export const ExtRouteLink = styled(ExtLink)<{ selected: boolean }>`
  margin-bottom: 48px;
  text-decoration: none;
  width: 100%;
  color: ${(props) =>
    props.selected ? props.theme.colors.primary : props.theme.colors.muted};
  font-weight: ${(props) =>
    props.selected
      ? props.theme.fontWeights.mid
      : props.theme.fontWeights.regular}};
  font-weight: 500;

  ${(props) =>
    props.selected
      ? `div > svg > path { fill: ${props.theme.colors.primary}}`
      : ""};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.fontWeights.mid}};
  }
`

export const RouteLink = styled(Link)<{ selected: boolean }>`
  margin-bottom: 48px;
  text-decoration: none;
  width: 100%;

  color: ${(props) =>
    props.selected ? props.theme.colors.primary : props.theme.colors.muted};
  font-weight: ${(props) =>
    props.selected
      ? props.theme.fontWeights.mid
      : props.theme.fontWeights.regular}};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.fontWeights.mid}};
  }
`

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useDimensions = (ref) => {
  const dimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth
    dimensions.current.height = ref.current.offsetHeight
  }, [])

  return dimensions.current
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    opacity: 1,
    display: "block",
  },
  closed: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

interface NavigationProps {
  location: WindowLocation
  toggleOpen: () => any
  user: User
  showManage: boolean
  page: Page
}

export const Navigation = ({
  location,
  toggleOpen,
  showManage,
  user,
  page,
}: NavigationProps) => {
  const checkSelected = isPath(location)

  return (
    <Box width={1}>
      <motion.ul className="ul" variants={variants as Variants}>
        <ItemWrapper toggleOpen={() => null}>
          <Box mx={3} mt={3}>
            <Flex alignItems="center">
              <Avatar width={"32px"} user={user} />
              <Text
                mx={3}
                fontSize={2}
                color="darkGrey"
                sx={{ fontWeight: "regular" }}
              >
                {isAuthedUser(user) ? user.name : "Guest User"}
              </Text>
            </Flex>
          </Box>
        </ItemWrapper>
        <ItemWrapper toggleOpen={() => null}>
          <Flex my={2} justifyContent="center" width={1}>
            {isAuthedUser(user) ? (
              <Text
                onClick={() => {
                  auth.signOut()
                  window.location.href = window.location.origin
                }}
                mx={3}
                fontSize={0}
                color="muted"
                sx={{ fontWeight: "regular" }}
              >
                Sign out
              </Text>
            ) : (
              <Box>
                <Flex my={"24px"} justifyContent="center" width={1}>
                  <Divider width={"60%"} />
                </Flex>
                <Text
                  onClick={() => navigate("https://app.kampsite.co/manage")}
                  mx={3}
                  fontSize={0}
                  color="muted"
                  sx={{ fontWeight: "regular" }}
                >
                  Manage Page
                </Text>
              </Box>
            )}
          </Flex>
        </ItemWrapper>
        {showManage && (
          <>
            <ItemWrapper toggleOpen={() => null}>
              <Flex my={"24px"} justifyContent="center" width={1} mx={3}>
                <Divider width={"40%"} />
              </Flex>
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="Dashboard"
                route="/manage"
                selected={checkSelected("manage") || checkSelected("")}
                icon="dashboard"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="Page Settings"
                route="/manage/page"
                selected={checkSelected("manage/page")}
                icon="page_settings"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <Flex alignItems="flex-start">
                <NavMenuItem
                  text="Labels"
                  route="/manage/labels"
                  selected={checkSelected("manage/labels")}
                  icon="labels"
                />
                <NewPill />
              </Flex>
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="Questions"
                route="/manage/questions"
                selected={checkSelected("manage/questions")}
                icon="question"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <Flex alignItems="flex-start">
                <NavMenuItem
                  text="Users"
                  route="/manage/users"
                  selected={checkSelected("manage/users")}
                  icon="users"
                />
                <NewPill />
              </Flex>
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="Account"
                route="/manage/account"
                selected={checkSelected("manage/account")}
                icon="account"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <Flex alignItems="flex-start">
                <NavMenuItem
                  text="Billing"
                  route="/manage/billing"
                  selected={checkSelected("manage/billing")}
                  icon="billing"
                />
                <NewPill />
              </Flex>
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="Contact"
                route="/manage/support"
                selected={checkSelected("manage/support")}
                icon="contact"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <NavMenuItem
                text="View Page"
                route={`https://${page && page.subdomain}.kampsite.co`}
                selected={
                  checkSelected("/") || pathStartsWith(location)("suggestions")
                }
                icon="link"
              />
            </ItemWrapper>
            <ItemWrapper toggleOpen={() => null}>
              <Flex my={"24px"} justifyContent="center" width={1} mx={3}>
                <Divider width={"40%"} />
              </Flex>
            </ItemWrapper>
            <ItemWrapper toggleOpen={toggleOpen}>
              <Flex width={1} justifyContent="center">
                <ExtLink
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                  href="https://feedback.kampsite.co"
                >
                  <Text fontSize={0} color="darkGrey">
                    <i>Feedback</i>
                  </Text>
                </ExtLink>
              </Flex>
            </ItemWrapper>
          </>
        )}
        <ItemWrapper toggleOpen={toggleOpen}>
          <Flex width={1} justifyContent="center">
            <ExtLink
              mt={3}
              target="_blank"
              sx={{ textDecoration: "none" }}
              href="https://kampsite.co/privacy-policy"
            >
              <Text fontSize={0} color="darkGrey">
                <i>Privacy policy</i>
              </Text>
            </ExtLink>
          </Flex>
        </ItemWrapper>
      </motion.ul>
    </Box>
  )
}

const selectIcon = (icon: string, selected: boolean) =>
  R.cond([
    [R.equals("dashboard"), R.always(<DashboardIcon selected={selected} />)],
    [R.equals("account"), R.always(<SettingsIcon selected={selected} />)],
    [R.equals("contact"), R.always(<ContactIcon selected={selected} />)],
    [
      R.equals("page_settings"),
      R.always(<PageSettingsIcon selected={selected} />),
    ],
    [
      R.equals("integrations"),
      R.always(<IntegrationsIcon selected={selected} />),
    ],
    [R.equals("link"), R.always(<LinkIcon />)],
    [R.equals("question"), R.always(<QuestionIcon selected={selected} />)],
    [R.equals("users"), R.always(<UserIcon selected={selected} />)],
    [R.equals("billing"), R.always(<BillingIcon selected={selected} />)],
    [R.equals("labels"), R.always(<LabelIcon selected={selected} />)],
  ])(icon)

const ItemWrapper = ({ toggleOpen, children }) => (
  <motion.li
    onClick={toggleOpen}
    className="li"
    variants={variants2}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.li>
)

const IntOrExtRoute = ({ to, selected, children }) =>
  R.startsWith("http", to) ? (
    <ExtRouteLink selected={selected} href={to}>
      {children}
    </ExtRouteLink>
  ) : (
    <RouteLink to={to} selected={selected}>
      {children}
    </RouteLink>
  )

export const NavMenuItem = ({
  selected,
  route,
  icon,
  text,
}: {
  text: string
  selected: boolean
  route: string
  icon: string
}) => (
  <IntOrExtRoute to={route} selected={selected}>
    <Flex
      sx={{
        "&:hover": {
          "div > svg > path": {
            fill: "primary",
          },
        },
      }}
    >
      {selected && (
        <Box width="5px" borderRadius="0px 100px 100px 0px" bg="primary" />
      )}
      <Box mx={3}>{selectIcon(icon, selected)}</Box>
      {text}
    </Flex>
  </IntOrExtRoute>
)

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="round"
    stroke="#9d9da7"
    {...props}
  />
)

export const MenuToggle = ({ toggle, top, left }) => (
  <button
    style={{ top, left, position: "absolute" }}
    className="sidebar-button"
    onClick={toggle}
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
)

const variants2 = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"]

export const MenuItem = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` }
  return (
    <motion.li
      className="li"
      variants={variants2}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="icon-placeholder" style={style} />
      <div className="text-placeholder" style={style} />
    </motion.li>
  )
}

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 3 + 200}px at 40px 40px)`,
    background: "white",
    height:
      Math.max(
        window.innerHeight,
        document.body.offsetHeight,
        document.body.clientHeight,
        document.documentElement.scrollHeight
      ) + "px",
    bottom: 0,
    boxShadow:
      "0 0 1px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06)",
    transition: {
      duration: 0.8,
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transitionEnd: {
      height: "50px",
      bottom: "unset",
      background: "none",
    },
    transition: {
      delay: 0.1,
      duration: 0.8,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

interface MenuProps {
  location: WindowLocation
  top?: string
  left?: string
}

const HamburgerMenu = ({
  location,
  top = "32px",
  left = "16px",
}: MenuProps) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)
  const { canManagePage, user } = React.useContext(UserContext)
  const { page } = React.useContext(PageContext)

  return (
    <motion.nav
      className="nav"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={{
        open: { bottom: 0 },
        closed: { transitionEnd: { bottom: "unset" } },
      }}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation
        page={page}
        user={user}
        showManage={canManagePage}
        toggleOpen={toggleOpen}
        location={location}
      />
      <MenuToggle toggle={toggleOpen} top={top} left={left} />
    </motion.nav>
  )
}

export default HamburgerMenu
