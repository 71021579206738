import { RouteComponentProps } from "@reach/router"
import { PageContext } from "Contexts/PageContext"
import { isAfter } from "date-fns"
import { GET_ALL_FEATURES_LITE } from "modules/queries"
import FeatureRequest from "pages/Features/FeatureRequest"
import { IFeatureRequest } from "pages/Features/types"
import AnalyticCard from "pages/ManagementDashboard/Dashboard/AnalyticCard"
import * as R from "ramda"
import React, { useContext } from "react"
import { useQuery } from "react-apollo"
import { Box, Flex, Heading, Image } from "ui"
import Loader from "ui/Loader"
import illustration from "../../Features/FeatureList/Illustration.svg"

const Illustration = () => <Image width="80%" src={illustration} />

const ManagementDashboard: React.FC<RouteComponentProps> = () => {
  const { subdomain } = useContext(PageContext)
  const { data, loading } = useQuery(GET_ALL_FEATURES_LITE, {
    variables: { subdomain },
  })

  if (loading) {
    return <Loader />
  }

  const requests = R.pathOr([], ["page", "featureRequests"], data)

  const numFeatures = R.length(requests)

  const numCompleted = R.length(
    R.filter(R.where({ status: R.equals("completed") }), requests)
  )

  const toDate = ({ insertedAt }) => new Date(insertedAt)

  const sortDates = (a, b) => (isAfter(toDate(a), toDate(b)) ? -1 : 1)

  const newFeatures = R.pipe(
    R.sort(sortDates),
    // R.take(10)
  )(requests) as IFeatureRequest[]

  const numAccepted = R.length(
    R.filter(R.where({ status: R.equals("accepted") }), requests)
  )
  return (
    <Box mx="auto">
      <Heading mb={2} textAlign="center" fontSize={5}>
        Dashboard
      </Heading>
      <Flex mt={3} sx={{ flexWrap: ["wrap", "nowrap"] }}>
        <Box mr={2} width={[1, 1 / 3]}>
          <AnalyticCard
            color="primary"
            title="Suggestions"
            number={numFeatures}
            subtext="💯 Total suggestions"
          />
        </Box>
        <Box mr={2} mt={[3, 0]} width={[1, 1 / 3]}>
          <AnalyticCard
            color="success"
            title="Pending"
            number={numAccepted}
            subtext="🏗 In progress"
          />
        </Box>
        <Box mt={[3, 0]} width={[1, 1 / 3]}>
          <AnalyticCard
            color="warning"
            title="Completed"
            number={numCompleted}
            subtext="🎉 Well done"
          />
        </Box>
      </Flex>
      <Box mt={4} mb={3}>
        <Heading sx={{ fontWeight: "regular" }} my={3} fontSize={4}>
          Newest features
        </Heading>
        {R.pipe(R.length, R.lt(0))(newFeatures) ? (
          <Flex width={1} flexDirection="column" alignItems="center">
            {R.map((req: IFeatureRequest) => (
              <Box mt={3} width="100%" key={req.id}>
                <FeatureRequest
                  canManagePage={true}
                  navigate={`http://${subdomain}.kampsite.co/suggestions/${req.id}`}
                  userVoted={false}
                  updateUserVoted={() => null}
                  request={req}
                />
                <Box my={3}></Box>
              </Box>
            ))(newFeatures)}
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            mt={[4, 5]}
            width={1}
          >
            <Heading fontSize={[4, 5]} fontWeight="normal" textAlign="center">
              No features submitted yet!
            </Heading>
            <Flex justifyContent="center" width="100%" mt={4}>
              <Illustration />
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default ManagementDashboard
