import React from "react"

const CommentIcon = () => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.95273 14.96C3.95434 15.9541 4.76153 16.7587 5.75564 16.7571C6.15571 16.7565 6.54416 16.6225 6.85964 16.3765L9.8409 14.0516H15.4167C17.0735 14.0516 18.4167 12.7085 18.4167 11.0516V3.40002C18.4167 1.74317 17.0735 0.400024 15.4167 0.400024H3.58335C1.9265 0.400024 0.583353 1.74317 0.583353 3.40002V11.0516C0.583353 12.7085 1.9265 14.0516 3.58335 14.0516H3.95126L3.95273 14.96ZM5.94965 13.05C5.94875 12.4984 5.5013 12.0516 4.94965 12.0516H3.58335C3.03107 12.0516 2.58335 11.6039 2.58335 11.0516V3.40002C2.58335 2.84774 3.03107 2.40002 3.58335 2.40002H15.4167C15.969 2.40002 16.4167 2.84774 16.4167 3.40002V11.0516C16.4167 11.6039 15.969 12.0516 15.4167 12.0516H9.48268C9.19715 12.0516 8.94662 12.1687 8.76765 12.3523L5.95207 14.548L5.94965 13.05Z"
      fill="#FFC542"
    />
  </svg>
)

export default CommentIcon
