import React from "react"
import { Box, Card, Flex, Heading, Text } from "ui"
import {
  UserIcon,
  QuestionIcon,
  ContactIcon,
  SettingsIcon,
} from "../Sidebar/Icons"
import Pill from "components/Pill"

interface CurrentPlanProps {
  plan: "starter" | "plus" | "plus-free"
  users: number
  customDomain: boolean
  maxSuggestions: number
  pages: number
}

const PlanItem = ({ icon, feature, usage, enabled }) => (
  <Flex alignItems="center" width={1}>
    <Box mr={2}>{icon}</Box>
    <Box ml={2} width={1}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text mr={4} fontWeight="bold">
          {feature}
        </Text>
        <Box ml={2}>
          <Pill color={enabled ? "success" : "danger"} text={usage} />
        </Box>
      </Flex>
    </Box>
  </Flex>
)

const CurrentPlan = ({
  plan,
  users,
  customDomain,
  maxSuggestions,
}: CurrentPlanProps) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      flexWrap="wrap"
      p={3}
      height="100%"
    >
      <Flex width={1} justifyContent="center" flexDirection="column">
        <Flex alignItems="center" mb={3}>
          <Heading fontSize={3}>Your current plan: </Heading>
          <Box ml={3}>
            <Pill color="success" text={plan} />
          </Box>
        </Flex>
        <Text color="muted" fontSize={2} mb={3}>
          {plan === "plus"
            ? "You are currently on the plus plan. If you want to cancel your subscription or downgrade your account, click the button below"
            : plan === "starter"
            ? "You are currently on the starter plan, if you'd like to upgrade to the plus plan and get multiple users, custom domains and unlimited suggestions, click the upgrade button below"
            : "You are currently on the plus-free plan, which means you have been upgraded to plus free of charge. If you would like to change your account plan, please email support@kampsite.co"}
        </Text>
      </Flex>
      <Box my={3}>
        <Card boxShadow="mid">
          <Flex flexDirection="column">
            <Box my={2}>
              <PlanItem
                icon={<QuestionIcon selected={true} />}
                feature="Plan"
                usage={
                  plan === "starter"
                    ? "Starter"
                    : plan === "plus"
                    ? "Plus $10/m"
                    : "Plus free"
                }
                enabled
              />
            </Box>
            <Box my={2}>
              <PlanItem
                icon={<UserIcon selected={true} />}
                feature="Max Users"
                usage={users}
                enabled={users > 1}
              />
            </Box>
            <Box my={2}>
              <PlanItem
                icon={<SettingsIcon selected={true} />}
                feature="Custom Domain"
                usage={customDomain ? "Yes" : "No"}
                enabled={customDomain}
              />
            </Box>
            <Box my={2}>
              <PlanItem
                icon={<ContactIcon selected={true} />}
                feature="Max Suggestions"
                usage={maxSuggestions > 9999 ? "Unlimited" : maxSuggestions}
                enabled
              />
            </Box>
          </Flex>
        </Card>
      </Box>
    </Flex>
  )
}

export default CurrentPlan
