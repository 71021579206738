import {Link, navigate, RouteComponentProps, WindowLocation} from "@reach/router"
import Container from "components/Container"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import useBool from "hooks/useBool"
import { GET_FEATURES, GET_FEATURE_COUNTS } from "modules/queries"
import NoSuggestions from "pages/Features/FeatureList/NoSuggestions"
import {FeatureStatusCounts, IFeatureRequest} from "pages/Features/types"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import {AnimatedBox, Box, Button, Flex, Heading, Input, Text} from "ui"
import Loader from "ui/Loader"
import FeatureRequestList from "./FeatureRequestList"
import FilterTab from "./FilterTab"
import Search from "./Search"
import SearchIcon from "components/Search"
import FeatureRequest from "../FeatureRequest";
import UpVote from "../FeatureRequest/UpVote";
import Actions from "../FeatureRequest/Actions";
import LabelPill from "../../../../components/Label";

export const anyCounts = (counts: FeatureStatusCounts) =>
  counts?.pending > 0 || counts?.accepted > 0 || counts?.completed > 0

export const handleNewUpdates = (
  prev: { page: { featureRequests: any[] } },
  { fetchMoreResult }
) => {
  if (!fetchMoreResult) return prev
  return Object.assign({}, prev, {
    page: {
      ...prev.page,
      featureRequests: [...getReqs(prev), ...getReqs(fetchMoreResult)],
    },
  })
}

export const getReqs = (payload): any[] =>
  R.path(["page", "featureRequests"], payload)

export const buildFetchMore = (fetchFunc, reqs) => (page) =>
  fetchFunc({
    variables: {
      offset: reqs.length,
    },
    updateQuery: handleNewUpdates,
  })
export interface DashboardProps extends RouteComponentProps {
  statusFilter

}


const FeatureList: React.FC<DashboardProps> = (props) => {
  const statusFilter = props.statusFilter
  const [showSearch, toggleSearch] = useBool(false)
  const { subdomain, page } = useContext(PageContext)
  const prefs = defaultPrefs(page)
  const { activity, updateActivity, canManagePage } = useContext(UserContext)
  const [filter, updateFilter] = useState(statusFilter)
  const countsResp = useQuery(GET_FEATURE_COUNTS, {
    variables: { subdomain },
  })

  const { data, error, loading, fetchMore } = useQuery(GET_FEATURES, {
    variables: { subdomain, offset: 0, status: filter },
  })

  useEffect(() => {
    updateFilter(statusFilter)
  }, [statusFilter])

  if (error) {
    return null
  }
  const requests = R.pipe(
    R.pathOr([], ["page", "featureRequests"]),
    R.filter(R.propEq("status", filter))
  )(data)

  const counts: FeatureStatusCounts = R.path(
    ["data", "page", "featureRequestsCounts"],
    countsResp
  )

  const setFilter = (newFilter) => {
    updateFilter(newFilter)
    navigate(`/?filter=${newFilter}`)
  }
  const noRequests = {} as IFeatureRequest
  return (

        <Box width="100%" mt={4}>

              <Flex width={1} flexDirection="column" alignItems="center">
                {loading ? (
                  <Loader />
                ) : R.length(requests) > 0 ? (
                  <FeatureRequestList
                    loadMore={buildFetchMore(fetchMore, requests)}
                    totalCount={counts[filter]}
                    userVoted={activity.voted}
                    updateActivity={updateActivity}
                    requests={requests}
                    canManagePage={canManagePage}
                  />
                ) : (
                    <Flex justifyContent="center">

                    <AnimatedBox
                        mb={4}
                        width="100%"
                        bg="white"
                        borderRadius={16}
                        boxShadow="card"
                        p={3}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        key={0}
                        positionTransition
                    >
                      <Flex width="100%" alignItems="center" flexWrap="wrap">

                        <Flex flexDirection="column" flex={"12 0 100%"}>
                          <Flex mb={2} alignItems="center" justifyContent="space-between">
                            <Text width={1} fontSize={[1, 2]} sx={{fontWeight: "regular", fontStyle:"italic", whiteSpace: "nowrap" }}>
                              {'No suggestions yet'}
                            </Text>

                          </Flex>

                        </Flex>

                      </Flex>

                    </AnimatedBox>





                    </Flex>


                )}
              </Flex>
        </Box>
  )
}

export default FeatureList
