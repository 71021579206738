import { navigate } from "@reach/router"
import OTPInput, { ResendOTP } from "otp-input-react"
import React, { useState, useEffect } from "react"
import { Box, Button, Flex, Heading, Text } from "ui"
import styled from "ui/styled"
import * as R from "ramda"

interface VerifyEmailProps {
  onSubmit: (otp: string) => any
  onSkip: () => any
  resendEmail: () => any
  loading: boolean
  error: string | null
  email: string
}

const BigButton = styled(Button)`
  width: 100%;
`
const renderButton = (buttonProps: any) => {
  return (
    <Text
      onClick={buttonProps.disabled ? () => null : buttonProps.onClick}
      fontSize={0}
      color="darkGrey"
    >
      {buttonProps.remainingTime !== 0
        ? `Expires in ${buttonProps.remainingTime} sec`
        : "Resend"}
    </Text>
  )
}

const renderTime = () => React.Fragment
const VerifyEmail = ({
  onSubmit,
  onSkip,
  resendEmail,
  loading,
  error,
  email
}: VerifyEmailProps) => {
  const [otp, setOtp] = useState("")

  useEffect(() => {
    otp.length === 6 && onSubmit(otp)
  }, [otp])

  return (
    <Flex flexDirection="column">
      <Flex width={1} justifyContent="center" mb={2} mt={3}>
        <Heading color="darkerGrey">Claim your account</Heading>
      </Flex>
      <Box my={2}>
        <Text fontSize={1} color="darkGrey" textAlign="center">
          Look - we know it's a pain, but in order to verify you own the email
          you've entered, we need to you enter the 6 digit code we just sent to{" "}
          <span style={{ fontWeight: "bold" }}>{email}.</span>
        </Text>
      </Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        my={3}
        width={1}
      >
        <Box width={[1, 9 / 10, 8 / 10]}>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            width={1}
          >
            {error && (
              <Box mb={2}>
                <Text color="danger" fontWeight="bold">
                  {error}
                </Text>
              </Box>
            )}
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus={true}
              OTPLength={6}
              otpType="number"
              disabled={false}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
              inputStyles={{
                borderStyle: "solid",
                marginRight: "0",
                borderColor: "rgb(178, 178, 184)",
                borderRadius: "8px",
                fontSize: "20px",
                ...(error ? { borderColor: "red" } : {})
              }}
            />
            <Box my={2}>
              <ResendOTP
                maxTime={300}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={resendEmail}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        my={2}
        width={1}
      >
        <BigButton
          disabled={loading}
          onClick={() => onSubmit(otp)}
          fontSize={3}
        >
          {loading ? "Loading...." : "Verify"}
        </BigButton>
        <Box my={2}>
          <Text
            onClick={onSkip}
            sx={{ "&:hover": { cursor: "pointer", fontWeight: "bold" } }}
            color="darkGrey"
            fontSize={1}
          >
            Skip
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default VerifyEmail
