import useDebounce from "modules/hooks/useDebounce"
import SearchIcon from "pages/Features/FeatureList/SearchIcon"
import { IFeatureRequest } from "pages/Features/types"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { Box, Flex, Input } from "ui"

export const escapeRegExp = (query: string) =>
  query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")

export const filterSearch = R.curry(
  (query: string, { title }: IFeatureRequest) =>
    R.pipe(
      R.isNil,
      R.not
    )(R.toLower(title).match(R.toLower(escapeRegExp(query))))
)

const SuggestionSearch = ({ value, onChange }: any) => {
  const [val, setVal] = useState(value)
  const debouncedValue = useDebounce(val, 300)
  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue, onChange])
  return (
    <Flex alignItems="center">
      <Box mr="-40px" width="100%">
        <Input
          onChange={e => setVal(e.target.value)}
          value={val}
          sx={{ position: "relative" }}
          placeholder="Search suggestions"
        />
      </Box>
      <Box bg="primary" borderRadius={8} p={2} zIndex={2}>
        <Box height="20px">
          <SearchIcon />
        </Box>
      </Box>
    </Flex>
  )
}

export default SuggestionSearch
