import { navigate, RouteComponentProps } from "@reach/router"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import { PagePrefs, User } from "Contexts/types"
import { extractGraphqlErrors } from "modules/helpers"
import {
  CREATE_NEW_SUGGESTION,
  GET_ME,
  SUBSCRIBE_USER_TO,
  GET_FEATURES,
} from "modules/queries"
import { isGuest, isSignedIn } from "modules/user"
import PageUserSignIn from "pages/Auth/PageUser/SignIn"
import Container from "components/Container"
import NewSuggestionForm from "pages/Features/MutateFeature/form"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useContext, useEffect, useState, useCallback } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import uuidv4 from "uuid/v4"

const consentPath = ["submitter", "consent"]
const buildSubmitPayload = (input) => ({
  ...R.assocPath(
    consentPath,
    JSON.stringify(R.path(consentPath, input)),
    input
  ),
  labels: R.map(R.prop("id"), input.labels),
})

const getReqidFromResp = (data: any): string =>
  R.path(["createRequest", "id"], data || {})

const storeEmailPath = ["submitter", "consent", "storeEmail"]

const defaultInput = (user: User, prefs: PagePrefs) => ({
  description: "",
  pageId: "",
  image: null,
  labels: [],
  submitter: {
    email: user.email || "",
    id: user && user.id,
    name: (user && user.name) || "anonymous",
    editToken: uuidv4(),
    consent: {
      storeName: false,
      storeEmail: false,
    },
  },
  title: "",
  ...(prefs.wouldPay ? { wouldPay: false } : {}),
})

const bannedImages = [
  "34f108e4-91bf-4799-8cd5-548eb9d2c2f4",
  "b5925802-3eb3-44f9-9877-8bd5fd34abf8",
]

const NewFeature: React.FC<RouteComponentProps> = (props) => {
  const { addToast } = useToasts()
  const { page, labels } = useContext(PageContext)
  const prefs = defaultPrefs(page)
  const { user, updateActivity, canManagePage } = useContext(UserContext)
  const [input, setInput] = useState(defaultInput(user, prefs))
  const [showSignIn, setShowSignIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [addSuggestion, { loading, error, data }] = useMutation(
    CREATE_NEW_SUGGESTION,
    {
      variables: { input: buildSubmitPayload(input) },
      refetchQueries: [
        {
          query: GET_FEATURES,
          variables: {
            subdomain: page && page.subdomain,
            status: "pending",
            offset: 0,
          },
        },
      ],
    }
  )

  const canUploadImage = page ? !bannedImages.includes(page.id) : true

  const labelsToShow = canManagePage
    ? labels
    : R.filter(R.propEq("type", "PUBLIC"), labels)

  const [subscribeUser, _] = useMutation(SUBSCRIBE_USER_TO, {
    variables: {
      entity: "FEATURE_REQUEST",
      entId: R.path(["createRequest", "id"], data),
    },
    refetchQueries: [{ query: GET_ME, variables: { pageId: page && page.id } }],
    awaitRefetchQueries: true,
  })

  // Spent an hour with this edge case and gave up.
  // Basically, trying to pass the navigate func to the page users
  // onClose prop with the ID from the created suggestion would never
  // change and always be the inital rendered version (e.g. null).
  // Therefore doing this for my sanity. It's shit, there's probably
  // a cleaner way.
  const navigateToSuggestion = () => setRedirect(true)
  useEffect(() => {
    data && redirect && navigate(`suggestions/${getReqidFromResp(data)}/`)
  }, [redirect, data])

  useEffect(() => {
    page && setInput({ ...input, pageId: page.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    const id = getReqidFromResp(data)
    if (data && id) {
      updateActivity({
        type: "createdSuggestion",
        data: { reqId: id, token: input.submitter.editToken },
      })
      updateActivity({ type: "voted", data: id })
      addToast("Suggestion Added", { appearance: "success", autoDismiss: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateActivity])

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [error, addToast])

  const needsSignIn = isGuest(user) && input.submitter.email

  const addAndSubscribe = () =>
    addSuggestion()
      .then(({ data, errors }) => {
        if (getReqidFromResp(data)) {
          subscribeUser()
          return data
        }
      })
      .catch(() => false)

  const addDontSubscribe = () =>
    addSuggestion()
      .then(({ data }) => {
        if (getReqidFromResp(data)) {
          navigate(`/suggestions/${getReqidFromResp(data)}`)
        }
      })
      .catch(() => false)

  const onSubmit = () =>
    needsSignIn
      ? setShowSignIn(true)
      : isSignedIn(user)
      ? addAndSubscribe().then(
          (data) => data && navigate(`/suggestions/${getReqidFromResp(data)}`)
        )
      : addDontSubscribe()

  const onClose = () => navigate(`/suggestions/${getReqidFromResp(data)}`)

  return (
    <Container title="Add a suggestion" location={props.location}>
      {showSignIn && (
        <PageUserSignIn
          email={input.submitter.email}
          open={showSignIn}
          onSuccess={addAndSubscribe}
          onClose={navigateToSuggestion}
          onSkip={addDontSubscribe}
          consented={R.path(storeEmailPath, input)}
        />
      )}
      <NewSuggestionForm
        canUploadImage={canUploadImage}
        labels={labelsToShow}
        user={user}
        submitForm={onSubmit}
        setInput={setInput}
        input={input}
        loading={loading}
      />
    </Container>
  )
}

export default NewFeature
