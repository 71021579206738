import { AuthContext } from "Contexts/Auth"
import {
  ActivityData,
  IUserContext,
  Page,
  UpdateUserActivity,
  User,
} from "Contexts/types"
import useMe from "hooks/useMe"
import { pagesForUser } from "modules/user"
import * as R from "ramda"
import React, { useContext, useEffect, useReducer, useState } from "react"
import Box from "ui/Button"
import Loader from "ui/Loader"
import { PageContext } from "./PageContext"
import uuid from "uuid"

declare global {
  interface Window {
    delighted: any
  }
}

const checkCanManagePage = (userPages: Page[] | null | undefined, page: Page) =>
  page && !!userPages ? R.any(R.propEq("id", page.id), userPages) : false

const defaultUser: IUserContext = {
  user: {
    type: "guest",
    id: null,
    name: "anonymous",
    organisations: [],
    email: "",
    notificationPreferences: null,
  },
  activity: { voted: [], createTokens: [] },
  fetchingUser: false,
  canManagePage: false,
}

export const UserContext = React.createContext<IUserContext>(defaultUser)

const isType = (type: string) => R.pipe(R.prop("type"), R.equals(type))

const updateState = (state: ActivityData) => (
  action: UpdateUserActivity
): ActivityData =>
  R.cond([
    [
      isType("voted"),
      () => ({ ...state, voted: [...(state.voted || []), action.data] }),
    ],
    [
      isType("unvoted"),
      () => ({
        ...state,
        voted: R.reject(R.equals(action.data), state.voted || []),
      }),
    ],
    [
      isType("createdSuggestion"),
      () => ({
        ...state,
        createTokens: [...(state.createTokens || []), action.data],
      }),
    ],
  ])(action)

const saveToLocalStorage = (data: ActivityData): void => {
  localStorage.setItem("kmpste", btoa(JSON.stringify(data)))
}

const defaultActivity = (submitterId: string) => ({
  voted: [],
  createTokens: [],
  submitterId,
})

// Hyper cautious but if this fucks up the whole app fucks up.
const getStoredActivityData = (user: User): ActivityData => {
  const storedActivity = localStorage.getItem("kmpste")
  const activity = storedActivity
    ? JSON.parse(atob(storedActivity))
    : defaultActivity(user.id)
  return activity.voted && activity.voted.length >= 0
    ? activity
    : defaultActivity(user.id)
}

const activityReducer = (state: ActivityData, action: UpdateUserActivity) =>
  R.pipe(
    updateState(state),
    R.evolve({ voted: R.uniq, createTokens: R.uniq }),
    R.tap(saveToLocalStorage)
  )(action)

const UserProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext)
  const { page, isKampsiteApp, setPage } = useContext(PageContext)
  const [user, setUser] = useState(defaultUser.user)

  const { data, loading } = useMe()
  const [storedPages] = useState(
    JSON.parse(localStorage.getItem("pages")) || []
  )
  const [dataFetched, setDataFetched] = useState(false)

  const [activity, dispatch] = useReducer(
    activityReducer,
    getStoredActivityData(user)
  )

  const canManagePage = !!(
    authenticated &&
    (checkCanManagePage(pagesForUser(user), page) ||
      checkCanManagePage(storedPages, page) ||
      isKampsiteApp)
  )

  if (isKampsiteApp && !page && (pagesForUser(user) || []).length > 0) {
    R.pipe(pagesForUser, R.head, setPage)(user)
  }

  useEffect(() => {
    if (!authenticated && !dataFetched) {
      setDataFetched(true)
      setUser({
        email: null,
        id: uuid.v4(),
        name: null,
        notificationPreferences: null,
        organisations: null,
        type: "guest",
      })
    }
    if (data) {
      setUser(data.me)
      try {
        if (
          data?.me?.pages?.length > 0 &&
          data?.me?.type === "authenticated_user"
        ) {
          const me = data.me
          window.delighted.init("a216da4f-55da-4663-bfb7-9ebe02c5e9aa")
          window.delighted.initUser({
            user_id: me.id,
            email: me.email,
            first_name: me.name,
            image_url: me.imageUrl,
            joined: new Date(me.insertedAt).toISOString(),
            properties: {
              companyName: me.pages[0]?.name,
              subdomain: me.pages[0]?.subdomain,
              role: "Owner",
              plan: me.organisations[0]?.planDetails?.level,
              mrr: me.organisations[0]?.planDetails?.level === "plus" ? 10 : 0,
            },
          })
        }
      } catch (e) {
        console.error(e)
      }
      localStorage.setItem(
        "pages",
        JSON.stringify(R.pipe(pagesForUser, R.map(R.pick(["id"])))(data))
      )
      setDataFetched(true)
    }
  }, [data, authenticated])

  const defaultCtx = {
    user,
    activity: activity,
    fetchingUser: loading,
    updateActivity: dispatch,
    canManagePage,
  }

  return (
    <UserContext.Provider value={defaultCtx}>
      {!dataFetched ? (
        <Box width={1} mt={5}>
          <Loader />
        </Box>
      ) : (
        children
      )}
    </UserContext.Provider>
  )
}

UserProvider.displayName = "UserProvider"

export default UserProvider
