import { Button as RButton } from "rebass"
import styled from "./theme"

const Box = styled(RButton)`
  &:hover {
    cursor: ${props => (props.disabled ? "auto" : "pointer")};
  }

  ${props =>
    props.disabled &&
    `
    background: ${props.theme.colors.muted}
    `}
`
export default Box
