import React, { useContext, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { PageContext } from "Contexts/PageContext"
import { Box, Heading, Flex, Divider, Text, Button } from "ui"
import * as R from "ramda"
import { Label as ILabel } from "Contexts/types"
import LabelPill from "components/Label"
import useBool from "hooks/useBool"
import LabelForm from "./Form"

const Labels: React.FC<RouteComponentProps> = () => {
  const { page, labels } = useContext(PageContext)
  const [selected, setSelected] = useState(null)
  const [showForm, toggleForm] = useBool(false)

  return (
    <Box width={1}>
      <Heading textAlign="center" fontSize={5}>
        Labels
      </Heading>
      {(labels || []).length < 1 ? (
        <Text textAlign="center" mt={3} color="muted">
          Click "New" to create your first label
        </Text>
      ) : (
        <Text textAlign="center" mt={3} color="muted">
          Click on a label to edit it
        </Text>
      )}
      <Flex
        flexWrap="wrap"
        width={1}
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        {R.map(
          (label: ILabel) => (
            <Box
              mt={3}
              mx={3}
              borderRadius={8}
              key={label.id}
              onClick={() => {
                setSelected(label)
                return showForm ? null : toggleForm()
              }}
              sx={{
                ":hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "card",
                  cursor: "pointer",
                },
              }}
            >
              <LabelPill label={label} />
            </Box>
          ),
          labels || []
        )}
      </Flex>
      {showForm && (
        <Box my={3}>
          <LabelForm
            key={selected && selected.id}
            label={selected}
            pageId={page && page.id}
            close={toggleForm}
          />
        </Box>
      )}
      <Flex justifyContent="flex-end" my={3}>
        {!showForm && (
          <Button
            onClick={() => {
              setSelected(null)
              toggleForm()
            }}
          >
            New
          </Button>
        )}
      </Flex>
    </Box>
  )
}

export default Labels
