import InputWithLabel from "components/Input"
import { FormProps } from "pages/Features/FinishedFeatures/types"
import React, { useState } from "react"
import Upload from "pages/settings/fileUpload"
import { Box, Button, Flex, Text } from "ui"
import Loader from "ui/Loader"
import Markdown from "components/Markdown"

const CreateFinishedFeatureForm = ({
  input,
  submit,
  loading,
  setInput
}: FormProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  if (loading) {
    return <Loader />
  }

  return (
    <Box
      as="form"
      onSubmit={e => {
        e.preventDefault()
        !loading && submit()
      }}
    >
      <Flex width={1} flexDirection="column">
        <Box my={3} width="100%">
          <InputWithLabel
            name="title"
            type="text"
            required
            placeholder="Title"
            labelText="Title"
            labelSubText="Short title about what's new"
            value={input.title}
            onChange={setInput("title")}
            autoFocus={true}
            minLength={5}
            maxLength={400}
          />
        </Box>

        <Box my={3} width="100%">
          <InputWithLabel
            name="description"
            type="text"
            required
            placeholder="Go into more detail about the feature you want added or the improvement you want made"
            labelText="Description"
            labelSubText="Go into more detail. Markdown supported."
            value={input.description}
            onChange={setInput("description")}
            autoFocus={false}
            textArea
            minLength={5}
            maxLength={4000}
            rows={10}
          />
        </Box>
        <Box my={3} width="100%">
          <Text
            fontWeight="bold"
            onClick={() => setPreviewOpen(!previewOpen)}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            Preview description {previewOpen ? "⬇️ " : "➡️"}
          </Text>
          {previewOpen && (
            <Box my={3} boxShadow="card" bg="white" p={4} width={1}>
              <Markdown text={input.description} />
            </Box>
          )}
        </Box>
        <Box my={3} width="100%">
          <InputWithLabel
            name="added"
            type="date"
            required
            width="200px"
            placeholder="Date"
            labelText="Date added"
            labelSubText="When was this feature added"
            value={input.added}
            onChange={setInput("added")}
          />
        </Box>
        <Box my={3} width="100%">
          <InputWithLabel
            name="whyBuilt"
            type="text"
            required
            placeholder="Why do your users want this change/feature"
            labelText="Why was this added?"
            labelSubText="Why did you make this change or add this feature (optional)"
            value={input.whyBuilt || ""}
            onChange={setInput("whyBuilt")}
            autoFocus={false}
          />
        </Box>
        <Flex alignItems={"center"} mt={3} ml={2}>
          <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
            Image
          </Text>
          <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
            Upload a static image (or gif) of the change
          </Text>
        </Flex>
        <Flex flexWrap="wrap" alignItems="center" mt={3} ml={2}>
          <Box width={[1, "auto"]}>
            <Upload value={input.image} onSelected={setInput("image")} />
          </Box>
        </Flex>
        <Box mt={3} px={[4, 4, 0, 0]} alignSelf="flex-end">
          <Button
            type="submit"
            disabled={loading}
            onClick={() => submit()}
            size="large"
            variant="primary"
          >
            Submit
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default CreateFinishedFeatureForm
