import InputWithLabel from "components/Input"
import { Page } from "Contexts/types"
import { extractGraphqlErrors, notNil } from "modules/helpers"
import { UPDATE_PAGE } from "modules/queries"
import FakeBrowser from "pages/settings/FakeBrower"
import Upload from "pages/settings/fileUpload"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import {Box, Button, Checkbox, Flex, Label, Text, Heading, Divider, Input} from "ui"
import { RouteComponentProps, navigate } from "@reach/router"
import CustomDomain from "./customDomain"
import useMe from "hooks/useMe"
import { findOrg } from "modules/user"
import NewPill from "components/NewPill"

const formValid = R.curry(
  (name: string, subdomain: string) => name.length > 2 && subdomain.length > 2
)

const Form: React.FC<RouteComponentProps & { page: Page }> = ({ page }) => {
  const { addToast } = useToasts()
  const [name, setName] = useState(page ? page.name : "")
  const [logo, setLogo] = useState(null)
  const [subdomain, setSubdomain] = useState(page ? page.subdomain : "")
  const [customDomainModalOpen, openCloseCustomDomainModal] = useState(false)
  const [prefs, setPrefs] = useState(defaultPrefs(page))

  // Custom Domain
  const orgId: string = R.path(["organisation", "id"], page)
  const { data: meResp } = useMe()
  const canChangeCustomDomain = R.pipe(
    findOrg(orgId),
    R.pathOr(false, ["planDetails", "customDomain"])
  )(meResp)

  const [updatePage, { loading, error, data }] = useMutation(UPDATE_PAGE, {
    variables: {
      name,
      subdomain,
      pageId: page && page.id,
      logo,
      appearance: prefs,
    },
  })

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }

    if (data) {
      addToast("Settings updated", { appearance: "success" })
    }
  }, [data, error, addToast])

  const readyForSubmit = formValid(name, subdomain)
  const setLowerSubDomain = R.pipe(
    R.toLower,
    R.replace(/[^0-9a-zA-Z-]/, ""),
    setSubdomain
  )

  return (
    <Flex width={1} flexDirection="column">
      <Heading mb={2} textAlign="center" fontSize={5}>
        Page Settings
      </Heading>
      <Box alignSelf="flex-end">
        <Button
          onClick={() => updatePage()}
          disabled={!readyForSubmit || loading}
          size="large"
          variant="primary"
        >
          Save
        </Button>
      </Box>
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent={["flex-start", "flex-start", "space-between"]}
        alignItems="flex-start"
        width="100%"
      >
        <Flex flexDirection="column" my={3} width={1}>
          <Flex flexWrap="wrap" justifyContent="space-between" width={1}>
            <Flex mx={3} flexDirection="column" width={[1, 0.4, 0.45]}>
              <Box my={3} width="100%">
                <InputWithLabel
                  name="page_name"
                  type="text"
                  placeholder="Page name"
                  labelText="Name"
                  labelSubText="Your page's name"
                  value={name}
                  onChange={setName}
                  autoFocus={true}
                  minLength={3}
                />
              </Box>

              <Box my={4} width="100%">
                <InputWithLabel
                  name="company_name"
                  type="text"
                  placeholder="title"
                  labelText="Title"
                  labelSubText="The title displayed on your page"
                  value={prefs.titleText}
                  onChange={(v) => setPrefs({ ...prefs, titleText: v })}
                  autoFocus={true}
                  minLength={3}
                />
              </Box>
            </Flex>

            <Flex mx={3} flexDirection="column" width={[1, 0.4, 0.45]}>
              <Box my={3} width="100%">
                <InputWithLabel
                  name="subdomain"
                  type="url"
                  placeholder="Subdomain"
                  labelText="Subdomain"
                  labelSubText={
                    notNil(page && page.id)
                      ? "Can't be changed"
                      : "Where your page will live"
                  }
                  value={subdomain}
                  onChange={setLowerSubDomain}
                  autoFocus={false}
                  disabled={notNil(page && page.id)}
                  minLength={3}
                />
              </Box>
              <Box my={4} width="100%">
                <InputWithLabel
                  name="company_name"
                  type="text"
                  placeholder="subtitle"
                  labelText="Subtitle"
                  labelSubText="Displayed under the title"
                  value={prefs.subtitleText}
                  onChange={(v) => setPrefs({ ...prefs, subtitleText: v })}
                  autoFocus={true}
                  minLength={3}
                />
              </Box>
            </Flex>
          </Flex>
          <Box my={4} mx={3} width="100%">
            <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={["flex-start", "flex-start", "center"]}
                ml={2}
                mb={1}
            >
              <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
                Feature Status
              </Text>
              <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
                Choose which status to display and use custom labels if you wish
              </Text>
            </Flex>
            <Flex flexWrap="wrap" alignItems="center" mt={3} ml={2}>
              <Flex mx={3} flexDirection="column" width={[0.2, 0.45]}>
                <Flex my={2} width="100%">
                  <Text fontWeight="bold" color="muted">Show</Text>
                  <Text fontWeight="bold" color="muted" ml={5}>Heading</Text>

                </Flex>
                <Box mb={3} width="100%">
                  <Label>
                    <Checkbox mt={3} checked={prefs.showStatus.pending}
                             onChange={() =>
                                 setPrefs({
                                   ...prefs,
                                   showStatus: {
                                     ...prefs.showStatus,
                                    pending: !prefs.showStatus.pending
                                    }
                                 })
                             }/>
                  <Input ml={5}
                      value={prefs.customHeadings.pending}
                      placeholder="Pending"
                      onChange={(v) => setPrefs({ ...prefs, customHeadings: { ...prefs.customHeadings, pending: v.target.value} })}
                  /></Label>
          </Box>
                <Box my={3} width="100%">
                  <Label>
                    <Checkbox mt={3} checked={prefs.showStatus.considered}
                             onChange={() =>
                                 setPrefs({
                                   ...prefs,
                                   showStatus: {
                                     ...prefs.showStatus,
                                     considered: !prefs.showStatus.considered
                                   }
                                 })
                             }/>
                  <Input ml={5}
                      value={prefs.customHeadings.considered}
                      placeholder="In Review"
                      onChange={(v) => setPrefs({ ...prefs, customHeadings: { ...prefs.customHeadings, considered: v.target.value} })}

                  /></Label>

                </Box>
                <Box my={3} width="100%">
                  <Label>
                    <Checkbox mt={3} checked={prefs.showStatus.planned}
                             onChange={() =>
                                 setPrefs({
                                   ...prefs,
                                   showStatus: {
                                     ...prefs.showStatus,
                                     planned: !prefs.showStatus.planned
                                    }
                                 })
                             }/>
                  <Input ml={5}
                      value={prefs.customHeadings.planned}
                      placeholder="Planned"
                      onChange={(v) => setPrefs({ ...prefs, customHeadings: { ...prefs.customHeadings, planned: v.target.value} })}

                  /></Label>
                </Box>
                <Box my={3} width="100%">
                  <Label>
                    <Checkbox mt={3} checked={prefs.showStatus.inprogress}
                             onChange={() =>
                                 setPrefs({
                                   ...prefs,
                                   showStatus: {
                                     ...prefs.showStatus,
                                     inprogress: !prefs.showStatus.inprogress
                                    }
                                 })
                             }/>
                    <Input ml={5}
                        value={prefs.customHeadings.inprogress}
                        placeholder="In Progress"
                        onChange={(v) => setPrefs({ ...prefs, customHeadings: { ...prefs.customHeadings, inprogress: v.target.value} })}

                    /></Label>
                </Box>
                <Box my={3} width="100%">
                  <Label><Checkbox mt={3}  checked={prefs.showStatus.completed}
                             onChange={() =>
                                 setPrefs({
                                   ...prefs,
                                   showStatus: {
                                     ...prefs.showStatus,
                                     completed: !prefs.showStatus.completed
                                    }
                                 })
                             }/>
                    <Input ml={5}
                        value={prefs.customHeadings.completed}
                        placeholder="Completed"
                        onChange={(v) => setPrefs({ ...prefs, customHeadings: { ...prefs.customHeadings, completed: v.target.value} })}

                    />
                  </Label>
                </Box>

              </Flex>


            </Flex>
          </Box>
          <Box my={4} mx={3} width="100%">
            <Flex
              flexDirection={["column", "column", "row"]}
              alignItems={["flex-start", "flex-start", "center"]}
              ml={2}
              mb={1}
            >
              <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
                Logo
              </Text>
              <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
                Your companies logo (optional)
              </Text>
            </Flex>
            <Flex flexWrap="wrap" alignItems="center" mt={3} ml={2}>
              <Box width={[1, "auto"]}>
                <Upload value={logo} onSelected={setLogo} />
              </Box>
              <Flex mx={[0, 4]} mt={[3, 0]} flexWrap="wrap" width={0.6}>
                <Box mx={2}>
                  <Label>
                    <Checkbox
                      checked={prefs.showNavbarBg}
                      onChange={() =>
                        setPrefs({
                          ...prefs,
                          showNavbarBg: !prefs.showNavbarBg,
                        })
                      }
                      id="showNavbarBg"
                      name="showNavbarBg"
                    />
                    White Background
                  </Label>
                </Box>
                <Box mx={2}>
                  <Label>
                    <Checkbox
                      checked={prefs.logoShadow}
                      onChange={() =>
                        setPrefs({ ...prefs, logoShadow: !prefs.logoShadow })
                      }
                      id="logoShadow"
                      name="logoShadow"
                    />
                    Shadow
                  </Label>
                </Box>
                <Box mx={2}>
                  <Label>
                    <Checkbox
                      checked={prefs.logoBorderRadius}
                      onChange={() =>
                        setPrefs({
                          ...prefs,
                          logoBorderRadius: !prefs.logoBorderRadius,
                        })
                      }
                      id="logoBorderRadius"
                      name="logoBorderRadius"
                    />
                    Border Radius
                  </Label>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box my={4} mx={3} width="100%">
            <CustomDomain
              open={customDomainModalOpen}
              customDomain={page && page.customDomain}
            />
            <Flex flexDirection={"column"} ml={2} mb={1}>
              <Flex>
                <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
                  Custom Domain
                </Text>
                <Box width="50px">
                  <NewPill />
                </Box>
              </Flex>
              <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
                Use your own domain for your feedback page. Available to paid users only
              </Text>
              <Box height="40px" width="300px" mt={3}>
                <Button
                  onClick={() =>
                    canChangeCustomDomain
                      ? openCloseCustomDomainModal(!customDomainModalOpen)
                      : navigate("/manage/billing")
                  }
                >
                  Use custom domain
                </Button>
              </Box>
            </Flex>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box my={4} mx={3} width="100%">
              <Heading my={3}>Roadmap page</Heading>
            <Label mx={3} my={2}>
              <Checkbox
                  checked={prefs.showRoadmap}
                  onChange={() =>
                      setPrefs({
                        ...prefs,
                        showRoadmap: !prefs.showRoadmap,
                      })
                  }
                  id="showRoadmap"
                  name="showRoadmap"
              />
              Link to Roadmap in menu
            </Label>
            <Flex mx={3} flexDirection="row" width={1}>
              <Box mx={3} width="100%">
            <InputWithLabel
                name="roadmap_title"
                type="text"
                placeholder="Our Roadmap"
                labelText="Roadmap Title"
                labelSubText="Displayed on your Roadmap"
                value={prefs.roadmapTitle}
                onChange={(v) => setPrefs({ ...prefs, roadmapTitle: v })}
                autoFocus={true}
                minLength={3}
            />
              </Box>
              <Box mx={3} width="100%">
          <InputWithLabel
                name="roadmap_subtitle"
                type="text"
                placeholder="Our Roadmap"
                labelText="Roadmap Subtitle"
                labelSubText="Displayed under the title"
                value={prefs.roadmapSubtitle}
                onChange={(v) => setPrefs({ ...prefs, roadmapSubtitle: v })}
                autoFocus={true}
                minLength={3}
          /></Box>
            </Flex>

          </Box>
          <Box my={2}>
            <Divider />
          </Box>

          <Box my={4} mx={3} width="100%">
              <Heading my={3}>Changelog page</Heading>

              <Label mx={3} my={3}>
                <Checkbox
                    checked={prefs.showChangelog || false}
                    onChange={() =>
                        setPrefs({
                          ...prefs,
                          showChangelog: !(prefs.showChangelog || false),
                        })
                    }
                    id="showChangelog"
                    name="showChangelog"
                />
                Link to Changelog in menu
              </Label>
              <Flex mx={3} flexDirection="row" width={1}>
              <Box mx={3} width="100%">
            <InputWithLabel
                name="changelog_title"
                type="text"
                placeholder="Our Changelog"
                labelText="Changelog Title"
                labelSubText="Displayed on your changelog"
                value={prefs.changelogTitle}
                onChange={(v) => setPrefs({ ...prefs, changelogTitle: v })}
                autoFocus={true}
                minLength={3}
            />
              </Box>
              <Box mx={3} width="100%">
          <InputWithLabel
                name="changelog_subtitle"
                type="text"
                placeholder="Our Changelog"
                labelText="Changelog Subtitle"
                labelSubText="Displayed under the title"
                value={prefs.changelogSubtitle}
                onChange={(v) => setPrefs({ ...prefs, changelogSubtitle: v })}
                autoFocus={true}
          /></Box>
            </Flex>

          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Form
