import { AuthenticatedUser, Org } from "Contexts/types"
import { GET_ORG_USERS } from "modules/queries"
import * as R from "ramda"
import { useQuery } from "react-apollo"
import { findOrg } from "modules/user"

interface UseGetOrgUsers {
  loading: boolean
  users?: AuthenticatedUser[]
  maxUsers: number
}

const useGetOrgUsers = (orgId: string): UseGetOrgUsers => {
  const { data, loading } = useQuery(GET_ORG_USERS)

  const org: Org = findOrg(orgId)(data)

  const users = R.propOr([], "users", org)
  const maxUsers = R.pathOr(1, ["planDetails", "users"], org)

  return loading
    ? { loading, maxUsers: 1 }
    : { loading: false, users: users as AuthenticatedUser[], maxUsers }
}

export default useGetOrgUsers
