import { VOTE_REQ } from "modules/queries"
import { UpVoteProps } from "pages/Features/types"
import * as R from "ramda"
import React, { useEffect } from "react"
import { useMutation } from "react-apollo"
import { Box, Flex, Text } from "ui"
import styled from "ui/styled"
import sha1 from "sha1"
import { useToasts } from "react-toast-notifications"
import { extractGraphqlErrors } from "modules/helpers"
import Loader from "ui/Loader"

const UpVoteContainer = styled<any>(Flex)`
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    transform: translateY(-1px);
  }
`

const Icon = ({ voted }: { voted: boolean }) => (
  <svg
    className="upvote-arrow"
    width="14"
    height="10"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8C11.412 8 11.6472 7.52962 11.4 7.2L6.4 0.533334C6.2 0.266667 5.8 0.266667 5.6 0.533333L0.6 7.2C0.352787 7.52962 0.587977 8 1 8L11 8Z"
      fill={!voted ? "#92929D" : "#0062FF"}
    />
  </svg>
)

const canVote = R.allPass([
  R.pipe(R.prop("status"), R.flip(R.includes)(["pending", "accepted", "considered","planned"])),
  R.pipe(R.prop("isDuplicate"), R.identity, R.not)
])

const UpVote = (props: UpVoteProps) => {
  const { addToast } = useToasts()
  const [performVote, { data, loading, error }] = useMutation(
    VOTE_REQ(!props.userVoted ? "addVote" : "removeVote"),
    {
      variables: { requestId: props.request.id },
      context: {
        headers: {
          "x-content-fxt": sha1(`${props.request.id}-${props.request.votes}`)
        }
      }
    }
  )

  const { userVoted, request, updateUserVoted } = props
  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    } else if (data && (data.addVote || data.removeVote)) {
      updateUserVoted({
        type: userVoted ? "unvoted" : "voted",
        data: request.id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  if (loading) {
    return <Loader />
  }

  const color = props.userVoted ? "primary" : "muted"
  return (
    <UpVoteContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pending={canVote(props.request)}
      userVoted={props.userVoted && !loading}
      onClick={() => !loading && performVote()}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{}}
      >
        <Flex
          justifyContent="center"
          py={2}
          mx={2}
          sx={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px"
          }}
          width={1}
        >
          <Icon voted={props.userVoted} />
        </Flex>
        <Text color={color}>{props.request.votes}</Text>
      </Flex>
    </UpVoteContainer>
  )
}

export default UpVote
