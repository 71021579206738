import { PageContext } from "Contexts/PageContext"
import { Subscription } from "Contexts/types"
import { GET_ME, SUBSCRIBE_USER_TO } from "modules/queries"
import { useContext, useEffect } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"

type SubscribeResp = [() => any, { loading: boolean; data: any; error: any }]

const useSubscribeUser = (
  entity: Subscription["entityType"],
  entId: string
): SubscribeResp => {
  const { addToast } = useToasts()
  const { page } = useContext(PageContext)
  const [subscribeUser, { data, loading, error }] = useMutation(
    SUBSCRIBE_USER_TO,
    {
      variables: { entity, entId },
      refetchQueries: [
        { query: GET_ME, variables: { pageId: page && page.id } }
      ]
    }
  )

  useEffect(() => {
    if (data) {
      addToast("Subscribed", {
        appearance: "success",
        autoDismiss: true
      })
    }
  }, [data])

  return [subscribeUser, { data, loading, error }]
}

export default useSubscribeUser
