import { Box } from "ui"
import styled from "@emotion/styled"

interface Props {
  width?: any
}

const Divider = styled(Box)<Props>`
  border-bottom: 1px solid #efefef;
  width: ${props => props.width || "80%"};
`

Divider.defaultProps = {
  mx: 4
}

export default Divider
