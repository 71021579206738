import InputWithLabel from "components/Input"
import { Label as ILabel } from "Contexts/types"
import useInputSet from "hooks/useInputSet"
import { extractGraphqlErrors } from "modules/helpers"
import {
  DELETE_LABEL,
  UPDATE_LABEL,
  CREATE_LABEL,
  GET_PAGE,
} from "modules/queries"
import * as R from "ramda"
import React, { useEffect, useContext } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Flex, Label, Select, Button, Text } from "ui"
import Loader from "ui/Loader"

interface LabelFormProps {
  label?: ILabel
  pageId: string
  close: () => any
}

const colors = ["#0062FF", "#3300cc", "#FFC542", "#3DD598"]

const defaultInput = () => ({
  type: "PUBLIC",
  color: colors[Math.floor(Math.random() * colors.length)],
  text: "",
})

const inputToAttrs = R.pick(["color", "type", "text"])

const refetchPageQuery = (pageId) => [
  { query: GET_PAGE, variables: { id: pageId } },
]

const validateColor = (color: string) =>
  R.startsWith("#", color) && color.length === 7

const validateText = (text: string) => text.length > 0

const inputValid = (input) =>
  validateColor(input.color) && validateText(input.text)

const LabelForm = ({ label, pageId, close }: LabelFormProps) => {
  const { addToast } = useToasts()
  const [input, setInputVal] = useInputSet(label || defaultInput())

  const [createLabel, createLabelResp] = useMutation(CREATE_LABEL, {
    variables: { pageId, input: inputToAttrs(input) },
    refetchQueries: refetchPageQuery(pageId),
  })

  const [updateLabel, updateLabelResp] = useMutation(UPDATE_LABEL, {
    variables: { labelId: label && label.id, input: inputToAttrs(input) },
    refetchQueries: refetchPageQuery(pageId),
  })

  const [deleteLabel, deleteLabelResp] = useMutation(DELETE_LABEL, {
    variables: { labelId: label && label.id },
    refetchQueries: refetchPageQuery(pageId),
  })
  useEffect(() => {
    if (updateLabelResp.data) {
      addToast("Label Updated", {
        appearance: "success",
        autoDismiss: true,
      })
      close()
    } else if (updateLabelResp.error) {
      addToast(extractGraphqlErrors(updateLabelResp.error), {
        appearance: "error",
      })
    }
  }, [updateLabelResp])

  useEffect(() => {
    if (createLabelResp.data) {
      addToast("Label Created", {
        appearance: "success",
        autoDismiss: true,
      })
      close()
    } else if (createLabelResp.error) {
      addToast(extractGraphqlErrors(createLabelResp.error), {
        appearance: "error",
      })
    }
  }, [createLabelResp])

  useEffect(() => {
    if (deleteLabelResp.data) {
      addToast("Label Deleted", {
        appearance: "success",
        autoDismiss: true,
      })
      close()
    } else if (deleteLabelResp.error) {
      addToast(extractGraphqlErrors(updateLabelResp.error), {
        appearance: "error",
      })
    }
  }, [deleteLabelResp])

  if (
    createLabelResp.loading ||
    updateLabelResp.loading ||
    deleteLabelResp.loading
  ) {
    return <Loader />
  }

  return (
    <Flex flexDirection="column">
      <Text my={3} textAlign="center" mt={3} color="muted">
        Click{" "}
        <a href="https://feedback.kampsite.co/suggestions/ebaf49fd-220b-43eb-be82-4349f0224be2/feature-showcase/45e87659-3dbe-4324-94f9-23bb691ab342">
          here
        </a>{" "}
        to learn about the three different types of labels
      </Text>
      <Flex justifyContent="space-between">
        <Box width={0.3} mx={2}>
          <InputWithLabel
            name="text"
            value={input.text}
            type="text"
            onChange={setInputVal("text")}
            labelText="Text"
            labelSubText=""
            placeholder="Bug"
            smallLabel
          />
        </Box>
        <Box width={0.3} mx={2}>
          <InputWithLabel
            name="color"
            value={input.color}
            customValidation={(color) =>
              validateColor(color)
                ? null
                : "Must start with a # followed by 6 characters"
            }
            type="text"
            onChange={setInputVal("color")}
            labelText="Color"
            labelSubText="Must be a hex code"
            placeholder="Bug"
            maxLength={7}
            smallLabel
          />
        </Box>
        <Box width={0.3} mx={2}>
          <Label sx={{ fontWeight: "mid" }} htmlFor="status">
            Type
          </Label>
          <Select
            height={"53px"}
            id="type"
            name="type"
            onChange={(e) => setInputVal("type")(e.target.value)}
            defaultValue={input.type}
          >
            <option value="PUBLIC" key="public">
              Public
            </option>
            <option value="ADMIN" key="admin">
              Admin
            </option>
            <option value="INTERNAL" key="internal">
              Internal
            </option>
          </Select>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" mt={4}>
        <Button
          disabled={!inputValid(input)}
          onClick={() => (label ? updateLabel() : createLabel())}
        >
          {label ? "Update" : "Create"}
        </Button>
        {label && (
          <Button onClick={() => deleteLabel()} variant="outlineDanger" ml={3}>
            Delete
          </Button>
        )}
        <Button onClick={close} variant="outline" ml={3}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  )
}

export default LabelForm
