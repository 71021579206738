import {Link, navigate, RouteComponentProps} from "@reach/router"
import {UserContext} from "Contexts"
import {PageContext} from "Contexts/PageContext"
import useBool from "hooks/useBool"
import {GET_FEATURES, GET_FEATURE_COUNTS} from "modules/queries"
import NoSuggestions from "pages/Features/FeatureList/NoSuggestions"
import {FeatureStatusCounts} from "pages/Features/types"
import {defaultPrefs} from "pages/settings/helpers"
import * as R from "ramda"
import React, {useContext, useEffect, useState} from "react"
import {useQuery} from "react-apollo"
import {AnimatedBox, Box, Button, Flex, Heading, Input} from "ui"
import Loader from "ui/Loader"
import FeatureList from "./FeatureList"
import SearchIcon from "components/Search"


const Roadmap: React.FC<RouteComponentProps> = (props) => {
    const statusFilter =
        new URLSearchParams(props.location.search).get("filter") || "pending"

    const [showSearch, toggleSearch] = useBool(false)
    const {subdomain, page} = useContext(PageContext)
    const prefs = defaultPrefs(page)
    const {activity, updateActivity, canManagePage} = useContext(UserContext)
    const [filter, updateFilter] = useState(statusFilter)
    const countsResp = useQuery(GET_FEATURE_COUNTS, {
        variables: {subdomain},
    })

    const {data, error, loading, fetchMore} = useQuery(GET_FEATURES, {
        variables: {subdomain, offset: 0, status: filter},
    })

    return (
        <Box mb={6} maxWidth={["100%"]} mt={4} mx={3}>
            <Heading
                as="h1"
                mt={0}
                mb={3}
                sx={{
                    fontWeight: "mid",
                    fontSize: [5, 6],
                    textAlign: "center"
                }}
            >Roadmap</Heading>
            <Flex flexWrap={"wrap"}>
                <Box px={2} minWidth={"25%"} width={[1,1/2,1/4]}>
                    <h4>In Review</h4>
                    <FeatureList statusFilter="considered"></FeatureList>
                </Box>
                <Box px={2} minWidth={"25%"} width={[1,1/2,1/4]}>
                    <h4>Planned</h4>
                    <FeatureList statusFilter="planned"></FeatureList>
                </Box>
                <Box px={2} minWidth={"25%"} width={[1,1/2,1/4]}>

                    <h4>In Progress</h4>
                    <FeatureList statusFilter="accepted"></FeatureList>
                </Box>
                <Box px={2} minWidth={"25%"} width={[1,1/2,1/4]}>

                    <h4>Completed</h4>
                    <FeatureList statusFilter="completed"></FeatureList>
                </Box>

            </Flex>

        </Box>
    )
}

export default Roadmap
