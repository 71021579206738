import React from "react"
import { Box, Text, Flex } from "ui"

interface PillProps {
  color: string
  text: string
}

const Pill = (props: PillProps) => (
  <Box
    height="25px"
    mr={3}
    borderRadius={8}
    px={3}
    bg={`${props.color}Lightest`}
    my={1}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Text
        as="p"
        lineHeight={1}
        fontSize={0}
        sx={{ fontWeight: "mid" }}
        color={props.color}
      >
        {props.text}
      </Text>
    </Flex>
  </Box>
)

export default Pill
