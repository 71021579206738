import styled, { CreateStyled } from "@emotion/styled"
import { ColorProperty } from "csstype"

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | "bold"

export interface ITheme {
  colors: {
    text: string
    background: string
    primary: string
    primaryLightest: string
    secondary: string
    danger: string
    dangerLightest: string
    success: string
    successLightest: string
    warning: string
    warningLightest: string
    muted: string
    darkGrey: string
    darkerGrey: string
    highlight: string
  }
  fonts: {
    body: string
    heading: string
    monospace: string
  }
  fontSizes: number[]
  fontWeights: {
    body: FontWeight
    regular: FontWeight
    heading: FontWeight
    bold: FontWeight
    mid: FontWeight
  }
  lineHeights: {
    body: number
    heading: number
  }
  space: number[]
  sizes: {
    avatar: number
  }
  radii: {
    default: number
    circle: number
  }
  shadows: {
    card: string
    mid: string
    input: string
    hover: string
    inset: string
  }

  text: {
    heading: {
      fontFamily: string
      lineHeight: string
      fontWeight: string
    }
    display: {
      fontFamily: string
      fontWeight: string
      lineHeight: string
      fontSize: number[]
    }
    caps: {
      textTransform: string
      letterSpacing: string
    }
  }
  variants: {
    avatar: {
      width: string
      height: string
      borderRadius: string
    }
    card: {
      p: number
      bg: string
      boxShadow: string
    }
    link: {
      color: string
    }
    nav: {
      fontSize: number
      fontWeight: string
      display: string
      p: number
      color: ColorProperty
      textDecoration: string
      ":hover,:focus,.active": {
        color: string
      }
    }
  }
  buttons: {
    primary: {
      fontSize: number
      fontWeight: FontWeight
      color: string
      bg: string
      borderRadius: string
    }
    outline: {
      variant: string
      color: string
      bg: string
      boxShadow: string
      "&:hover": any
    }
    outlineSuccess: {
      variant: string
      color: string
      bg: string
      boxShadow: string
      "&:hover": any
    }
    outlineDanger: {
      variant: string
      color: string
      bg: string
      boxShadow: string
      "&:hover": any
    }
    dangerLightest: {
      variant: string
      color: string
      bg: string
    }
    secondary: {
      variant: string
      color: string
      bg: string
    }
  }
  styles: {
    root: {
      fontFamily: string
      fontWeight: string
      lineHeight: string
    }
  }
}

export const preset: ITheme = {
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#0062FF",
    primaryLightest: "rgba(0, 98, 255, 0.1)",
    secondary: "#30c",
    danger: "#FC5A5A",
    dangerLightest: "rgba(252, 90, 90, 0.3)",
    success: "#3DD598",
    successLightest: "rgba(61, 213, 152, 0.2)",
    warning: "#FFC542",
    warningLightest: "rgba(255, 197, 66, 0.2)",
    muted: "#92929D",
    darkGrey: "#696974",
    darkerGrey: "#46464c",
    highlight: "hsla(205, 100%, 40%, 0.125)",
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 64, 96],
  fontWeights: {
    body: 400,
    regular: 500,
    mid: 600,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    default: 4,
    circle: 99999,
  },
  shadows: {
    card: "0 0 1px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06)",
    mid:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    input: "0 0 1px 0 rgba(0,12,32,0.24), 0px 3px 1px 0 rgba(10,31,68,0.06)",
    hover: "1px 4px 4px 3px rgba(23,23,37,0.15)",
    inset: "inset 0px -1px 0px #E2E2EA",
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle",
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
    },
    link: {
      color: "primary",
    },
    nav: {
      fontSize: 1,
      fontWeight: "bold",
      display: "inline-block",
      p: 2,
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary",
      },
    },
  },
  buttons: {
    primary: {
      fontSize: 2,
      fontWeight: "bold",
      color: "background",
      bg: "primary",
      borderRadius: "default",
    },
    outline: {
      variant: "buttons.primary",
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 2px",
      "&:hover": {
        variant: "buttons.primary",
      },
    },
    outlineSuccess: {
      variant: "buttons.primary",
      color: "success",
      bg: "transparent",
      boxShadow: "inset 0 0 2px",
      "&:hover": {
        variant: "buttons.primary",
        bg: "success",
      },
    },
    outlineDanger: {
      variant: "buttons.primary",
      color: "danger",
      bg: "transparent",
      boxShadow: "inset 0 0 2px",
      "&:hover": {
        variant: "buttons.primary",
        bg: "danger",
      },
    },
    dangerLightest: {
      variant: "buttons.primary",
      color: "danger",
      bg: "dangerLightest",
    },
    secondary: {
      variant: "buttons.primary",
      color: "background",
      bg: "secondary",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
}

export default styled as CreateStyled<ITheme>
