import * as R from "ramda"
import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { AnimatedBox, Box } from "ui"
import Loader from "ui/Loader"
import FeatureRequest from "../FeatureRequest"

const canLoadMore = (currentNumber: number, total: number) =>
  currentNumber < total

const FeatureRequestList = (props: any) => (
  <Box width="100%">
    <InfiniteScroll
      pageStart={0}
      loadMore={props.loadMore}
      hasMore={canLoadMore(props.requests.length, props.totalCount)}
      loader={<Loader key={Math.random().toString()} />}
      initialLoad={false}
    >
      {props.requests.map(
        (req) =>
          !req.deleted && (
            <AnimatedBox
              mb={4}
              width="100%"
              bg="white"
              borderRadius={16}
              boxShadow="card"
              p={3}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              key={req.id}
              positionTransition
            >
              <FeatureRequest
                canManagePage={props.canManagePage}
                navigate={`/suggestions/${req.id}`}
                userVoted={R.includes(req.id, props.userVoted)}
                updateUserVoted={props.updateActivity}
                request={req}
                showDescription={true}
              />
            </AnimatedBox>
          )
      )}
    </InfiniteScroll>
  </Box>
)

export default FeatureRequestList
