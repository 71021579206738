import { navigate } from "@reach/router"
import { extractGraphqlErrors } from "modules/helpers"
import { DEDUPE_FEATURE, GET_FEATURES } from "modules/queries"
import ModalContent from "pages/Features/FeatureRequest/Actions/ModalContent"
import React, { useEffect } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Text } from "ui"

const DedupeModalContent = () => (
  <Box width={1}>
    <Text
      mb={2}
      sx={{
        fontWeight: "mid",
        fontSize: [3, 3, 4]
      }}
    >
      Deduplicate Suggestion
    </Text>
    <Text color="darkGrey" sx={{ whiteSpace: "pre-wrap" }}>
      This will remove the duplicate status from this suggestion and put in back
      into the suggestions list.
    </Text>
  </Box>
)

const DedupeModal = ({ isOpen, reqId, onClose }) => {
  const [dedupeRequest, dedupeRes] = useMutation(DEDUPE_FEATURE, {
    variables: { reqId }
  })
  const { addToast } = useToasts()

  useEffect(() => {
    if (dedupeRes.data) {
      addToast("Suggestion deduped", {
        appearance: "success",
        autoDismiss: true
      })
      navigate("/")
    } else if (dedupeRes.error) {
      addToast(extractGraphqlErrors(dedupeRes.error), {
        appearance: "error",
        autoDismiss: true
      })
    }
  }, [dedupeRes, addToast])

  return (
    <ModalContent
      onClose={onClose}
      buttonText="Update"
      open={isOpen}
      onSubmit={dedupeRequest}
      buttonDisabled={false}
    >
      <DedupeModalContent />
    </ModalContent>
  )
}

export default DedupeModal
