import { navigate, WindowLocation } from "@reach/router"
import { PagePrefs } from "Contexts/types"
import HamburgerMenu from "pages/ManagementDashboard/Sidebar/Menu"
import React from "react"
import { Box, Flex, Heading } from "ui"
import styled from "ui/styled"
import Logo from "./logo"

interface Props {
  logo: string | null
  companyName: string
  prefs?: PagePrefs
  location?: WindowLocation
  showMenu?: boolean
}

interface SImgProps {
  prefs: PagePrefs
}

const SImg = styled.img<SImgProps>`
  max-height: 40px;
  width: auto;
  padding: ${(props) => (props.prefs.showNavbarBg ? "8px" : "0")};
  margin-top: ${(props) => (props.prefs.showNavbarBg ? "0" : "32px")};
  border-radius: ${(props) => (props.prefs.logoBorderRadius ? "16px" : "none")};
  box-shadow: ${(props) =>
    props.prefs.logoShadow
      ? "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
      : "none"};
`

export const FeaturePageNavbar = (props: Props) => {
  const { showMenu = true } = props
  return (
    <Box
      bg={props.prefs.showNavbarBg && "white"}
      mb={props.prefs.showNavbarBg && 3}
      width="100%"
      boxShadow={props.prefs.showNavbarBg && "card"}
    >
      {showMenu && (
        <HamburgerMenu
          top={props.prefs.showNavbarBg ? "10px" : !props.logo ? "7px" : "32px"}
          left="16px"
          location={props.location}
        />
      )}
      <Flex alignItems="around" px={3} ml={5} justifyContent={"left"}>
        <Box
          onClick={() => navigate("/")}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          {props.logo ? (
            <SImg prefs={props.prefs} src={props.logo} />
          ) : (
            <Heading m={3} fontSize={[3, 3, 4]}>
              {props.companyName}
            </Heading>
          )}
        </Box>
        <Flex alignItems="center" ml={5} justifyContent={"center"} >
          {props.prefs.showRoadmap && (<Box  onClick={() => navigate("/roadmap")} fontSize={[0,2]}
                sx={{ "&:hover": { cursor: "pointer" } }}>Roadmap</Box>
          )}
          {props.prefs.showChangelog && (<Box fontSize={[0,2]} onClick={() => navigate("/changelog")}
                ml={3} sx={{ "&:hover": { cursor: "pointer" } }}>Changelog</Box>
          )}
        </Flex>
      </Flex>
      {showMenu && (
        <Box display="none">
          <HamburgerMenu location={props.location} />
        </Box>
      )}
    </Box>
  )
}

export const NavBar = (props: Props) => (
  <Box width="100%">
    {props.showMenu && (
      <HamburgerMenu top="32px" left="16px" location={props.location} />
    )}
    <Flex alignItems="center" px={3} justifyContent={"center"}>
      <Box
        onClick={() => navigate("/")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        <SImg
          prefs={
            {
              showNavbarBg: false,
              logoShadow: true,
              logoBorderRadius: true,
            } as PagePrefs
          }
          src={
            "https://dinte0h0exzgg.cloudfront.net/f62ebfb032e440bc83c43bac4b4a7854-logo.png"
          }
        />
      </Box>
    </Flex>
    {props.showMenu && (
      <Box display="none">
        <HamburgerMenu location={props.location} />
      </Box>
    )}
  </Box>
)
