import { Org, Page, Subscription, User } from "Contexts/types"
import * as R from "ramda"

export const isType = R.curry(
  (type: "authenticated_user" | "guest" | "page_user", user: User) =>
    R.pipe(R.prop("type"), R.equals(type))(user)
)

export const isGuest = isType("guest")
export const isAuthedUser = isType("authenticated_user")
export const isPageUser = isType("page_user")
export const isSignedIn = R.anyPass([isPageUser, isAuthedUser])

export const hasOrg = R.pipe(
  R.prop<"organisations", Org[]>("organisations"),
  R.length,
  R.lte(1)
)

export const onboardingCompleted = (user: User) => hasOrg(user)

export const isSubscribedTo = R.curry(
  (type: Subscription["entityType"], user: User, id: string) =>
    R.pipe(
      R.propOr([], "subscriptions"),
      R.any<Subscription[]>(R.whereEq({ entityType: type, entityId: id }))
    )(user)
)

export const findOrg = (orgId: string) => (meResp: { me: User }) =>
  R.pipe(
    R.pathOr([], ["me", "organisations"]),
    R.find(R.propEq("id", orgId))
  )(meResp)

// Dry to the bone
export const pagesForUser = (user: { me: User } | User): Page[] =>
  "me" in user
    ? R.pathOr([], ["me", "pages"], user)
    : R.propOr([], "pages", user)
