import styled from "@emotion/styled"
import { Heading as RHeading } from "rebass"
import {
  fontWeight,
  FontWeightProps,
  fontSize,
  FontSizeProps
} from "styled-system"

export type HeadingProps = FontWeightProps & FontSizeProps

const Heading = styled(RHeading)<HeadingProps>`
  ${fontWeight}
  ${fontSize}
  letter-spacing: 0.1px;
`

export default Heading
