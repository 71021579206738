import Flex from "./Flex"
import styled from "@emotion/styled"
import {
  borderRadius,
  BorderRadiusProps,
  shadow,
  ShadowProps,
} from "styled-system"

export type CardProps = BorderRadiusProps | ShadowProps

const Card = styled(Flex)<CardProps>`
  ${borderRadius}
  box-shadow: 0 0 1px 0 rgba(0, 12, 32, 0.04),
    0 10px 16px 0 rgba(10, 31, 68, 0.06);
  ${shadow}
`

Card.defaultProps = {
  p: 4,
  borderRadius: 8,
  bg: "white",
}

export default Card
