import gql from "graphql-tag"

export const CREATE_PAGE = gql`
  mutation CreatePage(
    $name: String!
    $subdomain: String!
    $logo: Upload
    $appearance: PageAppearanceInput!
  ) {
    createPage(
      input: {
        subdomain: $subdomain
        name: $name
        logo: $logo
        appearance: $appearance
      }
    ) {
      id
      name
      logoUrl
      customDomain
      subdomain
      appearance {
        id
        showNavbarBg
        showBranding
        logoBorderRadius
        changelogTitle
        changelogSubtitle
        roadmapTitle
        roadmapSubtitle
        showRoadmap
        showChangelog
        showStatus {
            pending
            inprogress
            completed
            considered
            planned
        }
        logoShadow
        titleText
        subtitleText
        wouldPay
        customHeadings {
          pending
          inprogress
          completed
          considered
          planned
        }
      }
    }
  }
`

export const UPDATE_PAGE = gql`
  mutation UpdatePage(
    $name: String!
    $pageId: ID!
    $logo: Upload
    $appearance: PageAppearanceInput!
  ) {
    updatePage(
      pageId: $pageId
      input: { name: $name, logo: $logo, appearance: $appearance }
    ) {
      id
      name
      logoUrl
      customDomain
      subdomain
      appearance {
        id
        showNavbarBg
        showBranding
        changelogTitle
        changelogSubtitle
        roadmapTitle
        roadmapSubtitle
        showRoadmap
        showChangelog
        showStatus {
            considered
            planned
            pending
            inprogress
            completed
        }
        logoShadow
        logoBorderRadius
        titleText
        subtitleText
        wouldPay
        customHeadings {
          pending
          inprogress
          completed
          considered
          planned
        }
      }
    }
  }
`

export const UPDATE_PAGE_PREFS = gql`
  mutation UpdatePagePrefs($prefs: PageAppearanceInput!, $pageId: ID!) {
    updatePrefs(pageId: $pageId, input: $prefs) {
      id
      name
      logoUrl
      subdomain
      appearance {
        id
        showNavbarBg
        logoShadow
        logoBorderRadius
        titleText
        subtitleText
        showBranding
        changelogTitle
        changelogSubtitle
        roadmapTitle
        roadmapSubtitle
        showRoadmap
        showChangelog
        showStatus {
            considered
            planned
            completed
            inprogress
        }
        wouldPay
        customHeadings {
          pending
          inprogress
          completed
          considered
          planned
        }
      }
    }
  }
`

export const CREATE_ORG = gql`
  mutation CreateOrg($name: String!) {
    createOrganisation(input: { name: $name }) {
      id
      name
    }
  }
`

export const SUBSCRIBE_USER_TO = gql`
  mutation SubscribeToPost($entity: SubscriptionEntityType!, $entId: ID!) {
    subscribeUserTo(input: { entity: $entity, entityId: $entId }) {
      id
    }
  }
`

export const UPDATE_CONSENT = gql`
  mutation UpdateConsent($consent: Json!) {
    updateConsent(consent: $consent) {
      id
      name
      imageUrl
      type
    }
  }
`

export const UPDATE_NOTIFICATION_PREFS = gql`
  mutation UpdateNotificationPrefs(
    $prefs: UpdateNotificationPreferencesInput!
  ) {
    updateNotificationPreferences(preferences: $prefs) {
      id
      name
      imageUrl
      type
      notificationPreferences {
        feedback
        essential
        productUpdates
        emails
      }
    }
  }
`

export const MARK_DUPLICATE = gql`
  mutation MarkDuplicate($duplicateId: ID!, $duplicateOfId: ID!) {
    markDuplicate(duplicateId: $duplicateId, duplicateOfId: $duplicateOfId) {
      id
      title
      votes
      insertedAt
      status
      updatedAt
      description
      rejectedReason
      plannedDate
      wouldPay
    }
  }
`

export const UPDATE_FEATURE_STATUS = gql`
  mutation UpdateFeatureStatus($reqId: ID!, $input: UpdateStatusInput!) {
    updateStatus(featureRequestId: $reqId, input: $input) {
      id
      status
    }
  }
`

export const DELETE_FEATURE = gql`
  mutation DeleteRequest($reqId: ID!) {
    deleteRequest(featureRequestId: $reqId) {
      id
      status
      deleted
    }
  }
`

export const DEDUPE_FEATURE = gql`
  mutation dedupeFeature($reqId: ID!) {
    dedupeRequest(featureRequestId: $reqId) {
      id
      status
      isDuplicate
      duplicateOfId
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`
export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CommentCreateInput!) {
    createComment(input: $input) {
      id
      body
      insertedAt
      submitter {
        id
        name
        imageUrl
      }
    }
  }
`
export const CREATE_NEW_SUGGESTION = gql`
  mutation CreateSuggestion($input: FeatureRequestCreateInput!) {
    createRequest(input: $input) {
      id
      title
      description
      insertedAt
      plannedDate
      labels {
        id
        type
        color
        text
      }
      status
      submitter {
        name
      }
    }
  }
`

export const UPDATE_SUGGESTION = gql`
  mutation UpdateSuggestion($input: FeatureRequestCreateInput!, $id: ID!) {
    updateRequest(input: $input, id: $id) {
      id
      title
      description
      insertedAt
      plannedDate
      status
      labels {
        id
        type
        color
        text
      }
    }
  }
`

export const VOTE_REQ = (type: "addVote" | "removeVote") => gql`
  mutation upvoteReq($requestId: ID!) {
    ${type}(featureRequestId: $requestId) {
      id
      votes
    }
  }
`

export const GET_ORG = gql`
  query getOrg($id: ID!) {
    organisation(id: $id) {
      id
      name
      slackSetup
      pages {
        id
        name
        logoUrl
        appearance {
          id
          showNavbarBg
          showBranding
          logoBorderRadius
          logoShadow
          titleText
          subtitleText
          wouldPay
          customHeadings {
            pending
            inprogress
            completed
          }
        }
      }
    }
  }
`

export const GET_PAGE = gql`
  query getPage($id: ID, $subdomain: String) {
    page(id: $id, subdomain: $subdomain) {
      id
      name
      logoUrl
      subdomain
      customDomain
      labels {
        id
        type
        color
        text
      }
      featureRequestsCounts {
        id
        pending
        completed
        accepted
        rejected
      }
      featureRequests(limit: 10, offset: 0, status: "pending") {
        id
        title
        votes
        labels {
          id
          type
          color
          text
        }
        insertedAt
        status
        updatedAt
        description
        wouldPay
        deleted
        commentCount
        isDuplicate
        showcase {
          id
        }
        submitter {
          id
          name
        }
      }
      organisation {
        id
        insertedAt
        planDetails {
          level
          suggestions
          pages
          users
          customDomain
        }
      }
      appearance {
        id
        showNavbarBg
        showBranding
        logoBorderRadius
        logoShadow
        titleText
        subtitleText
        changelogTitle
        changelogSubtitle
        roadmapTitle
        roadmapSubtitle
        showRoadmap
        showChangelog
        showStatus {
            considered
            planned
            pending
            inprogress
            completed
        }
        wouldPay
        customHeadings {
          pending
          inprogress
          completed
          considered
          planned
        }
      }
    }
  }
`

export const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      name
      imageUrl
      type
      insertedAt
      notificationPreferences {
        feedback
        essential
        productUpdates
        emails
      }
      subscriptions {
        id
        entityId
        entityType
      }
      pages {
        id
        name
        subdomain
      }
      organisations {
        id
        name
        slackSetup
        planDetails {
          level
          users
          customDomain
        }
      }
    }
  }
`

export const GET_ORG_USERS = gql`
  query GetOrgUsers {
    me {
      id
      organisations {
        id
        planDetails {
          users
        }
        users {
          id
          name
        }
      }
    }
  }
`

export const GET_FEATURE_COUNTS = gql`
  query getFeatureCounts($subdomain: String!) {
    page(subdomain: $subdomain) {
      id
      featureRequestsCounts {
        id
        pending
        completed
        accepted
        rejected
        considered
        planned
      }
    }
  }
`

export const GET_ALL_FEATURES_LITE = gql`
  query getAllFeaturesLite($subdomain: String!) {
    page(subdomain: $subdomain) {
      id
      featureRequests(limit: 500, offset: 0, status: "all") {
        id
        title
        votes
        labels {
          id
          type
          color
          text
        }
        insertedAt
        status
      }
    }
  }
`

export const GET_FEATURES = gql`
  query getFeatures(
    $subdomain: String!
    $offset: Int
    $status: String
    $searchTerm: String
    $labels: [String]
  ) {
    page(subdomain: $subdomain) {
      id
      featureRequests(
        limit: 50
        offset: $offset
        status: $status
        filters: { status: $status, labels: $labels, searchTerm: $searchTerm }
      ) {
        id
        title
        votes
        insertedAt
        status
        description
        updatedAt
        wouldPay
        plannedDate
        labels {
          id
          type
          color
          text
        }
        deleted
        commentCount
        isDuplicate
        showcase {
          id
        }
        submitter {
          id
          name
        }
      }
    }
  }
`

export const GET_COMPLETED_FEATURES = gql`
  query getFeatures(
    $subdomain: String!
    $offset: Int
    $status: String
    $searchTerm: String
    $labels: [String]
  ) {
    page(subdomain: $subdomain) {
      id
      featureRequests(
        limit: 50
        offset: $offset
        status: $status
        filters: { status: $status, labels: $labels, searchTerm: $searchTerm }
      ) {
        id
        title
        votes
        insertedAt
        status
        description
        updatedAt
        wouldPay
        plannedDate
        labels {
          id
          type
          color
          text
        }
        deleted
        commentCount
        isDuplicate
        showcase {
          id
          title
          description
          why_built
          image_url
          added
          inserted_at
        }
        submitter {
          id
          name
        }
      }
    }
  }
`

export const GET_FEATURE = gql`
  query getFeature($reqId: ID!) {
    featureRequest(id: $reqId) {
      id
      title
      votes
      insertedAt
      status
      updatedAt
      description
      submitter {
        id
        name
        imageUrl
      }
      rejectedReason
      plannedDate
      labels {
        id
        type
        color
        text
      }
      wouldPay
      isDuplicate
      duplicateOfId
      imageUrl
      showcase {
        id
        title
        description
        whyBuilt
        added
        imageUrl
      }
      duplicates {
        id
        title
        insertedAt
        wouldPay
        votes
      }
      comments {
        id
        body
        insertedAt
        submitter {
          id
          name
          imageUrl
        }
      }
    }
  }
`

export const GET_FEATURE_LITE = gql`
  query getFeatureLite($reqId: ID!) {
    featureRequest(id: $reqId) {
      id
      title
      votes
      insertedAt
      status
      updatedAt
      labels {
        id
        type
        color
        text
      }
      description
      rejectedReason
      plannedDate
      wouldPay
    }
  }
`

export const CREATE_FINISHED_FEATURE = gql`
  mutation CreateFinishedFeature($input: FinishedFeatureCreateInput!) {
    createFinishedFeature(input: $input) {
      id
      title
      description
      whyBuilt
      added
      imageUrl
    }
  }
`
export const UPDATE_FINISHED_FEATURE = gql`
  mutation UpdateFinishedFeature(
    $input: FinishedFeatureUpdateInput!
    $finishedFeatureId: ID!
  ) {
    updateFinishedFeature(input: $input, id: $finishedFeatureId) {
      id
      title
      description
      whyBuilt
      added
      imageUrl
    }
  }
`

export const GET_FINISHED_FEATURE = gql`
  query getFinishedFeature($finishedFeatureId: ID!) {
    finishedFeature(id: $finishedFeatureId) {
      id
      title
      description
      whyBuilt
      added
      imageUrl
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      name
      imageUrl
      type
    }
  }
`

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input)
  }
`

export const REMOVE_USER = gql`
  mutation RemoveUser($userId: ID!, $orgId: ID!) {
    removeUser(userId: $userId, orgId: $orgId)
  }
`

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      email
      name
      imageUrl
      type
    }
  }
`

export const ADD_DOMAIN = gql`
  mutation AddCustomDomain($input: AddCustomDomainInput!, $pageId: ID!) {
    addCustomDomain(input: $input, pageId: $pageId) {
      id
      customDomain
    }
  }
`

export const CREATE_BILLING_SESSION = gql`
  mutation CreateBillingSession($orgId: ID!) {
    createBillingSession(orgId: $orgId) {
      id
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($orgId: ID!) {
    cancelSubscription(orgId: $orgId)
  }
`

export const UPDATE_LABEL = gql`
  mutation updateLabel($labelId: ID!, $input: LabelInput!) {
    updateLabel(labelId: $labelId, input: $input) {
      id
      type
      text
      color
    }
  }
`

export const CREATE_LABEL = gql`
  mutation createLabel($pageId: ID!, $input: LabelInput!) {
    createLabel(pageId: $pageId, input: $input) {
      id
      type
      text
      color
    }
  }
`

export const DELETE_LABEL = gql`
  mutation updateLabel($labelId: ID!) {
    deleteLabel(labelId: $labelId) {
      id
    }
  }
`
