import {Link, navigate, RouteComponentProps} from "@reach/router"
import Container from "components/Container"
import {UserContext} from "Contexts"
import {PageContext} from "Contexts/PageContext"
import useBool from "hooks/useBool"
import {GET_FEATURES, GET_FEATURE_COUNTS} from "modules/queries"
import NoSuggestions from "pages/Features/FeatureList/NoSuggestions"
import {FeatureStatusCounts} from "pages/Features/types"
import {defaultPrefs} from "pages/settings/helpers"
import * as R from "ramda"
import React, {useContext, useEffect, useState} from "react"
import {useQuery} from "react-apollo"
import {AnimatedBox, Box, Button, Flex, Heading, Input} from "ui"
import Loader from "ui/Loader"
import FeatureRequestList from "./FeatureRequestList"
import FilterTab from "./FilterTab"
import Search from "./Search"
import SearchIcon from "components/Search"

export const anyCounts = (counts: FeatureStatusCounts) =>
    counts?.pending > 0 || counts?.accepted > 0 || counts?.completed > 0

export const handleNewUpdates = (
    prev: { page: { featureRequests: any[] } },
    {fetchMoreResult}
) => {
    if (!fetchMoreResult) return prev
    return Object.assign({}, prev, {
        page: {
            ...prev.page,
            featureRequests: [...getReqs(prev), ...getReqs(fetchMoreResult)],
        },
    })
}

export const getReqs = (payload): any[] =>
    R.path(["page", "featureRequests"], payload)

export const buildFetchMore = (fetchFunc, reqs) => (page) =>
    fetchFunc({
        variables: {
            offset: reqs.length,
        },
        updateQuery: handleNewUpdates,
    })

const FeatureList: React.FC<RouteComponentProps> = (props) => {
    const statusFilter =
        new URLSearchParams(props.location.search).get("filter") || "pending"

    const [showSearch, toggleSearch] = useBool(false)
    const {subdomain, page} = useContext(PageContext)
    const prefs = defaultPrefs(page)
    const {activity, updateActivity, canManagePage} = useContext(UserContext)
    const [filter, updateFilter] = useState(statusFilter)
    const countsResp = useQuery(GET_FEATURE_COUNTS, {
        variables: {subdomain},
    })

    const {data, error, loading, fetchMore} = useQuery(GET_FEATURES, {
        variables: {subdomain, offset: 0, status: filter},
    })

    useEffect(() => {
        updateFilter(statusFilter)
    }, [statusFilter])

    if (error) {
        return null
    }
    const requests = R.pipe(
        R.pathOr([], ["page", "featureRequests"]),
        R.filter(R.propEq("status", filter))
    )(data)

    const counts: FeatureStatusCounts = R.path(
        ["data", "page", "featureRequestsCounts"],
        countsResp
    )

    const setFilter = (newFilter) => {
        updateFilter(newFilter)
        navigate(`/?filter=${newFilter}`)
    }

    return (
        <Container
            subtitle={
                prefs.subtitleText ||
                `Join other users in helping make ${R.prop("name", page)} better`
            }
            title={
                <>
                    <Flex flexDirection="column">
                        {prefs.titleText || "Feature Requests"}
                    </Flex>
                </>
            }
            location={props.location}
        >
            <Flex width={1} justifyContent="center" mt={3}>
                <Box display={["inline-block", "none"]}>
                    <Flex alignItems="center">
                        <Link to="/new">
                            <Button fontSize={1} variant="primary">
                                + New
                            </Button>
                        </Link>
                        {!showSearch && (
                            <Flex sx={{cursor: "pointer"}} onClick={toggleSearch} mx={3}>
                                <Button
                                    onClick={toggleSearch}
                                    bg="transparent"
                                    color="darkGrey"
                                >
                                    Search
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Box>
            </Flex>
            {showSearch ? (
                <Search onExit={toggleSearch}/>
            ) : (
                <Box width="100%" mt={4}>
                    {countsResp.loading ? (
                        <Loader/>
                    ) : anyCounts(counts) ? (
                        <>
                            <Flex
                                flexWrap="wrap"
                                justifyContent="space-between"
                                width={1}
                                alignItems="center"
                            >
                                <Flex flexWrap="wrap" mb={3} width={1} justifyContent="space-between" mt={3}>
                                    {/*<Box
                    width={[0, 0, 0.2]}
                    display={["block", "none", "block"]}
                  ></Box>*/}
                                    <Box
                                        bg="white"
                                        boxShadow="mid"
                                        borderRadius={16}
                                        width={[1, 0.86, 1]}
                                    >
                                        <Flex flexWrap={"wrap"} justifyContent="center" height="100%" width={1}>
                                            {R.path(["showStatus", "pending"], prefs) ? (
                                                <FilterTab
                                                    number={counts.pending}
                                                    color={"primary"}
                                                    borderRight
                                                    onChange={() => setFilter("pending")}
                                                    text={
                                                        R.path(["customHeadings", "pending"], prefs) ||
                                                        "Suggested"
                                                    }
                                                    selected={filter === "pending"}

                                                />
                                            ) : (<Box/>)}
                                            {R.path(["showStatus", "considered"], prefs) ? (
                                            <FilterTab
                                                number={counts.considered}
                                                color={"primary"}
                                                borderRight
                                                onChange={() => setFilter("considered")}
                                                text={
                                                    R.path(["customHeadings", "considered"], prefs) ||
                                                    "In Review"
                                                }
                                                selected={filter === "considered"}
                                            />
                                            ) : (<Box/>)}
                                          {R.path(["showStatus", "planned"], prefs) ? (
                                                  <FilterTab
                                                number={counts.planned}
                                                color={"primary"}
                                                borderRight
                                                onChange={() => setFilter("planned")}
                                                text={
                                                    R.path(["customHeadings", "planned"], prefs) ||
                                                    "Planned"
                                                }
                                                selected={filter === "planned"}
                                            />
                                          ) : (<Box/>)}
                                          {R.path(["showStatus", "inprogress"], prefs) ? (

                                                  <FilterTab
                                                number={counts.accepted}
                                                color={"success"}
                                                borderRight
                                                onChange={() => setFilter("accepted")}
                                                text={
                                                    R.path(["customHeadings", "inprogress"], prefs) ||
                                                    "In Progress"
                                                }
                                                selected={filter === "accepted"}
                                            />
                                          ) : (<Box/>)}
                                          {R.path(["showStatus", "completed"], prefs) ? (

                                              <FilterTab
                                                onChange={() => setFilter("completed")}
                                                number={counts.completed}
                                                color={"danger"}
                                                text={
                                                    R.path(["customHeadings", "completed"], prefs) ||
                                                    "Completed"
                                                }
                                                selected={filter === "completed"}
                                            />
                                          ) : (<Box/>)}

                                            </Flex>
                                    </Box>

                                </Flex>
                            </Flex>
                            <Flex
                                width={[0, 1]}
                                mt={2}
                                mb={4}
                                flexWrap="wrap"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Box display={["none", "block"]}>
                                    <Flex alignItems="center">
                                        <Flex
                                            sx={{cursor: "pointer"}}
                                            onClick={toggleSearch}
                                            mx={3}
                                        >
                                            <SearchIcon/>
                                        </Flex>
                                        <Button
                                            onClick={() => navigate("/new")}
                                            fontSize={[1, 2]}
                                            variant="primary"
                                        >
                                            + New
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Flex width={1} flexDirection="column" alignItems="center">
                                {loading ? (
                                    <Loader/>
                                ) : R.length(requests) > 0 ? (
                                    <FeatureRequestList
                                        loadMore={buildFetchMore(fetchMore, requests)}
                                        totalCount={counts[filter]}
                                        userVoted={activity.voted}
                                        updateActivity={updateActivity}
                                        requests={requests}
                                        canManagePage={canManagePage}
                                    />
                                ) : (
                                    <Box
                                        boxShadow="card"
                                        pb={5}
                                        borderRadius="0 0 16px 16px"
                                        bg="white"
                                    >
                                        <Flex justifyContent="center">
                                            <NoSuggestions/>
                                        </Flex>
                                    </Box>
                                )}
                            </Flex>
                        </>
                    ) : (
                        <Flex justifyContent="center" width={1}>
                            <NoSuggestions/>
                        </Flex>
                    )}
                </Box>
            )}
        </Container>
    )
}

export default FeatureList
