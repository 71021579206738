import { PageContext } from "Contexts/PageContext"
import auth from "modules/auth"
import { CREATE_USER, GET_ME } from "modules/queries"
import CollectEmail from "pages/Auth/PageUser/CollectEmail"
import Success from "pages/Auth/PageUser/Success"
import VerifyEmail from "pages/Auth/PageUser/VerifyEmail"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import SignInModal from "./Modal"

interface PageUserProps {
  open: boolean
  onSuccess: () => any | void
  onClose: () => any | void
  onSkip: () => any | void
  consented?: boolean
  collectEmail?: boolean
  collectText?: string
  collectTitle?: string
  email?: string
}

export interface CollectFields {
  email: string
  consented: boolean
}

const defaultInput = (email: string | undefined): CollectFields => ({
  email: email || "",
  consented: false
})

const buildPayload = ({ email, consented }: CollectFields) => ({
  email,
  name: "anonymous",
  consent: JSON.stringify({
    storeName: consented,
    storeEmail: consented,
    consentedAt: new Date().toISOString()
  })
})

const PageUserSignIn = ({
  open,
  onClose,
  onSuccess,
  onSkip,
  email: initEmail,
  consented,
  collectEmail,
  collectText,
  collectTitle
}: PageUserProps) => {
  const { page } = useContext(PageContext)
  // will be ignored if collectEmail is false - see input for otp
  const [input, setInput] = useState(defaultInput(initEmail))
  const [step, setStep] = useState(collectEmail ? 1 : 2)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  // If email is passed in don't use input.
  // Probably could refactor this but oh well.
  const userEmail = collectEmail ? input.email : initEmail

  const [createUser, { data }] = useMutation(CREATE_USER, {
    variables: {
      input: buildPayload({
        ...input,
        email: userEmail,
        consented: input.consented || consented
      })
    },
    refetchQueries: [{ query: GET_ME, variables: { pageId: page && page.id } }]
  })

  // Only resend if account exists
  const sendTokenEmail = () => auth.requestUserToken(userEmail)

  // Try resend and if it fails, create a new page user
  const sendTokenEmailOrCreate = () => sendTokenEmail().catch(createUser)

  const onSuccessClose = close => () => {
    onClose()
    close()
  }

  useEffect(() => {
    if (open && !data && step === 2) {
      createUser()
    }
  }, [open, data])

  const signPageUserIn = async (otp: string) => {
    setLoading(true)
    return auth
      .signUserIn(userEmail, otp)
      .then(() => {
        setLoading(false)
        setStep(3)
      })
      .catch(() => {
        setLoading(false)
        setError("Invalid code entered")
        return Promise.reject("Invalid code")
      })
  }

  return (
    <SignInModal open={open}>
      {close =>
        R.cond([
          [
            R.equals(1),
            () => (
              <CollectEmail
                input={input}
                onSubmit={() => {
                  sendTokenEmailOrCreate()
                  setStep(2)
                }}
                setInput={(field: string) => (val: string) =>
                  setInput({ ...input, [field]: val })}
                collectTitle={collectTitle}
                collectText={collectText}
                loading={loading}
                onCancel={close}
              />
            )
          ],
          [
            R.equals(2),
            () => (
              <VerifyEmail
                resendEmail={sendTokenEmail}
                loading={loading}
                error={error}
                onSkip={onSkip}
                onSubmit={otp =>
                  signPageUserIn(otp)
                    .then(onSuccess)
                    .catch(() => null)
                }
                email={userEmail}
              />
            )
          ],
          [R.T, () => <Success onClose={onSuccessClose(close)} />]
        ])(step)
      }
    </SignInModal>
  )
}

export default PageUserSignIn
