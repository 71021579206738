import { RouteComponentProps, Router } from "@reach/router"
import { PageContext } from "Contexts/PageContext"
import ManagementDashboard from "pages/ManagementDashboard/Dashboard"
import Users from "pages/ManagementDashboard/Users"
import AccountSettings from "pages/settings/AccountSettings"
import Contact from "pages/settings/Contact"
import PageSettings from "pages/settings/form"
import Integrations from "pages/settings/Integrations"
import SurveyQuestions from "pages/settings/SurveyQuestions"
import React, { useContext } from "react"
import { Link as ExtLink } from "rebass"
import { AnimatedBox, Box, Flex, Text } from "ui"
import Sidebar from "./Sidebar"
import Billing from "./Billing"
import Labels from "./Labels"
// import PMF from "components/pmf"

const showSuccess = () =>
  new URLSearchParams(window.location.search).get("success") === "true"

const SuccessMessage = ({ page }) => (
  <Flex justifyContent="center">
    <Box
      py={3}
      px={2}
      width={[1, 1, 0.7, 0.3]}
      borderRadius={16}
      bg="successLightest"
    >
      <Text textAlign="center" color="success">
        Your page is ready! 🎉 you can click{" "}
        <ExtLink
          sx={{
            textDecoration: "none",
            fontWeight: "mid",
            color: "success",
          }}
          href={`https://${page && page.subdomain}.kampsite.co`}
        >
          here{" "}
        </ExtLink>
        to view it.
      </Text>
    </Box>
  </Flex>
)

const Manage: React.FC<RouteComponentProps> = ({ location }) => {
  const { page } = useContext(PageContext)
  return (
    <Box width={1} mt={5}>
      {showSuccess() && <SuccessMessage page={page} />}
      {/*{showSuccess() && <PMF onboardingOnly />}*/}
      <Flex alignItems="flex-start" mt={2} justifyContent="center">
        <Box
          height="auto"
          mt={5}
          px={5}
          display={["none", "none", "none", "block"]}
        >
          <Sidebar page={page} location={location} />
        </Box>
        <AnimatedBox
          mb={6}
          width={["350px", "657px", "870px"]}
          mt={4}
          bg="white"
          borderRadius={16}
          p={4}
          boxShadow="card"
        >
          <Router primary={false}>
            <Integrations path="/integrations" />
            <SurveyQuestions page={page} path="/questions" />
            <Contact path="/support" />
            <AccountSettings path="/account" />
            <Users path="/users" />
            <PageSettings page={page} path="/page" />
            <Billing path="/billing" />
            <Labels path="/labels" />
            <ManagementDashboard path="/" />
          </Router>
        </AnimatedBox>
        <Box
          height="800px"
          mx={4}
          display={["none", "none", "none", "block"]}
          sx={{ visibility: "hidden" }}
        >
          <Sidebar page={page} location={location} />
        </Box>
      </Flex>
    </Box>
  )
}

export default Manage
