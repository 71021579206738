import InputWithLabel from "components/Input"
import React from "react"
import { Box, Button, Flex, Heading, Text, Label, Checkbox, Link } from "ui"
import styled from "ui/styled"
import { CollectFields } from "pages/Auth/PageUser/SignIn"

interface CollectEmailProps {
  collectText: string
  collectTitle: string
  input: CollectFields
  setInput: (val: string) => any
  onSubmit: () => any
  loading: boolean
  onCancel: () => any
}

const BigButton = styled(Button)`
  width: 100%;
  ${props =>
    props.disabled &&
    `width: 100% !important; background: ${props.theme.colors.darkGrey};`}
`

const CollectEmail = ({
  collectText,
  collectTitle,
  setInput,
  input,
  onSubmit,
  loading,
  onCancel
}: CollectEmailProps) => {
  return (
    <Flex flexDirection="column">
      <Flex width={1} justifyContent="center" mb={2} mt={3}>
        <Heading color="darkerGrey">{collectTitle}</Heading>
      </Flex>
      <Box my={2}>
        <Text fontSize={1} color="darkGrey" textAlign="center">
          {collectText}
        </Text>
      </Box>
      <Box width={1} my={3}>
        <InputWithLabel
          name="email"
          type="email"
          value={input.email}
          onChange={setInput("email")}
          labelText="Email address"
          labelSubText=""
          placeholder="your.name@gmail.com"
          required={true}
          autoFocus
        />
      </Box>
      <Box my={2}>
        <Label sx={{ fontSize: 0, color: "darkGrey" }}>
          <Checkbox
            checked={input.consented}
            onChange={() => setInput("consented")(!input.consented)}
            id="storeName"
            name="storeName"
          />{" "}
          <span style={{ marginLeft: "8px" }}>
            I consent to Kampsite storing email and sending me updates.{" "}
            <Link href="https://kampsite.co/privacy">Privacy Policy</Link>
          </span>
        </Label>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        my={2}
        width={1}
      >
        <BigButton
          variant="primary"
          disabled={loading || !input.consented}
          onClick={onSubmit}
          fontSize={3}
        >
          {loading ? "Sending email...." : "Submit"}
        </BigButton>
        <Box my={2}>
          <Text
            onClick={onCancel}
            sx={{ "&:hover": { cursor: "pointer", fontWeight: "bold" } }}
            color="darkGrey"
            fontSize={1}
          >
            Cancel
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default CollectEmail
