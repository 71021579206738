import { Location, Redirect, Router } from "@reach/router"
import ScrollTop from "components/ScrollTop"
import { AuthContext, UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import { Page, User } from "Contexts/types"
import { toTitleCase } from "modules/helpers"
import { isType, onboardingCompleted } from "modules/user"
import SignIn from "pages/Auth/SignIn"
import Success from "pages/Auth/Success"
import FeatureList from "pages/Features/FeatureList"
import Roadmap from "pages/Features/Roadmap"
import ChangeLog from "pages/ChangeLog"
import FinishedFeatures from "pages/Features/FinishedFeatures"
import NewFeature from "pages/Features/MutateFeature/New"
import ViewFeature from "pages/Features/ViewFeature"
import ManagementDashboard from "pages/ManagementDashboard"
import { FeaturePageNavbar, NavBar } from "pages/navbar"
import Prefs from "pages/NotificationPrefs"
import FooterLinks from "pages/root/FooterLinks"
import Onboarding from "pages/settings/Onboarding"
import React, { useContext } from "react"
import { ToastProvider } from "react-toast-notifications"
import { Box, Flex } from "ui"
import Loader from "ui/Loader"
import styled from "ui/styled"
import * as R from "ramda"
import EditFeature from "pages/Features/MutateFeature/Edit"

const SRouter = styled(Router)`
  width: 100%;
  margin-bottom: 200px;
`

const onboardingQuestionShowing = () =>
  new URLSearchParams(window.location.search).get("success") === "true"

const PosedRouter: any = ({ children, location }) => {
  return (
    <Box width={1} position="absolute" minHeight="98vh">
      <Flex justifyContent="center">
        <SRouter location={location}>{children}</SRouter>
        {location.pathname !== "/sign-in" && <FooterLinks />}
      </Flex>
    </Box>
  )
}

const needsToDoOnboarding = (user: User, authenticated: boolean) =>
  isType("authenticated_user", user) &&
  !onboardingCompleted(user) &&
  authenticated

const pageTitle = (page: Page, subdomain: string) =>
  `${page ? page.name : toTitleCase(subdomain ?? "")} | Feedback`

const Root = () => {
  const { authenticated } = useContext(AuthContext)
  const { page, subdomain, prefs, loading, isKampsiteApp } = useContext(
    PageContext
  )
  const { user, canManagePage } = useContext(UserContext)
  if (!isKampsiteApp) {
    document.title = pageTitle(page, subdomain)
    if (!page && loading) {
      return (
        <Box mt={5}>
          <Loader />
        </Box>
      )
    }
    return (
      <Box width="100%">
        <Location>
          {({ location }) =>
            location.pathname !== "/sign-in" && (
              <FeaturePageNavbar
                location={location}
                prefs={prefs}
                companyName={page && page.name}
                logo={page && page.logoUrl}
              />
            )
          }
        </Location>
        <ToastProvider placement="top-right">
          <Flex justifyContent="center" mx="auto" maxWidth="1366px">
            <Location>
              {({ location }) => (
                <PosedRouter location={location}>
                  <ScrollTop path="/">
                    <SignIn path="/sign-in" />
                    <Success path="/auth/success" />
                    <NewFeature path="/new" />
                    {canManagePage && (
                      <EditFeature path="/suggestions/:reqId/edit" />
                    )}
                    <ViewFeature path="/suggestions/:reqId" />
                    <FinishedFeatures path="/suggestions/:reqId/finished-features/*" />
                    <FeatureList path="/" />
                    <Roadmap path="/roadmap" />
                    <ChangeLog path="/changelog" />
                    <Prefs path="/consent" />
                    <FinishedFeatures path="/suggestions/:reqId/feature-showcase/*" />
                    {!canManagePage && (
                      <Redirect from="/settings" to="/sign-in" noThrow />
                    )}
                    {!canManagePage && (
                      <Redirect from="/manage" to="/sign-in" noThrow />
                    )}
                    {!authenticated && (
                      <Redirect from="/manage/*" to="/sign-in" noThrow />
                    )}
                    {!authenticated && (
                      <Redirect from="/manage" to="/sign-in" noThrow />
                    )}
                    <ManagementDashboard path="/manage" />
                    <ManagementDashboard path="/manage/*" />
                    <FeatureList path="/" default />
                  </ScrollTop>
                </PosedRouter>
              )}
            </Location>
          </Flex>
        </ToastProvider>
      </Box>
    )
  } else {
    return (
      <Box width="100%">
        <Location>
          {({ location }) =>
            location.pathname !== "/sign-in" && (
              <NavBar
                location={location}
                showMenu={true}
                companyName="Kampsite"
                logo={null}
              />
            )
          }
        </Location>
        <ToastProvider placement="top-right">
          <Flex justifyContent="center" mx="auto" maxWidth="1366px">
            <SRouter>
              <SignIn path="/sign-in" />
              <Prefs path="/consent" />
              <Success path="/auth/success" />
              {!authenticated && <Redirect from="/" to="/sign-in" noThrow />}
              {!authenticated && (
                <Redirect from="/manage" to="/sign-in" noThrow />
              )}
              {!authenticated && (
                <Redirect from="/manage/*" to="/sign-in" noThrow />
              )}
              <Onboarding path="/onboarding" />
              {needsToDoOnboarding(user, authenticated) && (
                <Redirect from="/" to="/onboarding" noThrow />
              )}
              <ManagementDashboard path="/manage" />
              <ManagementDashboard path="/manage/*" />
              <ManagementDashboard path="/" />
            </SRouter>
          </Flex>
        </ToastProvider>
      </Box>
    )
  }
}

export default Root
