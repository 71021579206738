import { Global } from "@emotion/core"
import { AuthProvider, UserProvider } from "Contexts"
import PageProvider from "Contexts/PageContext"
import { ThemeProvider } from "emotion-theming"
import Pages from "pages"
import React from "react"
import { ITheme, preset } from "ui/theme"
import Apollo from "./api"

const globalStyles = {
  body: {
    margin: 0,
    background: "#FAFAFB",
    fontFamily:
      "Poppins,-apple-system,BlinkMacSystemFont,Avenir,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,Helvetica,sans-serif",
  },
}

const App = () => (
  <Apollo>
    <AuthProvider>
      <PageProvider>
        <UserProvider>
          <Global styles={globalStyles} />
          <ThemeProvider<ITheme> theme={preset}>
            <Pages />
          </ThemeProvider>
        </UserProvider>
      </PageProvider>
    </AuthProvider>
  </Apollo>
)

export default App
