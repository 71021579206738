import React from "react"
import BounceLoader from "react-spinners/BounceLoader"
import { Flex } from "./index"

const Loader = () => (
  <Flex bg="transparent" justifyContent="center" width={1}>
    <BounceLoader color="#0062FF" />
  </Flex>
)

export default Loader
