import { Box as Rbox, BoxProps } from "rebass"
import styled from "./theme"

import {
  overflow,
  height,
  width,
  border,
  shadow,
  position,
  WidthProps,
  HeightProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  FlexProps,
  FlexboxProps
} from "styled-system"

export interface IBoxProps {
  cursor?: string
}

export type MyBoxProps = IBoxProps &
  WidthProps &
  HeightProps &
  BorderProps &
  ShadowProps &
  PositionProps &
  BoxProps &
  FlexProps &
  FlexboxProps

const Box = styled(Rbox)<MyBoxProps>`
  ${overflow}
  ${height};
  ${width};
  ${border};
  ${shadow};
  ${position}
  ${props =>
    props.cursor
      ? `&:hover {
        cursor: ${props.cursor};
      }`
      : null}
`
export default Box
