import React from "react"
import { Box, Flex, Text } from "ui"

const AnalyticCard = ({ title, number, subtext, color }) => (
  <Box p={3}>
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex mb={2} justifyContent="center">
        <Box
          bg={`${color}Lightest`}
          py={1}
          px={2}
          width="auto"
          borderRadius={8}
        >
          <Text sx={{ fontWeight: "mid" }} color={color}>
            {title}
          </Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <Text fontSize={5} sx={{ fontWeight: "mid" }}>
          {number}
        </Text>
        <Text fontSize={1} color={"muted"}>
          {subtext}
        </Text>
      </Flex>
    </Flex>
  </Box>
)

export default AnalyticCard
