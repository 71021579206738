import { WindowLocation, navigate } from "@reach/router"
import React from "react"
import { Box, Flex, Heading, Text } from "ui"

export interface DashboardProps {
  title: any
  location: WindowLocation
  subtitle?: string
  showBack?: boolean
}

const Dashboard: React.FC<DashboardProps> = ({
  title,
  children,
  location,
  subtitle,
  showBack = true
}) => {
  return (
    <Box mb={6} maxWidth={["350px", "657px", "870px"]} mt={4} mx="auto">
      {showBack && location.pathname !== "/" && (
        <Text
          onClick={() => navigate("/")}
          color="muted"
          sx={{ "&:hover": { cursor: "pointer" }, fontWeight: "mid" }}
        >
          ← Back
        </Text>
      )}
      <Flex flexDirection="column" alignItems="center">
        <Heading
          as="h1"
          mt={0}
          mb={3}
          sx={{
            fontWeight: "mid",
            fontSize: [5, 6],
            textAlign: "center"
          }}
        >
          <Flex alignItems="center">{title}</Flex>
        </Heading>
        {subtitle && (
          <Text textAlign="center" mb={0} color="muted">
            {subtitle}
          </Text>
        )}
        <Box width={1}>{children}</Box>
      </Flex>
    </Box>
  )
}

export default Dashboard
