import { RouteComponentProps } from "@reach/router"
import React, { useEffect, useContext } from "react"
import { Box, Flex, Divider, Heading, Text, Card, Button } from "ui"
import CurrentPlan from "./CurrentPlan"
import { useMutation } from "react-apollo"
import {
  CREATE_BILLING_SESSION,
  CANCEL_SUBSCRIPTION,
  GET_PAGE,
} from "modules/queries"
import { loadStripe } from "@stripe/stripe-js/pure"
import Loader from "ui/Loader"
import { PageContext } from "Contexts/PageContext"
import * as R from "ramda"
import { useToasts } from "react-toast-notifications"
import { extractGraphqlErrors } from "modules/helpers"
import { PlanDetails } from "Contexts/types"

const Billing: React.FC<RouteComponentProps> = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
  const { addToast } = useToasts()
  const { page } = useContext(PageContext)
  const org = R.prop("organisation", page)
  const [getSession, { data, loading }] = useMutation(CREATE_BILLING_SESSION, {
    variables: { orgId: R.prop("id", org) },
  })

  const [cancelSubscription, cancelResp] = useMutation(CANCEL_SUBSCRIPTION, {
    variables: { orgId: R.prop("id", org) },
    refetchQueries: [
      { query: GET_PAGE, variables: { subdomain: page && page.subdomain } },
    ],
  })

  const cancelModal = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel your Kampsite plus subscription?"
      )
    ) {
      cancelSubscription()
    }
  }

  useEffect(() => {
    if (data) {
      stripePromise.then((stripe) =>
        stripe.redirectToCheckout({ sessionId: data.createBillingSession.id })
      )
    }
  }, [data])

  useEffect(() => {
    if (cancelResp.data) {
      addToast("Subscription Cancelled", {
        appearance: "success",
        autoDismiss: true,
      })
    } else if (cancelResp.error) {
      addToast(extractGraphqlErrors(cancelResp.error), { appearance: "error" })
    }
  }, [cancelResp])

  if (loading || cancelResp.loading) {
    return <Loader />
  }

  const plan: PlanDetails = R.propOr({}, "planDetails", org)

  return (
    <Box width={1}>
      <Heading textAlign="center" fontSize={5}>
        Billing
      </Heading>

      <Flex
        width={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        <Box>
          <CurrentPlan
            plan={plan.level}
            pages={plan.pages}
            users={plan.users}
            customDomain={plan.customDomain}
            maxSuggestions={plan.suggestions}
          />
        </Box>
        {plan.level !== "plus-free" && (
          <Box>
            {plan.level === "starter" ? (
              <Button onClick={() => getSession()}>Upgrade</Button>
            ) : (
              <Button onClick={cancelModal} variant="outline">
                Cancel subscription
              </Button>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default Billing
