import { navigate } from "@reach/router"
import { extractGraphqlErrors } from "modules/helpers"
import { DELETE_FEATURE, GET_FEATURES } from "modules/queries"
import ModalContent from "pages/Features/FeatureRequest/Actions/ModalContent"
import React, { useEffect } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Text } from "ui"
import * as R from "ramda"

const DeleteFeatureModalContent = () => (
  <Box width={1}>
    <Text
      mb={2}
      sx={{
        fontWeight: "mid",
        fontSize: [3, 3, 4]
      }}
    >
      Delete Suggestion
    </Text>
    <Text color="darkGrey" sx={{ whiteSpace: "pre-wrap" }}>
      Are you sure you want to delete this suggestion? If you meant to reject
      it, please click cancel instead.
    </Text>
  </Box>
)

const DeleteFeatureModal = ({ isOpen, reqId, subdomain, onClose }) => {
  const [deleteRequest, deleteRes] = useMutation(DELETE_FEATURE, {
    variables: { reqId }
  })
  const { addToast } = useToasts()
  useEffect(() => {
    if (deleteRes.data) {
      addToast("Suggestion deleted", {
        appearance: "success",
        autoDismiss: true
      })
      navigate("/")
    } else if (deleteRes.error) {
      addToast(extractGraphqlErrors(deleteRes.error), {
        appearance: "error",
        autoDismiss: true
      })
    }
  }, [deleteRes, addToast])

  return (
    <ModalContent
      onClose={onClose}
      buttonText="Delete"
      open={isOpen}
      onSubmit={deleteRequest}
      buttonDisabled={false}
    >
      <DeleteFeatureModalContent />
    </ModalContent>
  )
}

export default DeleteFeatureModal
