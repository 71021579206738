import React from "react"
import Popup from "reactjs-popup"
import { Box, Button, Flex } from "ui"

interface IModalContentProps {
  children: any
  onSubmit: () => any
  buttonText: string
  buttonDisabled: boolean
  open: boolean
  onClose: () => any
}

const ModalContent = ({
  open,
  children,
  buttonText,
  onSubmit,
  buttonDisabled,
  onClose
}: IModalContentProps) => (
  <Popup
    open={open}
    modal
    closeOnDocumentClick
    lockScroll
    onClose={onClose}
    contentStyle={{
      borderRadius: 8,
      width: "unset",
      padding: "8px",
      background: "#fafafb"
    }}
  >
    {close => (
      <Box
        className="modal"
        borderRadius={8}
        width={["90vw", "50vw", "30vw"]}
        py={2}
        px={3}
      >
        {children}
        <Flex mt={3} justifyContent="flex-end">
          <Button
            mr={3}
            type="submit"
            onClick={close}
            size="large"
            variant="outlineDanger"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={buttonDisabled}
            onClick={() => onSubmit() && close()}
            size="large"
            variant="primary"
          >
            {buttonText}
          </Button>
        </Flex>
      </Box>
    )}
  </Popup>
)

export default ModalContent
