import { navigate, RouteComponentProps } from "@reach/router"
import { AuthContext } from "Contexts"
import useMe from "hooks/useMe"
import React, { useContext, useEffect, useState } from "react"
import auth from "modules/auth"
import { useLazyQuery } from "react-apollo"
import { GET_ME } from "modules/queries"

const Success: React.FC<RouteComponentProps> = ({ location }) => {
  const [ready, setReady] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")
  auth.setSession({ accessToken: token })
  const validPages = ["manage", "onboarding", "consent"]
  const { setAuthenticated } = useContext(AuthContext)
  const [fetchMe, { data }] = useLazyQuery(GET_ME)

  const refresh = "/api/auth/refresh"

  useEffect(() => {
    !ready &&
      fetch(refresh, {
        method: "POST",
        body: JSON.stringify({ refresh: token }),
        headers: {
          "content-type": "application/json",
        },
      }).then((resp) => {
        auth.setSession(resp)
        setAuthenticated(true)
        fetchMe()
        setReady(true)
      })
  }, [ready])

  const page = searchParams.get("page")

  useEffect(() => {
    if (ready && data) {
      console.log("Sign in successful, redirecting")
      navigate(`/${page}`)
    }
  }, [data, ready])

  return null
}

export default Success
