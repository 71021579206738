import { PageContext } from "Contexts/PageContext"
import ChangeStatusModal from "pages/Features/FeatureRequest/Actions/ChangeStatusModal"
import DedupeModal from "pages/Features/FeatureRequest/Actions/DedupeModal"
import DeleteFeatureModal from "pages/Features/FeatureRequest/Actions/DeleteFeatureModal"
import MarkDuplicateModal from "pages/Features/FeatureRequest/Actions/MarkDuplicateModalContent"
import ThreeDots from "pages/Features/FeatureRequest/Actions/ThreeDots"
import { IFeatureRequest } from "pages/Features/types"
import React, { useContext, useState, useMemo } from "react"
import Popover from "react-tiny-popover"
import { Box, Flex } from "ui"
import styled from "ui/styled"
import { navigate } from "@reach/router"

export const MenuItem = styled(Box)`
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 4px 16px;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.fontWeights.mid};
    cursor: pointer;
  }
`

export const MenuButton = styled(Flex)`
  font-weight: 900;
  letter-spacing: 3px;
  user-select: none;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
`

const Actions = ({ request }: { request: IFeatureRequest }) => {
  const { subdomain } = useContext(PageContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const [changeStatusOpen, setChangeStatusOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [dedupeOpen, setDedupOpen] = useState(false)

  const openCloseMenu = () => setMenuOpen(!menuOpen)
  const openCloseChangeStatusModal = () =>
    setChangeStatusOpen(!changeStatusOpen)
  const openCloseDeleteModal = () => setDeleteOpen(!deleteOpen)
  const openCloseDuplicateModal = () => setDuplicateOpen(!duplicateOpen)
  const openCloseDedupeModal = () => setDedupOpen(!dedupeOpen)

  return (
    <Box key={request.id}>
      <DeleteFeatureModal
        isOpen={deleteOpen}
        subdomain={subdomain}
        reqId={request.id}
        onClose={openCloseDeleteModal}
      />
      {!request.isDuplicate && (
        <>
          <ChangeStatusModal
            isOpen={changeStatusOpen}
            request={request}
            onClose={openCloseChangeStatusModal}
          />
          <MarkDuplicateModal
            onClose={openCloseDuplicateModal}
            isOpen={duplicateOpen}
            subdomain={subdomain}
            duplicateRequest={request}
          />
        </>
      )}
      {request.isDuplicate && (
        <DedupeModal
          isOpen={dedupeOpen}
          reqId={request.id}
          onClose={openCloseDedupeModal}
        />
      )}
      <Popover
        isOpen={menuOpen}
        position="bottom"
        containerStyle={{
          overflow: "visible",
        }}
        onClickOutside={openCloseMenu}
        content={() => (
          <Box
            border="1px solid #f1f1f1"
            boxShadow="mid"
            bg="white"
            py={3}
            borderRadius={16}
          >
            <Flex flexDirection="column">
              {!request.isDuplicate && (
                <>
                  <MenuItem
                    onClick={() => {
                      openCloseChangeStatusModal()
                      openCloseMenu()
                    }}
                  >
                    Change status
                  </MenuItem>
                  {!(request.status === "completed") && (
                    <MenuItem
                      onClick={() => {
                        openCloseDuplicateModal()
                        openCloseMenu()
                      }}
                    >
                      Mark as duplicate
                    </MenuItem>
                  )}
                </>
              )}
              {request.isDuplicate && (
                <MenuItem
                  onClick={() => {
                    openCloseDedupeModal()
                    openCloseMenu()
                  }}
                >
                  Deduplicate Request
                </MenuItem>
              )}
              {
                <MenuItem
                  onClick={() => navigate(`/suggestions/${request.id}/edit`)}
                >
                  Edit Suggestion
                </MenuItem>
              }
              {request.status === "completed" && request.showcase && (
                <MenuItem
                  onClick={() =>
                    navigate(
                      `/suggestions/${request.id}/feature-showcase/${request.showcase.id}/edit`
                    )
                  }
                >
                  Edit feature showcase
                </MenuItem>
              )}
              {request.status === "completed" && !request.showcase && (
                <MenuItem
                  onClick={() =>
                    navigate(`/suggestions/${request.id}/feature-showcase/new`)
                  }
                >
                  Create feature showcase
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  openCloseDeleteModal()
                  openCloseMenu()
                }}
              >
                Delete
              </MenuItem>
            </Flex>
          </Box>
        )}
      >
        <MenuButton onClick={openCloseMenu}>
          <ThreeDots />
        </MenuButton>
      </Popover>
    </Box>
  )
}
export default Actions
