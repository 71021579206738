import { RouteComponentProps, navigate } from "@reach/router"
import { UPDATE_FINISHED_FEATURE, GET_FINISHED_FEATURE } from "modules/queries"
import Container from "components/Container"
import CreateFinishedFeatureForm from "pages/Features/FinishedFeatures/New/form"
import {
  FinishedFeature,
  FinishedFeatureCreateInput
} from "pages/Features/FinishedFeatures/types"
import React, { useState, useEffect } from "react"
import { useMutation, useQuery } from "react-apollo"
import Loader from "ui/Loader"
import { useToasts } from "react-toast-notifications"
import * as R from "ramda"

const buildCreateInput = (
  finishedFeature: FinishedFeature
): FinishedFeatureCreateInput =>
  R.pipe(
    R.pick(["title", "description", "added", "whyBuilt"]),
    R.assoc("image", null)
  )(finishedFeature)

const EditFinishedFeature: React.FC<{
  finishedFeature: FinishedFeature
  reqId: string
}> = ({ finishedFeature, reqId }) => {
  const { addToast } = useToasts()
  const [input, setInput] = useState(buildCreateInput(finishedFeature))
  const [updateFinishedFeature, { data, loading }] = useMutation(
    UPDATE_FINISHED_FEATURE,
    {
      variables: { input, finishedFeatureId: finishedFeature.id }
    }
  )

  const setField = (field: keyof FinishedFeatureCreateInput) => (val: any) =>
    setInput({ ...input, [field]: val })

  useEffect(() => {
    if (data) {
      const id = R.path(["updateFinishedFeature", "id"], data)
      addToast("Showcase updated", { appearance: "success", autoDismiss: true })
      navigate(`/suggestions/${reqId}/feature-showcase/${id}`)
    }
  }, [data])

  return (
    <CreateFinishedFeatureForm
      input={input}
      setInput={setField}
      loading={loading}
      submit={updateFinishedFeature}
    />
  )
}

const EditFinishedFeatureContainer: React.FC<RouteComponentProps & {
  reqId?: string
  finishedFeatureId?: string
}> = ({ finishedFeatureId, location, reqId }) => {
  const { data } = useQuery(GET_FINISHED_FEATURE, {
    variables: { finishedFeatureId }
  })

  if (data) {
    return (
      <Container location={location} title="Edit Feature Showcase">
        <EditFinishedFeature
          reqId={reqId}
          finishedFeature={data.finishedFeature}
        />
      </Container>
    )
  }

  return <Loader />
}

export default EditFinishedFeatureContainer
