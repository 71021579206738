import React from "react"
import { Button, Flex, Heading, Image } from "ui"
import illustration from "./Illustration.svg"
import { Link } from "@reach/router"

const Illustration = () => <Image width="80%" src={illustration} />

const NoSuggestions = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    mt={[4, 5]}
    width={[1, 0.6]}
  >
    <Heading fontSize={[4, 5]} fontWeight="normal" textAlign="center">
      Doesn't look like there are any suggestions yet{" "}
      <span role="img" aria-label="sad-face">
        😔
      </span>
    </Heading>
    <Flex justifyContent="center" width="100%" mt={4}>
      <Illustration />
    </Flex>
    <Heading
      my={3}
      color="muted"
      fontSize={[3, 4]}
      fontWeight="normal"
      textAlign="center"
    >
      Why don't you add one?
    </Heading>

    <Flex justifyContent="center">
      <Link to="/new">
        <Button fontSize={[1, 2]} variant="primary">
          + New
        </Button>
      </Link>
    </Flex>
  </Flex>
)

export default NoSuggestions
