import React, { useContext } from "react"
import { RouteComponentProps } from "@reach/router"
import { useQuery } from "react-apollo"
import { GET_FINISHED_FEATURE } from "modules/queries"
import Container from "components/Container"
import Loader from "ui/Loader"
import FinishedFeature from "pages/Features/FinishedFeatures/View/FinishedFeature"
import { Flex } from "ui"
import { UserContext } from "Contexts"

const ViewFinishedFeature: React.FC<RouteComponentProps & {
  finishedFeatureId?: string
  reqId?: string
}> = ({ finishedFeatureId, location, reqId, navigate }) => {
  const { data, loading } = useQuery(GET_FINISHED_FEATURE, {
    variables: { finishedFeatureId }
  })
  const { canManagePage } = useContext(UserContext)

  return (
    <Container title="" location={location} showBack={false}>
      {loading ? (
        <Loader />
      ) : (
        <Flex width={1} justifyContent="center">
          <FinishedFeature
            canManagePage={canManagePage}
            navigate={navigate}
            reqId={reqId}
            finishedFeature={data.finishedFeature}
          />
        </Flex>
      )}
    </Container>
  )
}

export default ViewFinishedFeature
