import { Link as RLink } from "@reach/router"
import React, { useContext } from "react"
import { Box, Flex, Link, Text } from "ui"
import { PageContext } from "Contexts/PageContext"

const FooterLinks = () => {
  const { prefs } = useContext(PageContext)
  return (
    <Box position="absolute" left="0" right="0" mx="auto" bottom="16px">
      <Flex
        width={1}
        justifyContent="center"
        alignItems="center"
        mt={4}
        flexDirection={["column", "row"]}
      >
        <RLink to="/manage">
          <Box variant="nav">
            <Text
              color="darkGrey"
              sx={{
                "&:hover": { cursor: "pointer" },
                position: "abosulte",
                bottom: "20px",
              }}
            >
              Manage Page
            </Text>
          </Box>
        </RLink>
        {/*{prefs.showBranding && (
          <Link href="https://kampsite.co" variant="nav">
            <Text
              color="darkGrey"
              sx={{
                "&:hover": { cursor: "pointer" },
                position: "abosulte",
                bottom: "20px",
              }}
            >
              Powered by Kampsite
            </Text>
          </Link>
        )}*/}
      </Flex>
    </Box>
  )
}

export default FooterLinks
