import { navigate, RouteComponentProps } from "@reach/router"
import { UserContext } from "Contexts"
import { extractGraphqlErrors } from "modules/helpers"
import { UPDATE_NOTIFICATION_PREFS } from "modules/queries"
import { isAuthedUser } from "modules/user"
import Prefs from "pages/NotificationPrefs/Prefs"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Heading } from "rebass"
import { Box, Flex } from "ui"
import Loader from "ui/Loader"
import * as R from "ramda"

//
//
// This is pretty fucked.
//
// The view logic for the single screen should be elsewhere.
//
//

const defaultState = {
  productUpdates: false,
  feedback: false,
  emails: true,
  essential: true
}

const buildPayload = prefs => ({
  productUpdates: prefs.productUpdates || false,
  feedback: prefs.feedback || false,
  emails: R.isNil(prefs.emails) ? true : prefs.emails,
  essential: R.isNil(prefs.essential) ? true : prefs.essential
})

const prefFields = ["productUpdates", "feedback", "emails", "essential"]

const PrefsContainer: React.FC<RouteComponentProps & {
  showBox?: boolean
}> = ({ showBox = true }) => {
  const { user } = useContext(UserContext)
  const [prefs, setPrefs] = useState(defaultState)
  const { addToast } = useToasts()

  const [updatePrefs, { data, loading, error }] = useMutation(
    UPDATE_NOTIFICATION_PREFS,
    {
      variables: { prefs: prefs }
    }
  )

  useEffect(() => {
    isAuthedUser(user) &&
      user.notificationPreferences &&
      setPrefs(buildPayload(R.pick(prefFields, user.notificationPreferences)))
  }, [user])

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
    if (data) {
      addToast("Preferences saved", {
        appearance: "success",
        autoDismiss: true
      })
    }
  }, [error, data])

  if (loading) {
    return <Loader />
  }

  const setPrefsVal = key => () => setPrefs({ ...prefs, [key]: !prefs[key] })

  return showBox ? (
    <Flex justifyContent="center" alignItems="center" height="80vh">
      <Box boxShadow="card" p={4} borderRadius={16} bg="white">
        <Flex maxWidth={"500px"} justifyContent="center" flexDirection="column">
          <Prefs
            prefs={prefs}
            setPrefsVal={setPrefsVal}
            onSubmit={() => updatePrefs().then(() => navigate("/onboarding"))}
            buttonBottom={true}
            showInitialOnly={true}
          />
        </Flex>
      </Box>
    </Flex>
  ) : (
    <Prefs
      prefs={prefs}
      setPrefsVal={setPrefsVal}
      onSubmit={updatePrefs}
      buttonBottom={false}
    />
  )
}

export default PrefsContainer
