import LabelPill from "components/Label"
import * as R from "ramda"
import React from "react"
import { Box, Flex } from "ui"
import { Label } from "Contexts/types"

interface LabelGroupProps {
  labels: Label[]
  maxAllowed?: number
  selectedLabels: Label[]
  onChange: (labelIds) => any | void
}

const hasLabel = (labelId: string) => (labels: Label[]) =>
  R.pipe(R.map(R.prop("id")), R.includes(labelId))(labels)

const updateLabels = (label: Label) =>
  R.ifElse(
    hasLabel(label.id),
    R.reject(R.propEq("id", label.id)),
    R.concat([label])
  )

const LabelGroup = ({
  labels,
  maxAllowed,
  selectedLabels,
  onChange,
}: LabelGroupProps) => (
  <Flex flexWrap="wrap" mt={2}>
    {R.map((label) => {
      const isSelected = hasLabel(label.id)(selectedLabels)
      const canBeClicked =
        (maxAllowed && (selectedLabels.length < 3 || isSelected)) || true
      return (
        <Box
          sx={
            canBeClicked && {
              borderRadius: "10px",
              boxShadow: "mid",
              ":hover": {
                transform: "translateY(-2px)",
                cursor: "pointer",
              },
            }
          }
          mb={3}
          onClick={() =>
            canBeClicked &&
            R.pipe(updateLabels(label), onChange)(selectedLabels)
          }
          mr={3}
          key={label.id}
        >
          <LabelPill label={label} muted={!isSelected} />
        </Box>
      )
    }, labels || [])}
  </Flex>
)

export default LabelGroup
