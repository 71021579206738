import React from "react"
import { Box, Text, Flex } from "ui"
import { Label } from "Contexts/types"

interface LabelPillProps {
  label: Label
  muted?: boolean
}

const LabelPill = ({ label, muted = false }: LabelPillProps) => {
  const color = muted ? "#92929D" : label.color
  return (
    <Box
      height="25px"
      borderRadius={8}
      px={3}
      bg={label.type === "INTERNAL" ? "transparent" : `${color}26`}
      border={label.type === "INTERNAL" ? `2px solid ${color}` : "none"}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Text
          as="p"
          lineHeight={1}
          fontSize={0}
          sx={{ fontWeight: "mid" }}
          color={color}
        >
          {label.text}
        </Text>
      </Flex>
    </Box>
  )
}

export default LabelPill
