import InputWithLabel from "components/Input"
import { UserContext } from "Contexts"
import { extractGraphqlErrors } from "modules/helpers"
import { CREATE_COMMENT, GET_FEATURE } from "modules/queries"
import { NewCommentInput } from "pages/Features/types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Button, Flex, Text, Label, Checkbox } from "ui"
import sha1 from "sha1"
import * as R from "ramda"
import { User } from "Contexts/types"
import { isGuest, isAuthedUser } from "modules/user"

const validConsent = ({ consent, name, id }) =>
  consent.storeName || !!id || R.includes(name, ["", "anonymous"])

const formValid = (attrs: NewCommentInput): boolean =>
  attrs.body &&
  attrs.body.length <= 1000 &&
  attrs.body.length >= 5 &&
  attrs.featureRequestId &&
  attrs.submitter &&
  validConsent(attrs.submitter)
    ? true
    : false

const defaultForm = (user: User, reqId: string) => ({
  body: "",
  featureRequestId: reqId,
  submitter: {
    name: user.name || "",
    id: isAuthedUser(user) ? user.id : null,
    consent: {
      storeName: false,
      storeEmail: false
    }
  }
})

const addDefaultSubmitterName = (name: string) => (input: NewCommentInput) => ({
  ...input,
  submitter: {
    ...input.submitter,
    name:
      input.submitter.name !== "" ? input.submitter.name : name || "anonymous"
  }
})

const consentPath = ["submitter", "consent"]

const buildSubmitPayload = (input, userName) =>
  R.pipe(
    addDefaultSubmitterName(userName),
    R.assocPath(consentPath, JSON.stringify(R.path(consentPath, input)))
  )(input)

const NewCommentForm = ({ reqId }: { reqId: string }) => {
  const { addToast } = useToasts()
  const { user, canManagePage } = useContext(UserContext)
  const [input, setInput] = useState(defaultForm(user, reqId))
  const [honeyPot, setHoneyPot] = useState("")

  const [createComment, { loading, error, data }] = useMutation(
    CREATE_COMMENT,
    {
      variables: { input: buildSubmitPayload(input, user.name) },
      refetchQueries: [{ query: GET_FEATURE, variables: { reqId } }],
      context: {
        headers: {
          "x-content-fxt": sha1(`${reqId}-${sha1(input.submitter.id || "nil")}`)
        }
      }
    }
  )

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [error, addToast])

  useEffect(() => {
    if (data) {
      addToast("Comment added", { appearance: "success", autoDismiss: true })
      setInput(defaultForm(user, reqId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user, reqId])

  return (
    <Box mb={5}>
      <Flex width={1} flexDirection="column">
        <Text
          ml={2}
          mb={2}
          sx={{
            fontWeight: "mid",
            fontSize: [3, 3, 4]
          }}
        >
          New Comment
        </Text>
        <Box width={1}>
          <InputWithLabel
            name="body"
            type="text"
            placeholder="Enter your comment here...."
            labelText=""
            labelSubText=""
            value={input.body}
            textArea
            rows={3}
            onChange={body => setInput({ ...input, body })}
            autoFocus={false}
            maxLength={1000}
            minLength={5}
          />
        </Box>
        {!canManagePage && (
          <Box my={2} maxWidth={["100%", "60%"]}>
            <input
              required={true}
              value={honeyPot}
              onChange={e => setHoneyPot(e.target.value)}
              name="username"
              type="email"
              style={{ display: "none" }}
              tabIndex={-1}
              autoComplete="off"
            />
            {isGuest(user) && (
              <InputWithLabel
                name="name"
                type="text"
                placeholder="Your name"
                labelText="Name"
                labelSubText="Optional"
                value={input.submitter.name}
                onChange={name =>
                  setInput({
                    ...input,
                    submitter: { ...input.submitter, name }
                  })
                }
                autoFocus={false}
              />
            )}
          </Box>
        )}
        {isGuest(user) && !R.includes(input.submitter.name, ["", "anonymous"]) && (
          <Box my={2}>
            <Label>
              <Checkbox
                checked={input.submitter.consent.storeName}
                onChange={() =>
                  setInput(
                    R.assocPath(
                      ["submitter", "consent", "storeName"],
                      !input.submitter.consent.storeName,
                      input
                    )
                  )
                }
                id="storeName"
                name="storeName"
              />
              I give consent for Kampsite to store and display my name
            </Label>
          </Box>
        )}
        <Flex mt={3} justifyContent="flex-end">
          <Button
            disabled={!formValid(input) || loading || honeyPot !== ""}
            onClick={() => createComment()}
            size="large"
            variant="primary"
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default NewCommentForm
