import { RouteComponentProps } from "@reach/router"
import { redirectTo } from "@reach/router"
import Logo from "pages/navbar/logo"
import React, { useContext, useState } from "react"
import { Box, Button, Divider, Flex, Text, Label, Checkbox, Link } from "ui"
import GoogleLogo from "./googleLogo.svg"
import { PageContext } from "Contexts/PageContext"
import useBool from "hooks/useBool"

const SignIn: React.FC<RouteComponentProps> = () => {
  const { page, isKampsiteApp } = useContext(PageContext)
  const [agreesTos, toggleAgreesTos] = useBool(false)
  const [agreesPP, togglePP] = useBool(false)

  const subdomain = isKampsiteApp ? "app" : page && page.subdomain

  if (subdomain !== 'app') redirectTo('https://app.kampsite.co/sign-in')

  return (
    <Flex justifyContent="center" alignItems="center" height="60vh">
      <Box width={"320px"} boxShadow="card" p={3} borderRadius={16} bg="white">
        <Flex
          width={1}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box>
            <Logo />
          </Box>
          <Box width={1} my={3}>
            <Divider />
          </Box>
          <Link href={`/api/auth/google?state=${subdomain}`}>
            <Button
              as="button"
              disabled={!agreesTos || !agreesPP}
              maxWidth="230px"
              sx={{
                borderRadius: 16,
                textDecoration: "none",
                ":hover": { cursor: "pointer", boxShadow: "card" },
              }}
              bg="primary"
              py={2}
            >
              <Flex p={0} alignItems="center">
                <Box bg="white" borderRadius={50} height="26px" p={1} mr={2}>
                  <img alt="google logo" src={GoogleLogo} />{" "}
                </Box>
                <Text fontSize={2} color="white" fontWeight="bold">
                  Sign in with Google
                </Text>
              </Flex>
            </Button>
          </Link>
          <Flex width={0.8} flexDirection="column">
            <Text mt={4} color="muted" fontSize={0}>
              In order to use Kampsite, you must agree to the terms of service
              and privacy policy
            </Text>
            <Box mt={3}>
              <Label alignItems="center" fontSize={0}>
                <Checkbox
                  checked={agreesTos}
                  onChange={toggleAgreesTos}
                  id="agreesTos"
                  name="agreesTos"
                />
                I agree to the{" "}
                <Box ml={2} as="a" href="https://kampsite.co/terms">
                  terms of service.
                </Box>
              </Label>
            </Box>
            <Box>
              <Label alignItems="center" fontSize={0}>
                <Checkbox
                  checked={agreesPP}
                  value={agreesPP}
                  onChange={togglePP}
                  id="agreePrivacyPolicy"
                  name="agreePrivacyPolicy"
                />
                I agree to the{" "}
                <Box ml={2} as="a" href="https://kampsite.co/privacy-policy">
                  Privacy policy
                </Box>
              </Label>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default SignIn
