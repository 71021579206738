import Pill from "components/Pill"
import CommentIcon from "pages/Features/FeatureRequest/CommentIcon"
import { FeatureRequestProps, IFeatureRequest } from "pages/Features/types"
import * as R from "ramda"
import React, { useContext } from "react"
import { Box, Flex, Text, Link as ExtLink } from "ui"
import UpVote from "./UpVote"
import { toPostedAt } from "modules/helpers"
import Actions from "./Actions"
import { Link } from "@reach/router"
import LabelPill from "components/Label"
import { Label } from "Contexts/types"
import { PageContext } from "Contexts/PageContext"

export const buildPopularPill = (request: IFeatureRequest) =>
  request &&
  request.labels.length < 3 &&
  request.votes > 5 && {
    id: "popular",
    text: "Popular 🔥",
    color: "#ffb100",
    type: "PUBLIC",
  }

export const statusPill = (request: IFeatureRequest) =>
  R.cond([
    [
      R.equals("accepted"),
      () => ({
        id: "accepted",
        text: "In Progress 🎉",
        color: "#3dd598",
        type: "PUBLIC",
      }),
    ],
    [
      R.equals("completed"),
      () => ({
        id: "completed",
        text: "Completed 💎",
        color: "#ffb100",
        type: "PUBLIC",
      }),
    ],
    [
      R.equals("pending"),
      () => ({
        id: "pending",
        text: "Suggested",
        color: "#0062ff",
        type: "PUBLIC",
      }),
    ],
    [
      R.equals("rejected"),
      () => ({
        id: "rejected",
        text: "Rejected",
        color: "#fc5a5a",
        type: "PUBLIC",
      }),
    ],
      [
      R.equals("considered"),
      () => ({
        id: "considered",
        text: "In Review 🤔",
        color: "#ec5afc",
        type: "PUBLIC",
      }),
    ],
      [
      R.equals("planned"),
      () => ({
        id: "planned",
        text: "Planned",
        color: "#5afcee",
        type: "PUBLIC",
      }),
    ],

  ])(request.status)

const IntOrExtLink = ({ to, children }) =>
  R.startsWith("http", to) ? (
    <ExtLink href={to} sx={{ color: "inherit", textDecoration: "none" }}>
      {children}
    </ExtLink>
  ) : (
    <Link style={{ color: "inherit", textDecoration: "none" }} to={to}>
      {children}
    </Link>
  )
const LinkToIf = ({ to, ifTrue, children }) =>
  ifTrue ? <IntOrExtLink to={to}>{children}</IntOrExtLink> : children

const Comments = ({ count }: { count: number }) => (
  <>
    <Box mr={2} p={2} bg="warningLightest" borderRadius={50}>
      <Box height="17px">
        <CommentIcon />
      </Box>
    </Box>
    <Text ml={2} color="muted" sx={{ fontWeight: "body" }} fontSize={1}>
      {count}{" "}
      <Box display={["none", "none", "inline-block"]}>{` ${
        count === 1 ? " Comment" : " Comments"
      }`}</Box>
    </Text>
  </>
)

const extraLabels = (request: IFeatureRequest, pageId: any) => {
  const popularLabel = buildPopularPill(request)
  if (pageId === "a47113d7-2ae0-4ad6-add8-34f5d9dd2ce8") {
    return popularLabel ? [popularLabel] : []
  } else {
    return [statusPill(request), ...(popularLabel ? [popularLabel] : [])]
  }
}

const FeatureRequest = ({
  request,
  userVoted,
  updateUserVoted,
  navigate,
  showComments = true,
  canManagePage = false,
  showDescription = false,
}: FeatureRequestProps) => {
  const { page } = useContext(PageContext)

  // Isn't it beautiful
  const labels = R.pipe(
    R.propOr([], "labels"),
    R.concat(extraLabels(request, page && page.id)),
    R.map((label: Label) => (
      <Box mr={2} key={label.id} my={2}>
        <LabelPill label={label} />
      </Box>
    ))
  )(request)
  return (
    <Flex width="100%" alignItems="center" flexWrap="wrap">
      <Flex
        mr={3}
        alignItems="center"
        justifyContent="center"
        flex="1 0 10%"
        height="100%"
      >
        <UpVote
          userVoted={userVoted}
          updateUserVoted={updateUserVoted}
          request={request}
        />
      </Flex>
      <Flex flexDirection="column" flex={showComments ? "9 0 65%" : "12 0 70%"}>
        <Flex mb={2} alignItems="center" justifyContent="space-between">
          <LinkToIf to={navigate} ifTrue={navigate}>
            <Text width={1} fontSize={[1, 2]} sx={{ fontWeight: "regular" }}>
              {request.title}
            </Text>
          </LinkToIf>
          {canManagePage && (
            <Box display={["flex", "none"]} alignItems="center" mx={2}>
              <Actions request={request} />
            </Box>
          )}
        </Flex>
        <Flex mb={2} flexWrap="wrap" width={1} alignItems="center">
          <Text
            mr={2}
            color="muted"
            sx={{ wordBreak: "break-word", fontWeight: "body" }}
            fontSize={[0, 1]}
          >
            {showDescription &&
              R.pipe(
                R.ifElse(
                  R.pipe(R.length, R.lte(150)),
                  R.pipe(R.take(70), (s: any) => R.concat(s, "...")),
                  R.identity
                )
              )(request.description)}
          </Text>
        </Flex>
        <Box flexWrap="wrap" display={["none", "flex"]}>
          {labels}
        </Box>
        <LinkToIf to={navigate} ifTrue={navigate}>
          <Box flex="1 0 100%" flexWrap="wrap" display={["flex", "none"]}>
            {labels}
          </Box>
        </LinkToIf>
      </Flex>
      {showComments && (
        <LinkToIf to={navigate} ifTrue={navigate}>
          <Box
            alignItems="center"
            justifyContent="flex-start"
            flex={["3 1 0", "3 1 10%", "3 1 17%"]}
            display={["none", "flex"]}
          >
            <Comments count={request.commentCount} />
          </Box>
        </LinkToIf>
      )}
      {canManagePage && (
        <Box display={["none", "flex"]} alignItems="center" mx={2}>
          <Actions request={request} />
        </Box>
      )}
    </Flex>
  )
}

export default FeatureRequest
