import React, { useContext } from "react"
import { RouteComponentProps, Router } from "@reach/router"
import NewFinishedFeature from "./New"
import ViewFinishedFeature from "pages/Features/FinishedFeatures/View"
import EditFinishedFeature from "pages/Features/FinishedFeatures/Edit"
import { UserContext } from "Contexts"

const FinishedFeatures: React.FC<RouteComponentProps> = () => {
  const { canManagePage } = useContext(UserContext)

  return (
    <Router primary={false}>
      {canManagePage && <NewFinishedFeature path="/new" />}
      {canManagePage && <EditFinishedFeature path="/:finishedFeatureId/edit" />}
      <ViewFinishedFeature path="/:finishedFeatureId" />
    </Router>
  )
}

export default FinishedFeatures
