import { Link, navigate, RouteComponentProps } from "@reach/router"
import Container from "components/Container"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import useBool from "hooks/useBool"
import { GET_COMPLETED_FEATURES, GET_FEATURE_COUNTS } from "modules/queries"
import NoSuggestions from "pages/Features/FeatureList/NoSuggestions"
import { FeatureStatusCounts } from "pages/Features/types"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { AnimatedBox, Box, Button, Flex, Heading, Input } from "ui"
import Loader from "ui/Loader"
import FeatureRequestList from "./FeaturesList/FeatureRequestList"
import SearchIcon from "components/Search"

export const anyCounts = (counts: FeatureStatusCounts) =>
    counts?.pending > 0 || counts?.accepted > 0 || counts?.completed > 0

export const handleNewUpdates = (
    prev: { page: { featureRequests: any[] } },
    { fetchMoreResult }
) => {
    if (!fetchMoreResult) return prev
    return Object.assign({}, prev, {
        page: {
            ...prev.page,
            featureRequests: [...getReqs(prev), ...getReqs(fetchMoreResult)],
        },
    })
}

export const getReqs = (payload): any[] =>
    R.path(["page", "featureRequests"], payload)

export const buildFetchMore = (fetchFunc, reqs) => (page) =>
    fetchFunc({
        variables: {
            offset: reqs.length,
        },
        updateQuery: handleNewUpdates,
    })

const ChangeLog: React.FC<RouteComponentProps> = (props) => {
    const statusFilter = "completed"

    const [showSearch, toggleSearch] = useBool(false)
    const { subdomain, page } = useContext(PageContext)
    const prefs = defaultPrefs(page)
    const { activity, updateActivity, canManagePage } = useContext(UserContext)
    const [filter, updateFilter] = useState(statusFilter)
    const countsResp = useQuery(GET_FEATURE_COUNTS, {
        variables: { subdomain },
    })

    const { data, error, loading, fetchMore } = useQuery(GET_COMPLETED_FEATURES, {
        variables: { subdomain, offset: 0, status: filter },
    })

    useEffect(() => {
        updateFilter(statusFilter)
    }, [statusFilter])

    if (error) {
        return null
    }
    const requests = R.pipe(
        R.pathOr([], ["page", "featureRequests"]),
        R.filter(R.propEq("status", filter))
    )(data)

    const counts: FeatureStatusCounts = R.path(
        ["data", "page", "featureRequestsCounts"],
        countsResp
    )

    const setFilter = (newFilter) => {
        updateFilter(newFilter)
        navigate(`/?filter=${newFilter}`)
    }

    return (
        <Container
            subtitle={
                prefs.changelogSubtitle ||
                `Keep up to date with ${R.prop("name", page)}'s latest changes`
            }
            title={
                <>
                    <Flex flexDirection="column">
                        {prefs.changelogTitle || "ChangeLog"}
                    </Flex>
                </>
            }
            location={props.location}
        >


            <Box width="100%" mt={4}>
                {countsResp.loading ? (
                    <Loader/>
                ) : anyCounts(counts) ? (
                    <>
                        <Flex width={1} flexDirection="column" alignItems="center">
                            {loading ? (
                                <Loader/>
                            ) : R.length(requests) > 0 ? (
                                <FeatureRequestList
                                    loadMore={buildFetchMore(fetchMore, requests)}
                                    totalCount={counts[filter]}
                                    userVoted={activity.voted}
                                    updateActivity={updateActivity}
                                    requests={requests}
                                    canManagePage={canManagePage}
                                    navigate={navigate}
                                />
                            ) : (
                                <Box
                                    boxShadow="card"
                                    pb={5}
                                    borderRadius="0 0 16px 16px"
                                    bg="white"
                                >
                                    <Flex justifyContent="center">
                                        <NoSuggestions/>
                                    </Flex>
                                </Box>
                            )}
                        </Flex>
                    </>
                ) : (
                    <Flex justifyContent="center" width={1}>
                        <NoSuggestions/>
                    </Flex>
                )}
            </Box>
        </Container>
    );
}

export default ChangeLog
