import React, { useEffect, useState } from "react"
import { Flex, Heading, Text, AnimatedBox } from "ui"
import "./animation.css"
import Box from "ui/Button"

const Success = ({ onClose }) => {
  useEffect(() => {
    setTimeout(onClose, 2300)
  }, [])

  return (
    <Flex flexDirection="column" bg="white">
      <Flex width={1} justifyContent="center" mb={2} mt={3}>
        <Heading color="darkerGrey">Success 🎉</Heading>
      </Flex>
      <Box my={2} bg="white">
        <Text fontSize={1} color="darkGrey" textAlign="center">
          Your email address has been verified.
        </Text>
      </Box>
      <Box my={3} bg="white">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
      </Box>
      <Box my={2} width={1} bg="white">
        <Box my={2} bg="white">
          <Text
            onClick={onClose}
            sx={{ "&:hover": { cursor: "pointer", fontWeight: "bold" } }}
            color="darkGrey"
            fontSize={1}
          >
            Close
          </Text>
        </Box>
      </Box>
      <Box width={1} height="10px" bg="white">
        <AnimatedBox
          height="10px"
          bg="successLightest"
          animate={{ width: 0 }}
          width={1}
          transition={{ duration: 2.3 }}
        />
      </Box>
    </Flex>
  )
}

export default Success
