import React from "react"
import { Box, Button, Flex, Link, Text, Heading } from "ui"
import { RouteComponentProps } from "@reach/router"

const Contact: React.FC<RouteComponentProps> = () => (
  <Flex py={3} flexDirection="column">
    <Flex flexDirection="column">
      <Heading mb={2} textAlign="center" fontSize={5}>
        Help & support
      </Heading>
      <Text mt={3} fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
        If you need any help at all or just want to ask a question, feel free to
        shoot me a message.
      </Text>
    </Flex>
    <Box my={3}>
      <Link href="mailto:support@kampsite.co">
        <Button>Contact Jonny</Button>
      </Link>
    </Box>
  </Flex>
)

export default Contact
