import React from "react"
import ReactMarkdown from "react-markdown"
import { Text } from "ui"

const Markdown = ({ text }: { text: string }) => (
  <ReactMarkdown
    source={text}
    unwrapDisallowed={true}
    renderers={{
      text: content => (
        <Text as="span" color="darkGrey" lineHeight="32px">
          {content.value}
        </Text>
      )
    }}
    disallowedTypes={["heading", "html", "image", "imageReference"]}
  />
)

export default Markdown
