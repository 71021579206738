import { navigate, RouteComponentProps } from "@reach/router"
import Container from "components/Container"
import { UserContext } from "Contexts"
import { PageContext } from "Contexts/PageContext"
import { extractGraphqlErrors } from "modules/helpers"
import { GET_FEATURE, UPDATE_SUGGESTION } from "modules/queries"
import NewSuggestionForm from "pages/Features/MutateFeature/form"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import Loader from "ui/Loader"
import { IFeatureRequest } from "../types"

const reqToInput = (req: IFeatureRequest) =>
  R.pipe(
    R.evolve({
      submitter: R.always(null),
    }),
    R.assoc("image", req.imageUrl),
    R.omit(["submitter"])
  )(req)

const defaultInput = {
  description: "",
  pageId: "",
  image: null,
  labels: [],
  title: "",
}

const buildImagePayload = (existingUrl: string) =>
  R.pipe(
    R.when(
      removeImage(existingUrl),
      R.pipe(R.omit(["image"]), R.assoc("removeImage", true))
    ),
    R.when(imageUnchanged(existingUrl), R.omit(["image"]))
  )

const removeImage = (existingUrl) => (input) =>
  existingUrl && R.isNil(input.image)

const imageUnchanged = (existingUrl) => (input) => existingUrl === input.image

const UPDATE_FIELDS = ["title", "description", "image", "wouldPay", "labels"]

const buildSubmitPayload = (input) =>
  R.pipe(
    R.pick(UPDATE_FIELDS),
    R.evolve({
      labels: R.map(R.prop("id")),
    }),
    buildImagePayload(input && input.imageUrl)
  )(input)

const EditFeature: React.FC<RouteComponentProps & { reqId?: string }> = (
  props
) => {
  const { addToast } = useToasts()
  const { page, labels } = useContext(PageContext)
  const prefs = defaultPrefs(page)
  const { user } = useContext(UserContext)
  const [input, setInput] = useState(defaultInput)

  const getReqResp = useQuery(GET_FEATURE, {
    variables: { reqId: props.reqId },
  })

  useEffect(() => {
    if (getReqResp.data) {
      R.pipe(R.path(["data", "featureRequest"]), reqToInput, (input: any) =>
        setInput(input)
      )(getReqResp)
    }
  }, [getReqResp])

  const [updateSuggestion, { loading, error, data }] = useMutation(
    UPDATE_SUGGESTION,
    {
      variables: { input: buildSubmitPayload(input), id: props.reqId },
      refetchQueries: [
        { query: GET_FEATURE, variables: { reqId: props.reqId } },
      ],
    }
  )

  useEffect(() => {
    if (data) {
      addToast("Suggestion Added", { appearance: "success", autoDismiss: true })
      navigate(`/suggestions/${props.reqId}`)
    } else if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (getReqResp.loading || loading) {
    return <Loader />
  }

  return (
    <Container title="Add a suggestion" location={props.location}>
      <NewSuggestionForm
        labels={labels}
        user={user}
        submitForm={updateSuggestion}
        setInput={setInput}
        input={input}
        loading={loading}
        isEdit={true}
      />
    </Container>
  )
}

export default EditFeature
