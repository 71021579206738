import React from "react"
import { Box, Text } from "ui"

const NewPill = () => (
  <Box bg="success" px={2} pr={2} pt={"2px"} borderRadius={8} mt={"2px"} ml={2}>
    <Text fontSize={0} color="white" fontWeight="bold" mr={"12px"}>
      New
    </Text>
  </Box>
)

export default NewPill
