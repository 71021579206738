import React, { useContext } from "react"
import { RouteComponentProps } from "@reach/router"
import { useQuery } from "react-apollo"
import { GET_FINISHED_FEATURE } from "modules/queries"
import Container from "components/Container"
import Loader from "ui/Loader"
import FinishedFeature from "pages/Features/FinishedFeatures/View/FinishedFeature"
import { Flex } from "ui"
import { UserContext } from "Contexts"

const ViewFinishedFeature: React.FC<RouteComponentProps & {
  finishedFeatureId?: string
  data: { id: 'asdas-asas', showcase: { id: 'asdasd', title:'', description: '', whyBuilt:'', added:'2020-01-01', imageUrl:''} }
}> = ({ finishedFeatureId, location, data, navigate }) => {
  const loading=false
  const { canManagePage } = useContext(UserContext)

  return (
    <Container title="" location={location} showBack={false}>
      {loading ? (
        <Loader />
      ) : (
        <Flex width={1} justifyContent="center">
          <FinishedFeature
            canManagePage={canManagePage}
            navigate={navigate}
            reqId={data.id}
            finishedFeature={data.showcase}
          />
        </Flex>
      )}
    </Container>
  )
}

export default ViewFinishedFeature
