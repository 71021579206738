import React, { useState, useContext, useEffect } from "react"
import InputWithLabel from "components/Input"
import Modal from "./Modal"
import { Box, Flex, Heading, Text, Button } from "ui"
import { useMutation } from "react-apollo"
import { ADD_DOMAIN } from "modules/queries"
import { PageContext } from "Contexts/PageContext"
import { useToasts } from "react-toast-notifications"
import { extractGraphqlErrors } from "modules/helpers"

interface CustomDomainProps {
  open: boolean
  customDomain: string
}

const CustomDomain = ({ open, customDomain }: CustomDomainProps) => {
  const { addToast } = useToasts()
  const [domain, setDomain] = useState(customDomain || "")
  const { page } = useContext(PageContext)
  const [saveDomain, { data, loading, error }] = useMutation(ADD_DOMAIN, {
    variables: {
      pageId: page && page.id,
      input: { domain },
    },
  })

  useEffect(() => {
    if (data) {
      addToast("Domain successfully updated", {
        appearance: "success",
        autoDismiss: true,
      })
    } else if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }
  }, [data, error])

  return (
    <Modal open={open}>
      {(close) => (
        <Flex flexDirection="column" bg="white">
          <Flex width={1} justifyContent="center" mb={2} mt={3}>
            <Heading color="darkerGrey">Custom Domain</Heading>
          </Flex>
          <Box my={2} bg="white">
            <Text fontSize={1} color="darkGrey" textAlign="center">
              To get started with a custom domain follow the the below steps.
              For help changing your DNS settings . It can take a few hours for
              your DNS to properly propogate.
              <a
                target="_blank"
                href="https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain"
              >
                click here.
              </a>
            </Text>
            <Text my={3} fontSize={1} color="darkGrey">
              1. Using a CNAME dns record, point your domain to{" "}
              <span
                style={{
                  background: "lightGrey",
                  padding: "0px 5px",
                }}
              >
                cname.kampsite.co
              </span>
            </Text>
            <Text my={3} fontSize={1} color="darkGrey">
              2. Enter your domain below and press save
            </Text>
          </Box>
          <Box my={3}>
            <InputWithLabel
              name="domain"
              type="text"
              placeholder="google.com"
              labelText="Domain"
              labelSubText="Don't include http:// or https://"
              value={domain}
              onChange={setDomain}
              autoFocus={true}
              minLength={3}
              smallLabel
            />
          </Box>
          <Flex>
            <Box my={3}>
              <Button onClick={() => saveDomain()}>Save</Button>
            </Box>
            <Box ml={3} my={3}>
              <Button variant="outline" onClick={close}>
                Cancel
              </Button>
            </Box>
          </Flex>
          {data && (
            <Text my={3} fontSize={1} fontWeight="bold" color="success">
              Domain updated
            </Text>
          )}
        </Flex>
      )}
    </Modal>
  )
}

export default CustomDomain
