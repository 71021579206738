import { AuthedUserState } from "Contexts/types"
import Auth from "modules/auth"
import React, { useState, useEffect } from "react"
import Loader from "ui/Loader"
import Box from "ui/Button"

const defaultAuthed: AuthedUserState = {
  authenticated: false,
  checking: true,
}

export const AuthContext = React.createContext<AuthedUserState>(defaultAuthed)

const AuthProvider = ({ children }) => {
  const isAuthed = Auth.isAuthenticated()
  const [authenticated, setAuthenticated] = useState(isAuthed)
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    if (isAuthed) {
      setChecking(true)
      Auth.signIn()
        .then((accessToken) => {
          if (accessToken) {
            setAuthenticated(true)
          }
          setChecking(false)
        })
        .catch((e) => {
          setAuthenticated(false)
          setChecking(false)
        })
    } else {
      setChecking(false)
    }
  }, [isAuthed])

  const defaultContext: AuthedUserState = {
    authenticated,
    setAuthenticated,
    checking,
  }
  return (
    <AuthContext.Provider value={defaultContext}>
      {checking ? (
        <Box width={1} mt={5}>
          <Loader />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

AuthProvider.displayName = "AuthProvider"

export default AuthProvider
