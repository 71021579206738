import { navigate } from "@reach/router"
import InputWithLabel from "components/Input"
import { PageContext } from "Contexts/PageContext"
import { extractGraphqlErrors } from "modules/helpers"
import { CREATE_ORG, CREATE_PAGE, GET_ME } from "modules/queries"
import FakeBrowser from "pages/settings/FakeBrower"
import Upload from "pages/settings/fileUpload"
import { defaultPrefs } from "pages/settings/helpers"
import * as R from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { Box, Button, Checkbox, Flex, Label, Text } from "ui"

const formValid = R.curry(
  (name: string, subdomain: string) => name.length > 2 && subdomain.length > 2
)

const OnboardingForm = () => {
  const { addToast } = useToasts()
  const { setPage } = useContext(PageContext)
  const [companyName, setCompanyName] = useState("")
  const [logo, setLogo] = useState(null)
  const [subdomain, setSubdomain] = useState("")
  const [prefs, setPrefs] = useState(defaultPrefs(null))
  const { page } = useContext(PageContext)
  const [createOrg, { loading, error, data }] = useMutation(CREATE_ORG, {
    variables: { name: companyName }
  })

  if (page) {
    navigate("/manage?success=true")
  }

  const [
    createPage,
    { data: pRespData, error: pRespError, loading: pRespLoading }
  ] = useMutation(CREATE_PAGE, {
    variables: {
      name: companyName,
      subdomain,
      logo,
      appearance: prefs
    },
    refetchQueries: [{ query: GET_ME }]
  })

  useEffect(() => {
    if (error) {
      addToast(extractGraphqlErrors(error), { appearance: "error" })
    }

    if (data) {
      createPage().catch(() => null)
    }
  }, [data, error, addToast])

  useEffect(() => {
    if (pRespData) {
      addToast("Page successfully created! 🎉", { appearance: "success" })
      R.pipe(R.prop("createPage"), setPage)(pRespData)
      navigate("/manage?success=true")
    }

    if (pRespError) {
      addToast(extractGraphqlErrors(pRespError), { appearance: "error" })
    }
  }, [pRespData, pRespError])

  const readyForSubmit = formValid(companyName, subdomain)
  const setLowerSubDomain = R.pipe(
    R.toLower,
    R.replace(/[^0-9a-zA-Z-]/, ""),
    setSubdomain
  )

  return (
    <Flex width={1} flexDirection="column">
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent={["flex-start", "flex-start", "space-between"]}
        alignItems="flex-start"
        width="100%"
      >
        <Flex
          flexDirection="column"
          px={[4, 4, 0]}
          my={3}
          width={[1, 1, 4.5 / 10]}
        >
          <Box my={3} width="100%">
            <InputWithLabel
              name="company_name"
              type="text"
              placeholder="Company Name"
              labelText="Company"
              labelSubText="Your users will see this."
              value={companyName}
              onChange={setCompanyName}
              autoFocus={true}
              minLength={3}
            />
          </Box>

          <Box my={3} width="100%">
            <InputWithLabel
              name="subdomain"
              type="url"
              placeholder="Subdomain"
              labelText="Subdomain"
              labelSubText={"Where your page will live"}
              value={subdomain}
              onChange={setLowerSubDomain}
              autoFocus={false}
              disabled={false}
              minLength={3}
            />
          </Box>
          <Box my={3} width="100%">
            <Flex
              flexDirection={["column", "column", "row"]}
              alignItems={["flex-start", "flex-start", "center"]}
              ml={2}
              mb={1}
            >
              <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
                Logo
              </Text>
              <Text fontWeight="normal" fontSize={[0, 0, 1]} color="muted">
                Your companies logo (optional)
              </Text>
            </Flex>
            <Box ml={2}>
              <Upload value={logo} onSelected={setLogo} />
            </Box>
          </Box>
          <Box mt={4} ml={2}>
            <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[2, 2, 3]}>
              Appearance customization
            </Text>
            <Text sx={{ fontWeight: "mid" }} mr={2} fontSize={[1, 1, 2]}>
              Logo
            </Text>
            <Flex mt={2}>
              <Label>
                <Checkbox
                  checked={prefs.showNavbarBg}
                  onChange={() =>
                    setPrefs({ ...prefs, showNavbarBg: !prefs.showNavbarBg })
                  }
                  id="showNavbarBg"
                  name="showNavbarBg"
                />
                White Background
              </Label>
              <Label>
                <Checkbox
                  checked={prefs.logoShadow}
                  onChange={() =>
                    setPrefs({ ...prefs, logoShadow: !prefs.logoShadow })
                  }
                  id="logoShadow"
                  name="logoShadow"
                />
                Shadow
              </Label>
            </Flex>
            <Flex mt={1}>
              <Label>
                <Checkbox
                  checked={prefs.logoBorderRadius}
                  onChange={() =>
                    setPrefs({
                      ...prefs,
                      logoBorderRadius: !prefs.logoBorderRadius
                    })
                  }
                  id="logoBorderRadius"
                  name="logoBorderRadius"
                />
                Border Radius
              </Label>
            </Flex>
          </Box>
        </Flex>

        <Flex
          flexDirection={"column"}
          my={3}
          px={[4, 4, 0]}
          width={[1, 1, 4.5 / 10]}
        >
          <Text sx={{ fontWeight: "mid" }} ml={2} mb={2} fontSize={[2, 2, 3]}>
            Preview
          </Text>
          <FakeBrowser
            logo={logo}
            subdomain={subdomain}
            companyName={companyName}
            prefs={prefs}
            isOnboarding={true}
          />
        </Flex>
      </Flex>
      <Box mt={3} px={[4, 4, 0, 0]} alignSelf="flex-end">
        <Button
          onClick={() => {
            if (!data) {
              createOrg()
            } else if (!pRespData) {
              createPage()
            } else {
              navigate("/")
            }
          }}
          disabled={!readyForSubmit || loading || pRespLoading}
          size="large"
          variant="primary"
        >
          Save
        </Button>
      </Box>
    </Flex>
  )
}

export default OnboardingForm
