import { navigate } from "@reach/router"
import { extractGraphqlErrors, toPostedAt } from "modules/helpers"
import {
  GET_ALL_FEATURES_LITE,
  GET_FEATURES,
  MARK_DUPLICATE
} from "modules/queries"
import ModalContent from "pages/Features/FeatureRequest/Actions/ModalContent"
import { IFeatureRequest } from "pages/Features/types"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-apollo"
import { useToasts } from "react-toast-notifications"
import { AnimatedBox, Box, Button, Divider, Flex, Text } from "ui"
import Loader from "ui/Loader"
import SuggestionSearch, { filterSearch } from "../../FeatureList/SearchResults"

const toPrettyStatus = (status: IFeatureRequest["status"]) =>
  R.cond([
    [R.equals("accepted"), R.always("in progress")],
    [R.equals("completed"), R.always("completed")],
    [R.equals("considered"), R.always("considered")],
    [R.equals("planned"), R.always("planned")],
    [R.equals("rejected"), R.always("rejected")],
    [R.equals("pending"), R.always("pending")],
    [R.T, R.always("")]
  ])(status)

const MarkDuplicateModalContent = ({
  subdomain,
  onSelect,
  selected
}: {
  selected: string
  subdomain: string
  onSelect: (id: string) => any
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { data, error, loading } = useQuery(GET_ALL_FEATURES_LITE, {
    variables: { subdomain }
  })

  if (loading) {
    return <Loader />
  }

  const allRequests = R.pathOr([], ["page", "featureRequests"], data)

  const filteredResults = R.ifElse(
    R.isNil,
    () => R.filter(filterSearch(searchTerm), allRequests),
    () => R.filter(R.propEq("id", selected), allRequests)
  )(selected)

  return (
    <Box width={1} height={["auto", "500px"]} maxHeight={["80vh", "auto"]}>
      <Text
        mb={2}
        sx={{
          fontWeight: "mid",
          fontSize: [3, 3, 4]
        }}
      >
        Mark Suggestion as duplicate
      </Text>
      <Text color="darkGrey" sx={{ whiteSpace: "pre-wrap" }}>
        This will remove this suggestion from the list and add it as a duplicate
        of the selected suggestion below.
      </Text>
      <Box my={3}>
        <SuggestionSearch value={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Flex
        flexDirection="column"
        width={1}
        maxHeight={["300px", "300px"]}
        sx={{
          overflowY: "scroll"
        }}
        my={4}
        p={2}
      >
        {R.map((req: IFeatureRequest) => (
          <AnimatedBox positionTransition key={req.id} width={1}>
            <Flex flexDirection="column" width={1} my={2}>
              <Flex justifyContent="space-between" width={1}>
                <Flex width={4 / 5} flexDirection="column" flexWrap="wrap">
                  <Text fontSize={1} sx={{ fontWeight: "regular" }}>
                    {req.title}
                  </Text>
                  <Flex flexWrap="wrap">
                    <Text
                      mr={2}
                      color="muted"
                      sx={{ fontWeight: "body" }}
                      fontSize={[0, 1]}
                    >
                      {req.votes} upvotes -
                    </Text>
                    <Text
                      mr={2}
                      color="muted"
                      sx={{ fontWeight: "body" }}
                      fontSize={[0, 1]}
                    >
                      {toPostedAt(req.insertedAt)} -
                    </Text>
                    <Text
                      mr={2}
                      color="muted"
                      sx={{ fontWeight: "body" }}
                      fontSize={[0, 1]}
                    >
                      {toPrettyStatus(req.status)}
                    </Text>
                  </Flex>
                </Flex>
                <Box>
                  <Button
                    onClick={() => onSelect(req.id)}
                    disabled={req.id === selected}
                    fontSize="12px"
                    variant={!selected ? "outline" : "primary"}
                  >
                    {req.id === selected ? "Selected" : "Select"}
                  </Button>
                </Box>
              </Flex>
              <Box mt={2} width={1}>
                <Divider />
              </Box>
            </Flex>
          </AnimatedBox>
        ))(filteredResults)}
      </Flex>
    </Box>
  )
}

const MarkDuplicateModal = ({
  isOpen,
  subdomain,
  onClose,
  duplicateRequest
}) => {
  const [selectedId, setSelectedId] = useState(null)
  const [markDuplicate, resp] = useMutation(MARK_DUPLICATE, {
    variables: { duplicateId: duplicateRequest.id, duplicateOfId: selectedId },
    refetchQueries: [{ query: GET_FEATURES, variables: { subdomain } }]
  })
  const { addToast } = useToasts()

  useEffect(() => {
    if (resp.data) {
      addToast("Suggestion marked as duplicate", {
        appearance: "success",
        autoDismiss: true
      })
      navigate("/")
    } else if (resp.error) {
      addToast(extractGraphqlErrors(resp.error), {
        appearance: "error",
        autoDismiss: true
      })
    }
  }, [resp, addToast])

  return (
    <ModalContent
      onClose={() => {
        onClose()
        setSelectedId(null)
      }}
      buttonText="Save"
      open={isOpen}
      onSubmit={markDuplicate}
      buttonDisabled={false}
    >
      <MarkDuplicateModalContent
        selected={selectedId}
        onSelect={setSelectedId}
        subdomain={subdomain}
      />
    </ModalContent>
  )
}

export default MarkDuplicateModal
