import { WindowLocation } from "@reach/router"
import React from "react"
import { Flex, Box, Button, Divider, Text } from "ui"
import { NavMenuItem } from "pages/ManagementDashboard/Sidebar/Menu"
import { Page } from "Contexts/types"
import * as R from "ramda"
import NewPill from "components/NewPill"

export const isPath = (location: WindowLocation) => (pathName: string) =>
  location.pathname === `/${pathName}`

export const pathStartsWith = (location: WindowLocation) => (
  pathName: string
) => R.startsWith(`/${pathName}`, location.pathname)

const Sidebar = ({
  location,
  page,
}: {
  location: WindowLocation
  page: Page
}) => {
  const checkSelected = isPath(location)
  return (
    <Flex
      height="100%"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width={180}
    >
      <NavMenuItem
        text="Dashboard"
        route="/manage"
        selected={checkSelected("manage") || checkSelected("")}
        icon="dashboard"
      />
      <NavMenuItem
        text="Page Settings"
        route="/manage/page"
        selected={checkSelected("manage/page")}
        icon="page_settings"
      />
      <Flex alignItems="flex-start">
        <NavMenuItem
          text="Labels"
          route="/manage/labels"
          selected={checkSelected("manage/labels")}
          icon="labels"
        />
        <NewPill />
      </Flex>
      <NavMenuItem
        text="Questions"
        route="/manage/questions"
        selected={checkSelected("manage/questions")}
        icon="question"
      />
      <Flex alignItems="flex-start">
        <NavMenuItem
          text="Users"
          route="/manage/users"
          selected={checkSelected("manage/users")}
          icon="users"
        />
        <NewPill />
      </Flex>
      <NavMenuItem
        text="Account"
        route="/manage/account"
        selected={checkSelected("manage/account")}
        icon="account"
      />
      <Flex alignItems="flex-start">
        <NavMenuItem
          text="Billing"
          route="/manage/billing"
          selected={checkSelected("manage/billing")}
          icon="billing"
        />
        <NewPill />
      </Flex>
      <NavMenuItem
        text="Contact"
        route="/manage/support"
        selected={checkSelected("manage/support")}
        icon="contact"
      />
      <Flex mt="-24px" mb={"24px"} justifyContent="center" width={1} mx={3}>
        <Divider width={"40%"} />
      </Flex>
      <NavMenuItem
        text="Go to page"
        route={`https://${page && page.subdomain}.kampsite.co`}
        selected={checkSelected("/")}
        icon="link"
      />
    </Flex>
  )
}

export default Sidebar
